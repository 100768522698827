import styled, { css } from "styled-components";
//import RecImage from "../../../styles/images/Rectangle949@2x.png";

export default styled.div`
    ${css`
        
.artist-card-container{
    width: calc(100% - 30px);
    padding: 2px;
    font-family: "Montserrat-Bold";   
    border-radius: 6px;
    margin: auto;
    width: 178px;
}
.artist-card {       
    border: 1px solid #BCBCBC27;
    border-radius: 6px;
    height: 178px;
    width: 100%;
    margin: auto;
}
.artist-card-inner{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: calc( 100% - 12px);;
    height: calc( 100% - 12px);;
    margin: 6px;
    background: #1A4576  linear-gradient(180deg, #23405C00 0%, #00000088 100%) 0% 0% no-repeat padding-box;
}
.artist-innerOverlay {
    position: absolute;
    right: 10px;
    bottom: 10px;
    text-align: right;
    font-size: 23;
    letter-spacing: 0px;
    color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};
}
.artist-info{
    padding: 15px 10px;
}
.artist-head {
    text-align: left;
    letter-spacing: 0px;
    color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};
    padding: 5px 0;
    font-size: 15px;
    font-family: "Montserrat-Bold"; 
}
.artist-description {
    padding: 0;
    opacity: 0.8;
    font-size: 12px;
    font-family: "Montserrat-Medium";
    color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};
}
`}
`;