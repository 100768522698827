import React from "react";
// import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { marketPlaceAction } from "./duck/action";
import { Container } from "semantic-ui-react";
import DetailLeft from "./components/detail-left/detail-left";
import CoverDescription from "../../../components/cover-description/cover-description";
import TabSection from "../../../components/tab-section/tab-section";
import {DetailsTabList, BidTabList} from "./components/tab-list/tab-list";
import PlaceBidModal from "./components/place-bid/place-bid";
import AcceptOfferModal from "./components/accept-offer/accept-offer";
import OpenBidsCss from './openBids'
// import "./openBids.css";


const NftOpenBidsPage = () => {

  const [showBid, setShowBid] = React.useState(false);
  const [showOffer, setShowOffer] = React.useState(false);

  const tabData = [
    {
      header: "Details",
      childran: <DetailsTabList />,
    },
    {
      header: "Bids",
      childran: <BidTabList onAcceptOffClick={() => setShowOffer(true)} />,
    },
    {
      header: "History",
      childran: <DetailsTabList />,
    },
  ];
  const buttonClickFunc = () => {
    setShowBid(true);
  }
  return (
    <Container>
    <OpenBidsCss>
      <div className="detail-main-openBids">
        <div className="detail-left">
          <DetailLeft />
        </div>
        <div className="detail-right">
          <div className="right-block">
            <CoverDescription />
            <TabSection  tabData={tabData} />
            {/* <HighestBid buttonClickFunc={() => buttonClickFunc}/> */}
            <div className="last-block-openBids">
            <p>Minimum bid </p>
            <h2>0.02 wETH</h2>
            <h4>$182</h4>
            <div className="button-span">
              <button className="btn_full" onClick={ () => setShowBid(true)}>PLACE A BID</button>
              <button className="btn_outline" onClick={() => buttonClickFunc()}>SHARE</button>
            </div>
           
           </div>
          </div>
        </div>
      </div>
      <PlaceBidModal onClose={() => setShowBid(false)} show={showBid}/>
      <AcceptOfferModal onClose={() => setShowOffer(false)} show={showOffer}/>
    </OpenBidsCss>
    </Container>
  );
};

export default NftOpenBidsPage;
