import styled, { css } from "styled-components";

export default styled.div`
    ${css`
    .sctbl-container {
        display: flex;
        margin-top: 120px;
    }
    .sctbl-c1 {
        display: flex;
    }
    .sctbl-c1-img {
        height: 40px;
        width: 40px;
        cursor: pointer;
        position: relative;
        top: -10px;
    }
    
    .sctbl-c2 {
        width: calc(100% - 400px);
        margin: 0 30px;
    }
    .sctbl-c2-type-text {
        text-align: left;
        color: #AFAFAF;
        font-family: 'Montserrat-Bold';
    }
    .sctbl-c2-head-text {
        text-align: left;
        font-family: 'Montserrat-Bold';
        font-size: 39px;
    }
    
    .sctbl-c2-upload-head-text {
        text-align: left;
        letter-spacing: 1.33px;
        font-family: 'Montserrat-Bold';
        text-transform: uppercase;
    }
    
    .sctbl-c2-upload-box {       
        border: 1px dashed #707070;
        border-radius: 5px;
        /* opacity: 0.4; */
        padding:50px 40px; 
        text-align: center;
        font-family: 'Montserrat-Bold';
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 260px;
        max-height: 600px;
    }
    
    .sctbl-c2-upload-box-text {
        color: #AFAFAF;
    }
    
    .sctbl-c2-upload-box-button {
        border-radius: 18px !important;
        background-color: transparent !important;
        color:  #E154C4 !important;
        border: 1px solid #E56BCC !important;
        cursor: pointer ;
        text-transform: uppercase;
    }

    .sctbl-c2-upload-box-preview {
        border-radius: 2px;
        img {
            max-width: 100%;
            min-height: 250px;
            max-height: 500px;
        }
        span {
            position: absolute;
            cursor: pointer;
            right: 25px;
            top: 15px;
        }
        video {
            max-width: 100%;
            min-width: 100%;
            max-height: 500px;
        }
        audio {
            background: ${props => props.theme.mode === "dark" ? "#000000" : "#ffffff"};
        }
        audio::-webkit-media-controls-panel {
            background-color: ${props => props.theme.mode === "dark" ? "#000000" : "#ffffff"};
        }        
        audio::-webkit-media-controls-mute-button,
        audio::-webkit-media-controls-play-button {
            background-color: #E154C4;
            border-radius: 50%;
        }
        audio::-webkit-media-controls-timeline-container {
            color: red !important;
        }
        audio::-webkit-media-controls-current-time-display,
        audio::-webkit-media-controls-time-remaining-display {
            color: #E154C4;
        }

        audio::-webkit-media-controls-timeline {
            background-color: #E154C4;
            border-radius: 25px;
            margin-left: 10px;
            margin-right: 10px;
        }
        audio::-webkit-media-controls-volume-slider {
            background-color: #E154C4;
            border-radius: 25px;
            padding-left: 8px;
            padding-right: 8px;
        }
    }
    
    .sctbl-c2-logo-card-holder {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }
    
    .sctbl-c2-collection-heading {
        text-align: left;
        font-family: 'Montserrat-Bold';
        letter-spacing: 1.33px;
        text-transform: uppercase;
        opacity: 1;
        margin-top: 50px;
    }
    
    .sctbl-c2-collection-holder {
        display: flex;
        justify-content: flex-start;
    }
    
    .sctbl-c2-settings-button-holder {
        margin-top: 50px;
    }
    
    .sctbl-c2-settings-button {
        border-radius: 65px !important;
        background-color: transparent !important;
        border: 1px solid #707070 !important;
        letter-spacing: 1.14px;
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"} !important;
        font-family: 'Montserrat-Bold';
        text-transform: uppercase;
    }
    
    .sctbl-c2-settings-button-hide{
        background-color: #FFFFFF !important;
        color: #000000;
        border-radius: 65px !important;
        border: 1px solid #707070 !important;
        letter-spacing: 1.14px;
        font-family: 'Montserrat-Bold';
        text-transform: uppercase;
    }
    .sctbl-c2-settings-button-icon {
        margin: auto;
    }
    
    .sctbl-c2-cancel-create-buttons {
        margin-top: 80px;
        display: flex;
        justify-content: space-between;
    }
    
    .sctbl-c2-cancel-buttons {
        border-radius: 18px !important;
        background-color: transparent !important;
        color:  #E154C4 !important;
        border: 1px solid #E56BCC !important;
        cursor: pointer ;
        text-transform: uppercase;
        font-family: 'Montserrat-Bold';
    }
    .sctbl-c2-create-buttons {
        border-radius: 18px !important;
        background-color: #E154C4 !important;
        color:  #FFFFFF !important;
        letter-spacing: 1.14px;
        cursor: pointer ;
        text-transform: uppercase;
        font-family: 'Montserrat-Bold';
    }
    
    /* C3 */
    .sctbl-c3 {
        margin-top: 128px;
        position: sticky;
        top: 100px;
        height: 100%;
    }
    
    .sctbl-c3-preview {
        text-align: left;
        letter-spacing: 1.33px;
        font-family: 'Montserrat-Bold';
        text-transform: uppercase;
    }
    @media only screen and (max-width: 991px) {
        .sctbl-container{
            flex-direction: column;
        }
        .sctbl-c2 {
            width: 90%;
            margin: 0 30px;
        }
        .sctbl-c2-logo-card-holder{
            flex-direction: column;
        }
        .sctbl-c2-collection-holder {
            flex-direction: column;
            align-items: center;
        }
        .box-dev{
            margin-left: 0!important;
        }
        .sctbl-c2-head-text {           
            font-size: 24px;
        }
        .collectible-sub-type p{
            font-size: 16px;
        }
        .collectible-sub-type-description{
            font-size: 14px;
        }
    }
    @media only screen and (max-width: 768px) {
        .sctbl-c2-head-text {           
            font-size: 18px;
        }
        .collectible-sub-type p{
            font-size: 14px;
        }
        .collectible-sub-type-description{
            font-size: 12px;
        }
    }
    `}
`;