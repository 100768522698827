import React from "react";
import { Container, Table, Icon, Menu, Tab } from "semantic-ui-react";
import defaultImg from "../../../styles/images/default.svg";
import MyActivityCss from './my-activity'
import { useHistory } from "react-router";

const tableData = [
  {
    date: "2014-04-18",
    total: 121.0,
    status: "Shipped",
    name: "A",
    points: 5,
    percent: 50,
  },
  {
    date: "2014-04-21",
    total: 121.0,
    status: "Not Shipped",
    name: "B",
    points: 10,
    percent: 60,
  },
  {
    date: "2014-08-09",
    total: 121.0,
    status: "Not Shipped",
    name: "C",
    points: 15,
    percent: 70,
  },
  {
    date: "2014-04-24",
    total: 121.0,
    status: "Shipped",
    name: "D",
    points: 20,
    percent: 80,
  },
  {
    date: "2014-04-26",
    total: 121.0,
    status: "Shipped",
    name: "E",
    points: 25,
    percent: 90,
  },
];

const MyActivityPage = () => {
  const history = useHistory();
  const panes = [
    {
      
      menuItem: (
        
              <Menu.Item key='All'>
               All
              </Menu.Item>
            ),
      render: () => <Tab.Pane attached={false}>
        
        <Table selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell>Highest bid</Table.HeaderCell>
                <Table.HeaderCell>Expiration</Table.HeaderCell>
                <Table.HeaderCell>From</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>{allItemRows}</Table.Body>
          </Table>
        
        </Tab.Pane>,
    },
    {
      menuItem: (
        <Menu.Item key='Following'>
         Following
        </Menu.Item>
      ),
      render: () => <Tab.Pane attached={false}>
          <Table selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell>Highest bid</Table.HeaderCell>
                <Table.HeaderCell>Expiration</Table.HeaderCell>
                <Table.HeaderCell>From</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>{allItemRows}</Table.Body>
          </Table>
       
      </Tab.Pane>,
    },
    {
      menuItem: (
        <Menu.Item key='My Activity'>
          My Activity
        </Menu.Item>
      ),
      render: () => <Tab.Pane attached={false}>
          <Table selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell>Highest bid</Table.HeaderCell>
                <Table.HeaderCell>Expiration</Table.HeaderCell>
                <Table.HeaderCell>From</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>{allItemRows}</Table.Body>
          </Table>
      </Tab.Pane>,
    },
    {
      menuItem: (
        <Menu.Item key='My Bids'>
          My Bids
        </Menu.Item>
      ),
      render: () => <Tab.Pane attached={false}>
          <Table selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell>Highest bid</Table.HeaderCell>
                <Table.HeaderCell>Expiration</Table.HeaderCell>
                <Table.HeaderCell>From</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>{allItemRows}</Table.Body>
          </Table>
       
      </Tab.Pane>,
    }
  ]
  const TabExampleAttachedFalse = () => (
    <Tab menu={{ attached: false }} panes={panes} />
  )
  const [expandedRows, setExpandedRows] = React.useState<string[]>([]);

  const handleRowClick = (rowId: any) => {
    const currentExpandedRows = expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((id) => id !== rowId)
      : currentExpandedRows.concat(rowId);

    setExpandedRows(newExpandedRows);
  };

  const renderItemCaret = (rowId: any) => {
    const currentExpandedRows = expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    if (isRowCurrentlyExpanded) {
      return <Icon name="caret down" />;
    } else {
      return <Icon name="caret right" />;
    }
  };

  const renderItemDetails = (item: any) => {
    return (
      <MyActivityCss>
        
      <React.Fragment>
        <Table.Row >
          <Table.Cell className="span-dr">
            {/* <div className="table-title">
        <div className="image-title"><img  src={defaultImg} alt=""/></div>
        <div className="info-title">
          <span className="big-title">This NFT is awesome</span>
          <span className="small-title">By Random Girl</span>
        </div>
      </div> */}
          </Table.Cell>
          <Table.Cell className="span-dr">
            <div className="highest-bid">
              <div className="bid-title">0.02 wETH</div>
              <div className="bid-btn" onClick = {()=>history.push("nft-open-bids")}>
                VIEW BID OFFER
              </div>
            </div>
          </Table.Cell>
          <Table.Cell className="span-dr">
            <div className="expiration">
              <div className="expiration-title">7 days</div>
            </div>
          </Table.Cell>
          <Table.Cell className="span-dr">
            <div className="from-div">
              <div className="image-title"><img src={defaultImg} alt="" /></div>
              <div className="info-title">
                This NFT is awesome
              </div>
            </div>
          </Table.Cell>
          <Table.Cell className="span-dr"></Table.Cell>
        </Table.Row>
        <Table.Row >
          <Table.Cell className="span-dr">
            {/* <div className="table-title">
        <div className="image-title"><img  src={defaultImg} alt=""/></div>
        <div className="info-title">
          <span className="big-title">This NFT is awesome</span>
          <span className="small-title">By Random Girl</span>
        </div>
      </div> */}
          </Table.Cell>
          <Table.Cell className="span-dr">
            <div className="highest-bid">
              <div className="bid-title">0.02 wETH</div>
              <div className="bid-btn" onClick = {()=>history.push("nft-open-bids")}>
                VIEW BID OFFER
              </div>
            </div>
          </Table.Cell>
          <Table.Cell className="span-dr">
            <div className="expiration">
              <div className="expiration-title">7 days</div>
            </div>
          </Table.Cell>
          <Table.Cell className="span-dr">
            <div className="from-div">
              <div className="image-title"><img src={defaultImg} alt="" /></div>
              <div className="info-title">
                This NFT is awesome
              </div>
            </div>
          </Table.Cell>
          <Table.Cell className="span-dr"></Table.Cell>
        </Table.Row>
        <Table.Row >
          <Table.Cell className="span-dr">
            {/* <div className="table-title">
        <div className="image-title"><img  src={defaultImg} alt=""/></div>
        <div className="info-title">
          <span className="big-title">This NFT is awesome</span>
          <span className="small-title">By Random Girl</span>
        </div>
      </div> */}
          </Table.Cell>
          <Table.Cell className="span-dr">
            <div className="highest-bid">
              <div className="bid-title">0.02 wETH</div>
              <div className="bid-btn" onClick = {()=>history.push("nft-open-bids")}>
                VIEW BID OFFER
              </div>
            </div>
          </Table.Cell>
          <Table.Cell className="span-dr">
            <div className="expiration">
              <div className="expiration-title">7 days</div>
            </div>
          </Table.Cell>
          <Table.Cell className="span-dr">
            <div className="from-div">
              <div className="image-title"><img src={defaultImg} alt="" /></div>
              <div className="info-title">
                This NFT is awesome
              </div>
            </div>
          </Table.Cell>
          <Table.Cell className="span-dr"></Table.Cell>
        </Table.Row>
      </React.Fragment>
      </MyActivityCss>
    );
  };

  const renderItem = (item: any, index: any) => {
    const clickCallback = () => handleRowClick(index);
    const itemRows = [
      <Table.Row onClick={clickCallback} key={"row-data-" + index}>        
        {/* <Table.Cell>{item.date}</Table.Cell>
        <Table.Cell>{item.total}</Table.Cell>
        <Table.Cell>{item.status}</Table.Cell>
        <Table.Cell>{renderItemCaret(index)}</Table.Cell> */}
        <Table.Cell>
          <div className="table-title">
            <div className="image-title"><img  src={defaultImg} alt=""/></div>
            <div className="info-title">
              <span className="big-title">This NFT is awesome</span>
              <span className="small-title">By Random Girl</span>
            </div>
          </div>
          </Table.Cell>
        <Table.Cell>
        <div className="highest-bid">
            <div className="bid-title">0.02 wETH</div>
            <div className="bid-btn" onClick = {()=>history.push("nft-open-bids")}>
            VIEW BID OFFER
            </div>
          </div>
        </Table.Cell>
        <Table.Cell>
        <div className="expiration">
            <div className="expiration-title">7 days</div>           
          </div>
        </Table.Cell>
        <Table.Cell>
          <div className="from-div">
            <div className="image-title"><img src={defaultImg} alt=""/></div>
            <div className="info-title">
              This NFT is awesome             
            </div>
          </div>
          </Table.Cell>
          <Table.Cell>{renderItemCaret(index)}</Table.Cell>
      </Table.Row>,
    ];
    

    if (expandedRows.includes(index)) {
      itemRows.push(
        <React.Fragment key={"row-expanded-" + index}>
          {renderItemDetails(item)}
          {/* <Table.Cell colSpan="4">{renderItemDetails(item)}</Table.Cell> */}
        </React.Fragment>
      );
    }

    return itemRows;
  };

  let allItemRows: any[] = [];

  tableData.forEach((item, index) => {
    const perItemRows = renderItem(item, index);
    allItemRows = allItemRows.concat(perItemRows);
  });
  return (
    <MyActivityCss>
      <Container>           
        <div className="activity-div">
          <h1>Activity</h1>  
                 
          <div className="tab-activity">
           <TabExampleAttachedFalse/> 
           </div>           
        </div>       
      </Container>
      </MyActivityCss>
  );
};

export default MyActivityPage;
