import * as action_types from "./constant";

export const marketPlaceAction = () => {
    return (dispatch: any) => {
        dispatch({
            type: action_types.EXAMPLE,
            data: 'Market Place',
        });
    };
};
