import styled, { css } from "styled-components";

export default styled.div`
    ${css`
    .collectible-auction-date-container {
        margin-top: 50px;
    }
    
    .collectible-auction-date-holders {
        display: flex;
        justify-content: space-between;
        text-align: left;
        letter-spacing: 1.33px;
        color: ${props => props.theme.mode === "dark" ? "#ffffff" : "#000000"};
        text-transform: uppercase;
        font-family: 'Montserrat-Bold';
    }
    .collectible-auction-date-start {
        flex: 1;
    }  
    .collectible-auction-date-end {
        flex: 1;
    }
    .visible.menu.transition {
        background-color: ${props => props.theme.mode === "dark" ? "#000000" : "#ffffff"} !important;
        width: 100%;
    }
    .item {
        color: ${props => props.theme.mode === "dark" ? "#ffffff" : "#000000"} !important;
    }
    
    .collectible-auction-description {
        text-align: left;
        font-size: 16px;
        letter-spacing: 0px;
        color: #AFAFAF;
        font-family: 'Montserrat-Bold';
        margin-top: 30px;
    }
    
    .collectible-auction-link-text {
        text-align: left;
        font-size: 12px;
        letter-spacing: 1.14px;
        color: #E154C4;
        font-family: 'Montserrat-Bold';
        /* margin-top: 20px; */
    }
    .ui.fluid.dropdown {
        border: 1px solid #707070;
        padding: 10px;
    }
    `}
`;