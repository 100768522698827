import styled, { css } from "styled-components";

export default styled.div`
    ${css`
    .ctlb-container {
        margin: 120px 0 0 0;
        color:  ${props => props.theme.mode === "dark" ? "#ffffff" : "#000000"};
        font-family: 'Montserrat-Bold';
    }
    
    .cltb-head-arrow {
        margin: 50px;
        position: relative;
    }
    .cltb-head-arrow .arrow-image {
        width: 34px;
        height: 34px;
        left: 0;
        position: absolute;
        top: -3px;
    }
    .cltb-head-arrow .head-text {
        text-align: center;
        position: relative;       
        font-size: 39px;
    }
    
    .cltb-description {
        text-align: center;
        color: #AFAFAF;
        margin: 16px;
        font-size: 20px;
        line-height: normal;
        padding: 0 200px;
    }
    .card-holder {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 70px;
        padding: 0 200px;
    }
    
    .cltb-lower-description {
        text-align: center;
        margin-top: 70px;
        color: #AFAFAF;
        font-size: 16px;
        padding: 0 350px;
    }
    @media only screen and (max-width: 991px) {
        .cltb-head-arrow .head-text {           
            font-size: 24px;
        }
        
        .cltb-description {
            font-size: 16px;        
            padding: 0 50px;
        }
        .card-holder {
            flex-direction: column;
        }
        .l1-card{
            margin-top: 20px; 
        }
        .cltb-lower-description{
            padding: 0 50px;
        }
        .card-holder{
            padding: 0 50px;
        }
    }
    
    @media only screen and (max-width: 768px) {
        .cltb-head-arrow .head-text {           
            font-size: 18px;
        }
        
        .cltb-description {
            font-size: 14px;        
            padding: 0 20px;
        }
        .cltb-lower-description{
            padding: 0 20px;
        } 
        .card-holder{
            padding: 0 20px;
        }
    }
   
    `}

`;