import styled from "styled-components";

const StyledContainer = styled.div<any>`
    // width: 300px;
    width: 30%;
    // height: 220px;
    height: 250px;
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
    position: relative;
    .collection-user-card-container-logo {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        background: pink;
        position: absolute;
        margin:auto;
        top: 30%;
        left: 38%;
        border: 10px solid black;
        img {
            border-radius: 50%;
            max-width: 100%;
            height: 60px;
            width: 60px;
            min-height: 100%;
        }
    }
    .collection-user-card-container-upper {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        background:${props => "#"+props.randomeColor} ;
        height: 110px;
        padding: 10px;
    }
    .collection-user-card-container-lower{
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        background: #000;
        height: 110px;
        padding: 50px;
        text-align: center;
    }
`;

export default StyledContainer;
