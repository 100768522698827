export const darkTheme = {
    mode:'dark',
    color: {
        mainBackgroundColor: "#000000",
        primaryfontColor: '#FFFFFF',
        secondaryColor: '#E154C4',
        tertiaryColor: '#AFAFAF',
    }
}

export const lightTheme = {
    mode:'light',
    color: {
        mainBackgroundColor: "#FFFFFF",
        primaryfontColor: '#000000',
        secondaryColor: '#E154C4',
        tertiaryColor: '#AFAFAF',
    }
}