import styled, {css} from 'styled-components';

const OpenBidsCss = styled.section`
${css`
.home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 30px 0;
}

.home-category-slide {
    height: 36px;
    margin: 50px 0;
}
.home-artist-slide {
    margin: 50px 0;
}

.home-artist-slide {
    margin: 50px 0;
}

.detail-main-openBids{
    display: flex;
    width: 100%;
    margin-top: 120px;  
}

.detail-main-openBids .detail-left{
    display: flex;
    width: 45%;  
}
.detail-main-openBids .detail-right{
    display: flex;
    width: 55%; 
}

.detail-main-openBids .right-block{
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.detail-main-openBids .highBit-section{
    display: flex;
    width: 100%;
    flex-direction: column;
    background: #1A101C ;
    border-radius: 9px;
    padding: 20px;
    margin-top: 50px;
    margin-bottom: 60px;
}
.detail-main-openBids .highBit-section h2{
    color: #FFFFFF;
    font-family: "SF-Pro-Rounded-Bold";
    font-size: 32px;
    margin: 0;
}
.detail-main-openBids .highBit-section p{
    margin-bottom: 5px;
    color: #B7B7B7;
    font-size: 20px;
    font-family: "Montserrat-SemiBold";
}
.detail-main-openBids .highBit-section p span{
    color: #FFFFFF;
}
.detail-main-openBids .highBit-section h4{
    color: #B7B7B7;
    font-size: 13px;
    color: #B7B7B7;
    margin: 0;
}
.detail-main-openBids .highBit-section .button-span{
    flex-direction: row;
    width: 100%;
    margin: 30px 0 0 0;
}
.detail-main-openBids .highBit-section .button-span button{
    width: calc(50% - 8px);
}
.detail-main-openBids .highBit-section .button-span .btn_full{
    margin-right: 8px;
}
.detail-main-openBids .highBit-section .button-span .btn_outline{
    margin-left: 8px;
}
.detail-main-openBids .last-block-openBids{
    display: flex;
    width: 100%;
    flex-direction: column;
    background:  ${props => props.theme.mode === "dark" ? "#1A101C" : "#f7f7f7"};
    border-radius: 9px;
    padding: 20px;
    margin-top: 50px;
    margin-bottom: 60px;
}
.detail-main-openBids .last-block-openBids h2{
    color: ${props => props.theme.mode === "dark" ? "#ffffff" : "#1A101C"};
    font-family: "SF-Pro-Rounded-Bold";
    font-size: 32px;
    margin:0;
}
.detail-main-openBids .last-block-openBids p{
    margin-bottom: 5px;
    color: #B7B7B7;
    font-size: 20px;
    font-family: "Montserrat-SemiBold";
}
.detail-main-openBids .last-block-openBids p span{
    color: #FFFFFF;
}
.detail-main-openBids .last-block-openBids h4{
    color: #B7B7B7;
    font-size: 13px;
    color: #B7B7B7;
    margin: 0;
}
.detail-main-openBids .last-block-openBids .button-span{
    flex-direction: row;
    width: 100%;
    margin: 30px 0 0 0;
}
.detail-main-openBids .last-block-openBids .button-span button{
    width: calc(50% - 8px);
}
.detail-main-openBids .last-block-openBids .button-span .btn_full{
    margin-right: 8px;
}
.detail-main-openBids .last-block-openBids .button-span .btn_outline{
    margin-left: 8px;
}
.des-block-main{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.des-block-main .inner-block-main{
    display: flex;
    flex-direction: column;
    width: 50%;
}
.des-block-main .inner-block-main h3{
    color: #B7B7B7;
    font-size: 13px;
    font-family: "Montserrat-SemiBold";   
}
.des-block-main .inner-block-main h3 span{
    color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"} !important;
}
.des-block-main .inner-block-main .inner-sub{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.des-block-main .inner-block-main .inner-sub .img_block {
    min-width: 57px;
    height: 57px;
    border-radius: 100%;
    background-color: #BCBCBC27;
    border: 1px solid #BCBCBC27;
    position: relative;
}

.des-block-main .inner-block-main .inner-sub .img_block img {
    position: absolute;
    right: -3px;
    bottom: -3px;
}
.des-block-main .inner-block-main .inner-sub .info_block{
    margin-left: 20px;
}
.des-block-main .inner-block-main .inner-sub .info_block .title {
    font-size: 15px;
    font-family: 'Montserrat-Bold';
    color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"} !important;   
    margin: 0;
}

.des-block-main .inner-block-main .inner-sub .info_block p {
    font-size: 13px;
    font-family: "Montserrat-SemiBold";
    color: #E154C4;
    margin-bottom: 0;
}
@media only screen and (max-width: 991px) {
    .detail-main-openBids{
        flex-direction: column;
    }
    .detail-main-openBids .detail-left{
        width: 100%;  
    }
    .detail-main-openBids .detail-right{
        width: 100%;  
    }

}
@media only screen and (max-width: 520px) {
    .detail-main-openBids .last-block-openBids p {
        font-size: 15px;
    }
    .detail-main-openBids .last-block-openBids h2 {
        font-size: 25px;
    }
    .detail-main-openBids .last-block-openBids h4 {
        font-size: 11px;
    }
    .button-span button {
        font-size: 12px;
    }
   

}
`}
`;


export default OpenBidsCss;