import StyledContainer from "./styles";
import { Container } from "semantic-ui-react";
import LeftArrow from "../../../styles/images/leftArrow.png";
import HalfTone from "../../../styles/images/half-tone.png";
//import FullTone from "../../../styles/images/musical-note-full.png";
//import Daimond from "../../../styles/images/Group369.png";
import { Level1Card } from "./components/cards/collectibleCards/collectibleCards";
import { useHistory } from "react-router";

const Collectible = () => {
  const history = useHistory();
  const handleL1Card = (type: string) => {
    ["single", "multiple"].includes(type) &&
      (type === "single"
        ? history.push("collectible/single")
        : history.push("/collectible/multiple"));
  };
  return (
    <StyledContainer>
      <Container>
      <div className="ctlb-container">
        <div className="cltb-head-arrow">
          {/* <img className="arrow-image" src={LeftArrow} alt="" /> */}
          <div className="head-text">Create Collectible</div>
        </div>
        <div className="cltb-description">
          Choose “single” if you want your collectible to be one of a kind or
          “Multiple” if you want to see one collectible multiple times
        </div>

        <div className="card-holder">
          <div onClick={() => handleL1Card("single")}>
            <Level1Card l1CardImage={HalfTone} l1CardText="SINGLE" />
          </div>
          <div onClick={() => handleL1Card("multiple")}>
            <Level1Card l1CardImage={HalfTone} l1CardText="MULTIPLE" />
          </div>
        </div>

        <div className="cltb-lower-description">
          We don’t own your private key and cannot access funds without your
          confirmation
        </div>
      </div>
      </Container>
    </StyledContainer>
  );
};

export default Collectible;
