
//import RecImage from "../../../styles/images/Rectangle949@2x.png";
import StyledContainer from "./styles";

const ArtistCard = ({ overlayData, artistsData }: any) => {
  return (
    <StyledContainer>
    <div className="artist-card-container">
      <div className="artist-card">
        {/* <img src={RecImage} alt=""/> */}
        <div className="artist-card-inner">
            <div className="artist-innerOverlay">{overlayData}</div>
        </div>
      </div>
      <div className="artist-info">
         <div className="artist-head">{artistsData?.displayName}</div>
         {/* <div className="artist-head artist-description">{artDescription}</div> */}
      </div>
    </div>
    </StyledContainer>
  );
};

export default ArtistCard;
