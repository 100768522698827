import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import Global from "./styles/global";
import { darkTheme, lightTheme } from "./styles/theme";
import Header from "./components/shared/header/header";
import Footer from "./components/shared/footer/footer";
import "semantic-ui-css/semantic.min.css";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import store from "./store/store";
import Root from "./routings/routes";

const IndexApp = () => {
  const [mode, setMode] = useState("dark");
  let existingTheme: any = localStorage.getItem("nftTheme");
  useEffect(() => {
    setMode(existingTheme);
  }, [existingTheme]);
  const setTheme = (value: any) => {
    setMode(value);
    localStorage.setItem("nftTheme", value);
  };
  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={mode === "dark" ? darkTheme : lightTheme}>
          <Header setTheme={setTheme} existingTheme={existingTheme} />
          <Global />
          <ToastContainer
            autoClose={3000}
            position="top-right"
            // hideProgressBar={false}
          />
          <Root />
          <Footer />
        </ThemeProvider>
      </Provider>
    </>
  );
};
ReactDOM.render(<IndexApp />, document.getElementById("root"));
