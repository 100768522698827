import styled, {css} from 'styled-components';

const MyPortfolioCss = styled.div`

${css`
  .sec-portfolio{  
    background: #E154C4  linear-gradient(180deg, #23405C00 0%, #00000088 100%);
    height: 200px;
    display: flex;
    align-items: flex-end;
  }
 .des-block {
    display: flex;    
    width: 100%;
    justify-content: space-between;    
    padding: 20px;
    border-radius: 9px; 
    margin-bottom: 10px;
    position : relative;
    top : 85px;
}
.des-block .des-block-left{
    display: flex;
}
 .des-block .des-block-left .img_block {
    width: 75px;
    height: 75px;   
    border-radius: 100%;    
    position: relative;
    margin-right: 15px;
}
.des-block .des-block-left .img_block .img-big {
    position: absolute;
    width: 100%;
}
.des-block .des-block-left .img_block .img-small {
    position: absolute;
    right: 4px;
    bottom: 4px;   
}

 .des-block .des-block-left .info_block {
    display: flex;
    flex-direction: column;
    justify-content: center;    
}

 .des-block .des-block-left .info_block .title {
    font-size: 16px;
    font-family: 'Montserrat-Bold';
    color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"}; 
    margin-bottom: 0px; 
    padding-top: 20px;
}

.des-block .des-block-left .info_block p {
    font-size: 13px;
    font-family: "Montserrat-SemiBold";
    color: #E154C4!important;
    margin-bottom: 0;
}
.des-block .des-block-left .info_block p span{
    color: #E154C4;
}
.img-port{
    background: #000000;
    border-radius: 100%;
    padding: 5px;
}
`}
`;


export default MyPortfolioCss;