import styled, { css } from "styled-components";

export default styled.div`
    ${css`
    width: 100%; 
    .right-block{
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
    }
    .head-block{
        display: flex;
        align-items: center;
        width: 100%;
    }
    .head-block .des-block {
        display: flex;    
        width: 100%;
        justify-content: space-between;
    }
    .head-block .des-block .des-block-left{
        display: flex;
        align-items: center;
    }
    .head-block .des-block .des-block-left .img_block {
        width: 35px;
        height: 35px;
        border-radius: 100%;
        background-color: #BCBCBC27;
        border: 1px solid #BCBCBC27;
        position: relative;
    }
    
    .head-block .des-block .des-block-left .img_block img {
        position: absolute;
        right: -6px;
        bottom: -4px;
    }
    
    .head-block .des-block .des-block-left .info_block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    }
    
    .head-block .des-block .des-block-left .info_block .title {
        font-size: 25.25px;
        font-family: 'Montserrat-Bold';
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"} !important;   
    }
    
    .head-block .des-block .des-block-left .info_block p {
        font-size: 13px;
        font-family: "Montserrat-SemiBold";
        color: #B7B7B7;
        margin-bottom: 0;
    }
    .head-block .des-block .des-block-left .info_block p span{
        color: #E154C4;
    }
    
    .head-block .des-block .des-block-left .count{
        display: flex;
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"} !important;
        font-size: 16px;
        font-family: "SF-Pro-Rounded-Bold";
        align-items: center;        
    }
    .head-block .des-block .des-block-left .count img{
        margin-right: 5px;
    }
    .head-block .des-block .des-block-left img{
        margin-left: 25px;  
        height: 20px;
    }
    .middle-block{
        display: flex;
        align-items: center;
        width: 100%;
    }
    .middle-block p{
        font-family: "Montserrat-SemiBold";
        color: #B7B7B7;
        font-size: 15px;
        margin-top: 25px;
        margin-bottom: 50px;
    }
    
    .right-block .des-block-main{
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .right-block .des-block-main .inner-block-main{
        display: flex;
        flex-direction: column;
        width: 50%;
    }
    .right-block .des-block-main .inner-block-main h3{
        color: #B7B7B7;
        font-size: 13px;
        font-family: "Montserrat-SemiBold";   
    }
    .right-block .des-block-main .inner-block-main h3 span{
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"} !important;
    }
    .right-block .des-block-main .inner-block-main .inner-sub{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .right-block .des-block-main .inner-block-main .inner-sub .img_block {
        min-width: 57px;
        height: 57px;
        border-radius: 100%;
        background-color: #BCBCBC27;
        border: 1px solid #BCBCBC27;
        position: relative;
    }
    
    .right-block .des-block-main .inner-block-main .inner-sub .img_block img {
        position: absolute;
        right: -3px;
        bottom: -3px;
    }
    .right-block .des-block-main .inner-block-main .inner-sub .info_block{
        margin-left: 20px;
    }
    .right-block .des-block-main .inner-block-main .inner-sub .info_block .title {
        font-size: 15px;
        font-family: 'Montserrat-Bold';
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"} !important;   
        margin: 0;
    }
    
    .right-block .des-block-main .inner-block-main .inner-sub .info_block p {
        font-size: 13px;
        font-family: "Montserrat-SemiBold";
        color: #E154C4;
        margin-bottom: 0;
    }
    .right-block .tab-div{
        width: 100%;
        display: flex;
    }
    .right-block .tab-div>div{
        width: 100%;
    }
    .right-block .tab-div .menu{   
        background: transparent;    
        width: 100%;   
        margin-top: 60px;
        border-bottom: 1px solid ${props => props.theme.mode === "dark" ? "#151515" : "#f2f2f2"};
    }
    .right-block .tab-div .ui.menu .item {   
        padding: 0;
        margin: 0;  
        justify-content: center;
        font-size: 15px;
        color: #AFAFAF;
        font-family: "Montserrat-Bold";
        text-decoration: none;
        border-radius: 0;
        margin-right: 80px;
        position: relative;
        background: transparent;
    }
    .right-block .tab-div .ui.menu .active.item{   
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"} !important;
    }
    .right-block .tab-div .ui.tab p{
        margin-bottom: 30px;
    }
    .right-block .tab-div .ui.tab.active{
        background: transparent;
    }
    .right-block .tab-div .ui.menu .active.item::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: #ffffff;
        display: flex;
        bottom: -1px;
    }
    
    .right-block .tab-div .ui.segment{
       padding: 0;
       margin: 0;
    }
    
    .tab-block{
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
    }
    .tab-block .des-block {
        display: flex;    
        width: 100%;
        justify-content: space-between;
        background: ${props => props.theme.mode === "dark" ? "#151515" : "#f2f2f2"};     
        padding: 20px;
        border-radius: 9px; 
        margin-bottom: 10px;
    }
    .tab-block .des-block .des-block-left{
        display: flex;
    }
    .tab-block .des-block .des-block-left img{
        height: 20px;
    }
    .tab-block .des-block .des-block-left .img_block {
        width: 48px;
        height: 48px;    
        border-radius: 100%;    
        position: relative;
        margin-right: 25px;
    }
    .tab-block .des-block .des-block-left .img_block .img-big {
        position: absolute;
        width: 100%;
    }
    .tab-block .des-block .des-block-left .img_block .img-small {
        position: absolute;
        right: -3px;
        bottom: -3px;
        width: 18px;
    }
    
    .tab-block .des-block .des-block-left .info_block {
        display: flex;
        flex-direction: column;
        justify-content: center;    
    }
    
    .tab-block .des-block .des-block-left .info_block .title {
        font-size: 15px;
        font-family: 'Montserrat-Bold';
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"} !important;
        margin-bottom: 5px;  
    }
    
    .tab-block .des-block .des-block-left .info_block p {
        font-size: 16px;
        font-family: "Montserrat-SemiBold";
        color: #B7B7B7;
        margin-bottom: 0;
    }
    .tab-block .des-block .des-block-left .info_block p span{
        color: #E154C4;
    }
    .last-block{
        display: flex;
        width: 100%;
        flex-direction: column;
        background:  ${props => props.theme.mode === "dark" ? "#1A101C" : "#f7f7f7"} !important;
        border-radius: 9px;
        padding: 20px;
        margin-top: 50px;
        margin-bottom: 60px;
    }
    .last-block h2{
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};
        font-family: "SF-Pro-Rounded-Bold";
        font-size: 32px;
    }
    .last-block p{
        margin-bottom: 5px;
        color: #B7B7B7;
        font-size: 20px;
        font-family: "Montserrat-SemiBold";
    }
    .last-block p span{
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};
    }
    .last-block h4{
        color: #B7B7B7;
        font-size: 13px;
        color: #B7B7B7;
        margin: 0;
    }
    .last-block .button-span{
        flex-direction: row;
        width: 100%;
        margin: 30px 0 0 0;
    }
    .last-block .button-span button{
        width: calc(50% - 8px);
    }
    .last-block .button-span .btn_full{
        margin-right: 8px;
    }
    .last-block .button-span .btn_outline{
        margin-left: 8px;
    }
   
    @media only screen and (max-width: 798px) {
        .right-block .des-block-main{           
            flex-direction: column;           
        }
        .right-block .des-block-main .inner-block-main{
            margin-top: 20px; 
        }
    }
    `}

`;