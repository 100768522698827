// import React, { useState, useEffect } from "react";
// import { Container, Button } from "semantic-ui-react";
import "./detail-left.css";
//import defaultImg from "../../../../../styles/images/default-img.svg";
import Halftone from "../../../../../styles/images/half-tone.png";
import StyledContainer from "./styles";
const DetailLeft = (props: any) => {
  const { marketPlaceData } = props;
  const { mediaFile, mediaType } = marketPlaceData;
  return (
    <StyledContainer>
      <div className="market_img_block">
        <div className="market_img_card slick-slide">
          {mediaType?.includes("image") && <img src={mediaFile} alt="" />}
          {mediaType?.includes("video") && (
            <video
              style={{ width: "100%", height: "300px" }}
              src={mediaFile}
              autoPlay
              loop
              controls
              controlsList="nodownload noremoteplayback noplaybackrate" 
              disablePictureInPicture
            ></video>
          )}
          {mediaType?.includes("audio") && (
            <>
              <img src={Halftone} alt="" style={{height:'100px', maxWidth: '100%', maxHeight: '100%', width: 'auto'}}/>
              <audio
                style={{ width: "100%", height: "300px" }}
                src={mediaFile}
                autoPlay
                controlsList="nodownload noplaybackrate"
                controls
              ></audio>
            </>
          )}
        </div>
      </div>
    </StyledContainer>
  );
};

export default DetailLeft;
