import styled from "styled-components";


export default styled.div`
    margin-top:100px;
    font-family: "Montserrat-Bold";  
    .search-page-head {
        font-size: 46px;
        color: rgb(140, 140, 140);
        span {
            color: ${props => props.theme.mode === "dark" ? "#ffffff" : "#000000"};
        }
    }
    .search-tab-holder {
        margin-top: 80px;
    }
    .ui.inverted.segment.active.tab {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
    // .ui.inverted.segment.active.tab::after {
    //     content: "";
    //     flex: auto;
    // }
`;