import styled from "styled-components";

export const StyledContainer = styled.div<any>`
font-family: 'Montserrat-Bold';
    h2 {
        text-align: left;
        margin-bottom: 10px;
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};
    }
    .imageContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .imageClass {
            .spanImg {
                width: 90px;
                height: 90px;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid #707070;
                align-items: center;
                justify-content: center;
                display: flex;
                img{
                    width: 100%;
                    padding: 20px;
                }
            }            
        }
        .uploader {
            padding: 0 51px;
            display: flex;
            flex-direction: column;
            align-items: baseline;
            >div {
                text-align: left;
            }
            .info_txt{
                font-size: 11px;
                color:  ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};
            }
            .upButton {
                border-radius: 18px !important;
                background-color: transparent !important;
                color:  #E154C4 !important;
                border: 1px solid #E56BCC !important;
                cursor: pointer ;
                text-transform: uppercase;
                margin-top: 10px;
                font-size: 13px;
            }
        }
    }
 
    .form-container {
        margin-top: 5px;
        text-align: left;
        height: 250px;
        overflow: auto;
        .field-holder {
            margin-top: 25px;
            color:  ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};
        }
       
        input {
            background: transparent;
            border-width: 0px;
            color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};
            outline: none;
            font-size: 14px;
            margin:10px;
            width: 80%;
            border: 1px solid #707070;
            padding: 10px;
            border-radius: 5px;
        }
        h5{
            font-size: 17px;
            margin-bottom: 10px;
        }
        .info_txt{
            font-size: 11px;
            color:  ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};
        }
    }

    .buttonClass {
        height: 48px;
        min-width: 100%;
        border-radius: 48px;
        font-size: 15px;
        font-weight: 900;
        font-family: inherit;
        cursor: pointer;
        border-color: #E56BCC;
        color: #E154C4 !important;
        background: transparent !important;
        margin-top: 30px;
    }
`;