import styled, { css } from 'styled-components';

const CummunityStyledContainer = styled.div`
    ${css`
    .ui.bottom.popup {
        padding: 0;
      }
      .ui.bottom.popup:before {
        background: red;
      }
      .ui.popup {
        border: 0 !important;
        border-radius: 8px !important;
      }
      .cummunity-pop-div {
        background: ${props => props.theme.color.mainBackgroundColor};
        // background: #222222;
        display: flex;
        flex-direction: column;
        padding: 15px 20px;
        width: 200px;
        border-radius: 8px;
        position: relative;
        border: 0.5px solid #605e5e;
      }
      .cummunity-pop-div::before {
        position: absolute;
        top: -5px;
        right: 1em;
        bottom: auto;
        left: auto;
        content: '';
        width: 0.71428571em;
        height: 0.71428571em;
        background: ${props => props.theme.color.mainBackgroundColor};
        // background: #222222;
        transform: rotate(45deg);
        z-index: 2;
        border-top: 0.5px solid #605e5e;
        border-left: 0.5px solid #605e5e;
      }
      .cummunity-pop-div .cummunity-inner-pop {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 5px 0;
        // border-bottom: 1px solid #EAEAEA;
        padding-bottom: 10px;
      }
      .cummunity-pop-div .cummunity-inner-pop h4{ 
        font-size: 12px;
        color: ${props => props.theme.color.primaryfontColor};
        text-transform: uppercase;
        cursor: pointer;
        padding: 0;
        margin: 0;
        font-family: "Montserrat-Bold";
      }
      .cummunity-pop-div .cummunity-inner-pop:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
      .cummunity-pop-div .cummunity-inner-pop .pop-img {
        width: 25%;
        display: flex;
      }
      .cummunity-pop-div .cummunity-inner-pop .pop-info {
        width: 75%;
        display: flex;
        flex-direction: column;
      }
      .cummunity-pop-div .cummunity-inner-pop .pop-info .info-content {
        font-size: 13px;
        color: ${props => props.theme.color.primaryfontColor};
        font-family: "Montserrat-SemiBold";
      }
      .cummunity-pop-div .cummunity-inner-pop .pop-info .time {
        font-size: 10px;
        color: #EAEAEA;
        font-family: "Montserrat-Medium";
      }
      .ui.bottom.right.popup:before {
        background: ${props => props.theme.color.secondaryColor};
      }
      .cummunity_text{
        display: block;
        font-size: 12px;
        color: ${props => props.theme.color.primaryfontColor};
        text-transform: uppercase;
        cursor: pointer;
        padding: 10px;
        margin: 0 5px;
        font-family: "Montserrat-Bold";
      }
    `}
`;


export default CummunityStyledContainer;