// import React, { useState, useEffect } from "react";
import { Container } from "semantic-ui-react";
import topSellerImage from "../../styles/images/top-sellers.png";
import tickBadge from "../../styles/images/tick-badge.svg";
 import "./style.css";
import TopSellersStyledContainer from "./style";
const TopSellers = (props: any) => {
  const { data } = props;
  return (
    <TopSellersStyledContainer>
      <Container>
        <div>
          <div className="seller_heading_wrapper">
            <div className="seller_title">
              <h2>
                Top Sellers <img src={topSellerImage} alt="Top Sellers" />{" "}
              </h2>
            </div>
            <div className="seller_tab">
              <button className="seller_tab_btn">Today</button>
              <button className="seller_tab_btn">This week</button>
              <button className="seller_tab_btn">This month</button>
            </div>
          </div>
          <div className="seller_wrapper">
            {data &&
              data.map((val: any, i: number) => {
                return (
                  <div className="seller_block" key={i}>
                    <div className="img_block">
                      <img src={tickBadge} alt="" />
                    </div>
                    <div className="info_block">
                      <h3 className="title">{val?.name}</h3>
                      <p>{val?.sum}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Container>
    </TopSellersStyledContainer>
  );
};

export default TopSellers;
