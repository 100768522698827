import styled, {css} from "styled-components";

export default styled.div`
    ${css`
    .l1-card {
        width: 277px;
        height: 422px;
        border: 1px solid #707070;
        border-radius: 5px;
        /* opacity: 0.4; */
        position: relative;
        cursor: pointer;
    }
    
    .l1-card:hover {
        background-color: #E154C4;
    }
    .l1-card img {
        width: 48px;
        height: 85px;
        position: absolute;
        left: 40%;
        top: 30%;
    }
    
    .l1-card p {
        position: absolute;
        text-align: left;
        letter-spacing: 1.33px;
        color: ${props => props.theme.mode === "dark" ? "#ffffff" : "#000000"};
        text-transform: uppercase;
        left: 38%;
        top: 75%;
    }
    `}
`;