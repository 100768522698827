import React from "react";
// import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { marketPlaceAction } from "./duck/action";
import { Container } from "semantic-ui-react";
import DetailLeft from "./components/detail-left/detail-left";
import CoverDescription from "../../../components/cover-description/cover-description";
import TabSection from "../../../components/tab-section/tab-section";
import TabList from "./components/tab-list/tab-list";
import PlaceBidModal from "./components/place-bid/place-bid";
import HighestBid from "./components/highest-bid/highest-bid";
// import "./timedAuction.css";
import TimedAuctionStyledContainer from './style';


const NftTimedAuctionPage = () => {
  // const dispatch = useDispatch();
  // const exampleText = useSelector(
  //   (state: any) => state.marketPlaceReducer.exampleText
  // );

  // useEffect(() => {
  //   dispatch(marketPlaceAction());
  // }, []);

  const [show, setShow] = React.useState(false);

  const tabData = [
    {
      header: "Bids",
      childran: <TabList />,
    },
    {
      header: "Details",
      childran: <TabList />,
    },
    {
      header: "History",
      childran: <TabList />,
    },
  ];
  const buttonClickFunc = () => {
    setShow(true);
  }
  return (
    <Container>
      <TimedAuctionStyledContainer> 
      <div className="detail-main">
        <div className="detail-left">
          <DetailLeft />
        </div>
        <div className="detail-right">
          <div className="right-block">
            <CoverDescription />
            <TabSection tabData={tabData} />
            <HighestBid buttonClickFunc={() => buttonClickFunc}/>
          </div>
        </div>
      </div>
      <PlaceBidModal onClose={() => setShow(false)} show={show}/>
      </TimedAuctionStyledContainer>
    </Container>
  );
};

export default NftTimedAuctionPage;
