import styled, { css } from "styled-components";

export default styled.div`
    ${css`
    .footer-div{
        border-top: 1px solid #323232;
        margin-top: 60px;  
    }
    .main-footer{
        display: flex;
        width: 100%;           
        padding-top: 80px;
    }
    .sub-div{
        display: flex;
        width: 20%;
        flex-direction: column;
    }
    .sub-div h4{
        font-size: 12px;
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"} !important;
        margin-bottom: 20px;
        font-family: "Montserrat-Bold";
    }
    .sub-div .menu-list{
        display: flex;
        flex-direction: column;   
    }
    .sub-div .menu-list a{
        font-size: 12px;
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"} !important;
        opacity: 0.7;
        margin-bottom: 20px;
        font-family: "Montserrat-Bold";
    }
    .sub-div .menu-list a span {
        background: #094eff;
        font-size: 12px;
        padding: 2px;
        font-family: "Montserrat-SemiBold";
        margin-left: 5px;
    }
    .sub-div:first-child{
        width: 40%;
    }

    .err_info_msg{
    font-size: 11px;
    margin-top: 5px;
    color: red;
    }
    
    
    .footer-button {
        border-radius: 18px;
        background-color: #E56BCC;
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "#FFFFFF"} !important;
        border: 2px solid #E56BCC;
        font-size: 12px;
        height: 35px;
        line-height: 10px;
        padding: 0 12px;
        cursor: pointer;
        font-family: "Montserrat-Bold";
    }
    .footer-button:hover{
        background-color: transparent;
        color: ${props => props.theme.mode === "dark" ? "#E56BCC" : "#E56BCC"} !important;
    }
    .footer-button:disabled{
        cursor: not-allowed;
    }
    .footer-input{
        background: transparent;
        border: 1px solid #707070;
        border-radius: 6px;
        height: 35px;
        line-height: 35px;
        opacity: 0.7;
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"} !important;
        width: 219px;
        margin-right: 10px;
        font-size: 12px;
        font-family: "Montserrat-Bold";
        padding-left: 10px;
    }
    .social-icon{
        display: flex;
    }
    .social-icon a{
        margin: 15px;
        margin: 20px 10px;
        cursor: pointer;
    }
    .copy-right{
        opacity: 0.6;
        font-size: 12px;
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};
        font-family: "Montserrat-Bold";
        padding: 25px 0;
        margin: 0;
        border-top: 1px solid #323232;
        display: flex;
        justify-content: center;
        margin-top: 60px;
    }
    @media only screen and (max-width: 991px) {
        .main-footer {
            flex-wrap: wrap
        }       
        .sub-div, .sub-div:first-child{           
            width: 50%;    
            margin-bottom: 20px        
        }
    }
    @media only screen and (max-width: 768px) {
        .sub-div, .sub-div:first-child{           
            width: 100%;
        }
    }
    `}

`;