/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import { getCategories, getHotBids, getLiveAuction, getPopularShots, getTopArtists, getTopSellers } from "./duck/action";
import { Container, Tab } from "semantic-ui-react";
import StyledContainer from "./styles";
import CollectionUserItemCard from "./components/collection-user-card/collection-user-card";
import BidAutionShotCard from "../../../components/cards/bidAutionShotCard/bidAutionShotCard";
import AxiosRequest, { REQUESTS } from "../../../utils/hooks/useRequest";

const SearchPage = () => {
  const dispatch = useDispatch();
  const currentTheme = localStorage.getItem("nftTheme");
  const history = useHistory();
  //@ts-ignore
  const searchTerm = history?.location?.state?.searchTerm;

  const [currentTab, setCurrentTab] = useState({});

  useEffect(() => {
    loadAndRenderTabData("collections");
  }, []);

  const loadAndRenderTabData = async (tabName: any) => {
    const masterBody = {
      page: 1,
      records: 10,
      searchValue: searchTerm,
    };
    const body = Object.assign(
      masterBody,
      tabName.includes("col") ? { isMaster: false } : {}
    );
    const { executeRequest } = AxiosRequest({
      method: REQUESTS.POST,
      body,
      url: `${tabName}/list`,
    });
    try {
      const result = await executeRequest();
      if ([200, 201, 203, 404].includes(result?.statusCode)) {
        //   toast.success(result?.message);
        console.log("result", result);
        setCurrentTab({
          name: tabName,
          loading: true,
          data: <CollectionUserItemCard tabName={tabName} data={Array.isArray(result?.data) ? result?.data : []} />,
        });
      } else {
        //   toast.error(result?.message);
      }
    } catch (err: any) {
      // toast.error("Something Wrong");
    }
  };

  const handleTabClick = (e: any, data: any) => {
    switch (data.activeIndex) {
      case 0:
        loadAndRenderTabData("collections");
        break;
      case 1:
        loadAndRenderTabData("products");
        break;
      case 2:
        loadAndRenderTabData("users");
        break;
    }
  };

  const inverFlag = currentTheme === "dark" ? true : false;
  const panes = [
    {
      menuItem: "Collections",
      render: () => (
        <Tab.Pane
          attached={false}
          inverted={inverFlag}
          //@ts-ignore
          //   loading={currentTab.name === "collections"}
        >
          {
            //@ts-ignore
            currentTab.data
          }
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Items",
      render: () => (
        <Tab.Pane
          attached={false}
          inverted={inverFlag}
          //@ts-ignore
          //   loading={currentTab.name === "items"}
        >
          {
            //@ts-ignore
            currentTab.data
          }
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Users",
      render: () => (
        <Tab.Pane
          attached={false}
          inverted={inverFlag}
          //@ts-ignore
          //   loading={currentTab.name === "users"}
        >
          {
            //@ts-ignore
            currentTab.data
          }
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Container>
      <StyledContainer>
        <div className="search-page-head">
          Search results for <span>{searchTerm}</span>
        </div>
        <div className="search-tab-holder">
          <Tab
            menu={{ secondary: true, pointing: true, inverted: true }}
            onTabChange={handleTabClick}
            panes={panes}
          />
        </div>
      </StyledContainer>
    </Container>
  );
};

export default SearchPage;
