import StyledContainer from "./styles";
import HalfTone from "../../../../../../styles/images/half-tone.png";

export const PreviewCard = () => {
  return (
    <StyledContainer>
      <div className="preview-card">
        <img src={HalfTone} alt="" />
        <p>Upload file to preview your brand new NFT</p>
      </div>
    </StyledContainer>
  );
};
