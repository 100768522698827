/* eslint-disable react/prop-types */
import * as React from "react";
import { Switch, Route , Redirect} from "react-router-dom";
import { Container } from "semantic-ui-react";
import Example from "../app/modules/example";
import HomePage from "../app/modules/home";
import MarketPlacePage from "../app/modules/market-place";
import Collectible from "../app/modules/collectible/collectibleMain";
import SingleCollectible from "../app/modules/collectible/singleCollectible/singleCollectible";
import MultipleCollectible from "../app/modules/collectible/multipleCollectible/multipleCollectible";
import NftFixedPricePage from "../app/modules/nft-fixed-price";
import NftTimedAuctionPage from "../app/modules/nft-timed-auction";
import NftOpenBidsPage from "../app/modules/nft-open-bids";
import MyActivityPage from "../app/modules/my-activity";
import MyPortfolioPage from "../app/modules/my-portfolio";
import editPortfolioPage from "../app/modules/my-portfolio/edit-portfolio/editPortfolio";
import SearchPage from "../app/modules/search";


const Pages = (props: any) => {
  // const {
  //   match: { path },
  // } = props;
  return (
    <Container>
      <Switch>
        <Route exact path="/example" component={Example} />
        <Route exact path="/home" component={HomePage} />
        <Route exact path="/marketplace" component={MarketPlacePage} />
        <Route exact path="/collectible" component={Collectible} />
        <Route exact path="/collectible/single" component={SingleCollectible} />
        <Route exact path="/collectible/multiple" component={MultipleCollectible} />
        <Route exact path="/nft-fixed-price" component={NftFixedPricePage} />
        <Route exact path="/nft-timed-auction" component={NftTimedAuctionPage} />
        <Route exact path="/nft-open-bids" component={NftOpenBidsPage} />
        <Route exact path="/my-activity" component={MyActivityPage} />
        <Route exact path="/my-portfolio" component={MyPortfolioPage} />
        <Route exact path="/edit-portfolio" component={editPortfolioPage} />
        <Route exact path="/search" component={SearchPage} />
        <Redirect to="/home" />
      </Switch>
    </Container>
  );
};

export default Pages;
