import styled, {css} from 'styled-components';

 const CoverDescriptionCssContainer = styled.div`
    ${css`
    .coverDescription-blk{
        width: 100%;
    } 
    .head-block{
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 25px;
    }
    .head-block .des-block {
        display: flex;    
        width: 100%;
        justify-content: space-between;
    }
    .head-block .des-block .des-block-left{
        display: flex;
        align-items: center;
    }
    .head-block .des-block .des-block-left .img_block {
        width: 35px;
        height: 35px;
        border-radius: 100%;
        background-color: #BCBCBC27;
        border: 1px solid #BCBCBC27;
        position: relative;
    }
    
    .head-block .des-block .des-block-left .img_block img {
        position: absolute;
        right: -6px;
        bottom: -4px;
    }
    
    .head-block .des-block .des-block-left .info_block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    }
    
    .head-block .des-block .des-block-left .info_block .title {
        font-size: 25.25px;
        font-family: 'Montserrat-Bold';
        color: ${props => props.theme.color.primaryfontColor};  
    }
    
    .head-block .des-block .des-block-left .info_block p {
        font-size: 13px;
        font-family: "Montserrat-SemiBold";
        color: #B7B7B7;
        margin-bottom: 0;
    }
    .head-block .des-block .des-block-left .info_block p span{
        color: #E154C4;
    }
    
    .head-block .des-block .des-block-left .count{
        display: flex;
        color:  ${props => props.theme.color.primaryfontColor}
        font-size: 16px;
        font-family: "SF-Pro-Rounded-Bold";
        align-items: center;
    }
    .head-block .des-block .des-block-left img{
        margin-left: 25px;  
        height: 20px;
    }
    .middle-block{
        display: flex;
        align-items: center;
        width: 100%;
    }
    .middle-block p{
        font-family: "Montserrat-SemiBold";
        color: #B7B7B7;
        font-size: 15px;
        margin-top: 25px;
        margin-bottom: 50px;
    }
    
     .des-block-main{
        display: flex;
        flex-direction: row;
        width: 100%;
    }
     .des-block-main .inner-block-main{
        display: flex;
        flex-direction: column;
        width: 50%;
    }
     .des-block-main .inner-block-main h3{
        color: #B7B7B7;
        font-size: 13px;
        font-family: "Montserrat-SemiBold";   
    }
     .des-block-main .inner-block-main h3 span{
        color:  ${props => props.theme.color.primaryfontColor}
    }
     .des-block-main .inner-block-main .inner-sub{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
     .des-block-main .inner-block-main .inner-sub .img_block {
        min-width: 57px;
        height: 57px;
        border-radius: 100%;
        background-color: #BCBCBC27;
        border: 1px solid #BCBCBC27;
        position: relative;
    }
    
     .des-block-main .inner-block-main .inner-sub .img_block img {
        position: absolute;
        right: -3px;
        bottom: -3px;
    }
     .des-block-main .inner-block-main .inner-sub .info_block{
        margin-left: 20px;
    }
    .des-block-main .inner-block-main .inner-sub .info_block .title {
        font-size: 15px;
        font-family: 'Montserrat-Bold';
        color:  ${props => props.theme.color.secondaryColor}   
        margin: 0;
    }
    
    .des-block-main .inner-block-main .inner-sub .info_block p {
        font-size: 13px;
        font-family: "Montserrat-SemiBold";
        color:  ${props => props.theme.color.secondaryColor}
        margin-bottom: 0;
    }
    @media only screen and (max-width: 600px) {
        .des-block-main {
            flex-direction: column !important;
        }
        .des-block-main .inner-block-main {
            width: 100% !important;
            margin-bottom: 30px !important;
        }
        .head-block .des-block .des-block-left .info_block .title {
            font-size: 18px !important;
        }
        .isPQdI .middle-block p {
            font-size: 13px !important;
        }
     }
    `}
`;


export default CoverDescriptionCssContainer;