import styled, { css } from 'styled-components';

const AuctionTabStyledContainer = styled.div`

    ${css`
    .tab-port{
        width: 100%;
        display: flex;
    }
     .tab-port>div{
        width: 100%;
    }
     .tab-port .menu{   
        background: transparent;    
        width: 100%;   
        margin-top: 60px !important;
        border-bottom: 1px solid ${props => props.theme.mode === "dark" ? "#151515" : "#f2f2f2"};
    }
     .tab-port .ui.menu .item {   
        padding: 0;
        margin: 0;  
        justify-content: center;
        font-size: 11px;
        color: #AFAFAF;
        font-family: "Montserrat-Medium";
        text-decoration: none;
        border-radius: 0;
        margin-right: 80px;
        position: relative;       
    }
     .tab-port .ui.menu .active.item{   
        color: ${props => props.theme.color.primaryfontColor};
        background: transparent;
    }
     .tab-port .ui.tab p{
        margin-bottom: 30px;
    }
    .tab-port .ui.tab.active{
        background: transparent !important;
    }
    .tab-port .ui.menu a.item:hover {
        color:${props => props.theme.color.primaryfontColor};
    }
    .tab-port .ui.menu .active.item::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: ${props => props.theme.color.primaryfontColor};
        display: flex;
        bottom: -1px;
    }
    
     .tab-port .ui.segment{
       padding: 0;
       margin: 0;
    }
    .ui.menu{
        justify-content: center;
    }
    .count{
        position: relative;
        top: -3px;
    }
    @media only screen and (max-width: 768px) {
        .tab-port .ui.menu .item {
            margin-right: 10px;
        }
    }
    `}
`;


export default AuctionTabStyledContainer;