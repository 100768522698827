import * as action_types from "./constant";

const initialState = {
    marketPlaceData: '',
};

const marketPlaceReducer = (state = initialState, action: any) => {
    const { type, data } = action;
    switch (type) {
        case action_types.EXAMPLE:
            return { ...state, marketPlaceData: data };
        default:
            return state;
    }
};

export default marketPlaceReducer;
