import styled, { css } from "styled-components";

export default styled.div`
    ${css`
    .toggle-btn-container {
      width: 48px;
      background-color: #E154C4;
      cursor: pointer;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      padding: 5px;
      height: 27px;
      position: relative;
    }
    
    .toggle-btn-container-disabled {
      background-color: grey;
      cursor: pointer;
    }
    
    .toggle-btn-button {
      cursor: pointer;
      background-color: #000000;
      color: white;
      padding: 8px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      position: absolute;
      left: 30px;
      transition: all 0.3s ease;
    }
    
    .toggle-btn-disabled {
      background-color: #ffff;
      left: 2px;
    }

    `}
`;