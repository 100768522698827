import * as action_types from "./constant";
import axios from "axios";
import { DEV_BASE_URL } from "../../../../constants/url";

export const marketPlaceAction = (id: any) => {

    const url = `${DEV_BASE_URL}/products/${id}`;
    return async (dispatch: any) => {
        try {
            const response = await axios.get(url);
            dispatch({
                type: action_types.EXAMPLE,
                data: response?.data?.data || []
            });
        } catch (error) {
            console.log("ERROR", error);
        }
    };
};
