import StyledContainer from "./styles";
import tickBadge from "../../../styles/images/tick-badge.svg";
import Daimond from "../../../styles/images/Group369.png";
import defaultImg from "../../../styles/images/default-img.svg";
import likeIcon from "../../../styles/images/like-icon.svg";
import HalfTone from "../../../styles/images/half-tone.png";
import { DEV_BASE_URL_IMAGE } from "../../../constants/url";
import { useHistory } from "react-router-dom";
import PlaceBidModal from "../../../app/modules/nft-timed-auction/components/place-bid/place-bid";
import { useState } from "react";
import DropdownComponent from "../../../app/modules/home/components/dropdown-component/dropdown";
import { Modal } from "semantic-ui-react";
import close from "../../../styles/images/close.svg";
import { useDispatch, useSelector } from "react-redux";
import Auth from "../../../utils/NFT/ERC721/Auth";
import AxiosRequest, { REQUESTS } from "../../../utils/hooks/useRequest";
import { MetaMask } from "nft-web3";
import { getAccountDetails, actionLogin } from "../../shared/header/duck/action";
import Torus from "@toruslabs/torus-embed";
import { toast } from "react-toastify";

const BidAutionShotCard = ({
  useAs,
  coverDescriptionText,
  overlayText,
  bidStockNumber = 5,
  bidSftValue = "700SFT",
  likeNumbers = 24,
  bidImage,
  isbidNotForSale = false,
  isLike = false,
  isStackContainer = false,
  hotBid,
  liveAuction,
  popularShots,
}: any) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [token, setToken] = useState(0);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const torus = new Torus({});
  const [like, setLike] = useState(false);

  const handleMarketPlace = (id: string) => {
    history.replace("/marketplace", id);
  };

  const handlePlaceBid = (price: number, token: number) => {
    setShow(true);
    setToken(token);
  };

  const onClose = () => {
    setShow(false);
  };

  const handleUserLike = async (id: string) => {

   if(localStorage.getItem("authToken")){
    const { executeRequest } = AxiosRequest({
      method: REQUESTS.POST,
      url: `user-likes/${!like ? 'like' : 'unlike'}`,
      body: {productId: id},
      token: {"Authorization" : `Bearer ${localStorage.getItem('authToken')}`},
    });

    try {
      const res = await executeRequest();
      if ([200, 201, 203].includes(res?.statusCode)) {
        toast.success(res?.message);
      } else {
        toast.error(
          res?.message === "property sellerProfilePicture should not exist"
            ? "Please upload image"
            : res?.message
        );
      }
    } catch (err: any) {
      toast.error("Something went wrong");
    }
   }
   else{
     setOpen(true);
   }
  }

  const handleMetaMaskLogin = async () => {

    if (window.ethereum) {  
      //window.provider = window.ethereum;
      const wallet = await new MetaMask(window.ethereum);
      const account = await wallet.connectMetaMask();

     localStorage.setItem("account", account);
      if (localStorage.getItem("wallet") !== "MetaMask") {
        localStorage.setItem("wallet", "MetaMask");
        const { executeRequest } = AxiosRequest({
          method: REQUESTS.POST,
          body: { account },
          url: "auth/user/account",
        });

       const result = await executeRequest();
       const auth = new Auth(window.ethereum);
        const authValue = await auth.signMessage(
          `I am signing my one time nonce: ${result?.data?.nonce}`
        );
       dispatch(actionLogin(result, authValue));
        if (wallet.isMetaMaskConnected()) {
          dispatch(getAccountDetails(account));
          setOpen(false);
        }
        else {
          handleLogout();
        }
     }
    } else {
      alert("MetMask is not installed");
      setOpen(false);
    }
  };

  const handleLogout = async () => {
    const wallet = localStorage.getItem("wallet");
    if (wallet !== "MetaMask") {
      await torus.logout();
    }
    localStorage.removeItem("wallet");
    localStorage.removeItem("account");
    localStorage.removeItem("authToken");
    window.location.reload();
  };

  return (
    <StyledContainer isStackContainer={isStackContainer}>
      <PlaceBidModal show={show} onClose={onClose} token={token} />
      <div
        className="bid-card-container"
        {...(useAs === "shots" && { style: { margin: "20px" } })}
      >
        {/* Layer1 head */}
        {/* <div className="bid-card-layer1">
          <div className="bid-card-circle-container tooltip-on-hover">
            <img className="bid-card-circle-image" src={Logo} alt="" />
            <span>
              <img className="bid-card-badge" src={tickBadge} alt="" />
            </span>
            <div className="tooltip">{hotBid?.collection?.displayName}</div>
          </div>
          <div>
            <p>...</p>
          </div>
        </div> */}

        {/* Layer 2 Image with overlay */}
        {/* <div className="bid-card-layer2">
          <div className="bid-card-layer2-image-card">
            <div className=""><img src={defaultImg} alt="" /></div>            
          </div>
          {overlayText && (
            <span className="bid-card-layer2-overlay">{overlayText}</span>
          )}
        </div> */}

        {/* Layer 3 headtext */}
        {/* <div className="bid-card-layer3">
          <div className="title">{coverDescriptionText}</div>
        </div> */}

        {/* Variations starts here */}
        {/* Layer 4 and 5 Stock and bid sft */}
        {useAs === "bids" && (
          <>
            {isbidNotForSale ? (
              <div className="bid-card-layer4-not-for-sale">
                <img src={Daimond} alt="" />
                Not for sale
              </div>
            ) : (
              <>
                <div className="bid-card-layer1">
                  <div className="bid-card-circle-container tooltip-on-hover">
                    {/* <img className="bid-card-circle-image" src={Logo} alt="" /> */}
                    <span>
                      <img className="bid-card-badge" src={tickBadge} alt="" />
                    </span>
                    <div className="tooltip">
                    Collection : {hotBid?.collection?.displayName}
                    </div>
                  </div>
                  <div>
                    <p><DropdownComponent/></p>                   
                  </div>
                </div>
                <div className="bid-card-layer2">
                  <button
                    onClick={() => handleMarketPlace(hotBid?.id)}
                    className="bid-card-layer2-image-card slick-slide"
                  >
                    {hotBid?.mediaType.includes("video") && <video width="320" height="240" autoPlay loop controlsList="nodownload noremoteplayback noplaybackrate" disablePictureInPicture muted src={(hotBid?.mediaFile) || defaultImg}></video>}
                    {hotBid?.mediaType.includes("image") && <img src={(hotBid?.mediaFile) || defaultImg} alt="" />}
                    {hotBid?.mediaType.includes("audio") && <img src={HalfTone} alt="" />}
                  </button>
                  {overlayText && (
                    <span className="bid-card-layer2-overlay">
                      {overlayText}
                    </span>
                  )}
                </div>

                {/* Layer 3 headtext */}
                <div className="bid-card-layer3">
                  <div className="title">{coverDescriptionText}</div>
                </div>
                <div className="bid-card-layer3-span" style={{ paddingLeft: "10px" }}>{hotBid?.title}</div>
                <div className="bid-card-layer4">
                  <div className="bid-card-layer4-sft">
                   {hotBid?.price !== 0 ? `${hotBid?.price}${hotBid?.currency}` : 'Open for bids'}
                  </div>
                  <span>.</span>
                  <div className="bid-card-layer4-stock">
                    <b>{hotBid?.quantity}</b>in stock
                  </div>
                </div>
              </>
            )}
            <div className="bid-card-layer5">
              <button onClick={() => handlePlaceBid(hotBid?.price, hotBid?.tokens[0]?.tokenId)}>
                {isbidNotForSale ? "MAKE ME OFFER" : "Place a bid"}
              </button>
              {/* {isLike && <div className="bid-card-layer5-heart">{likeNumbers}</div>} */}
              <span style={{ display: "flex", marginRight: "6px", cursor:"pointer" }} onClick={() => handleUserLike(hotBid?.id)}>
                <img src={likeIcon} alt="" />
                {hotBid?.likedCount}
              </span>
              {/* <div className="bid-card-layer5-heart"><img src={likeIcon} alt='' /></div> */}
            </div>
          </>
        )}

        {useAs === "aution" && (
          <>
            <>
              <div className="bid-card-layer1">
                <div className="bid-card-circle-container tooltip-on-hover">
                  {/* <img className="bid-card-circle-image" src={Logo} alt="" /> */}
                  <span>
                    <img className="bid-card-badge" src={tickBadge} alt="" />
                  </span>
                  <div className="tooltip">
                  Collection: {liveAuction?.collection?.displayName}
                  </div>
                </div>
                <div>
                  <p>...</p>
                </div>
              </div>
              <div className="bid-card-layer2">
                <button
                  onClick={() => handleMarketPlace(liveAuction?.id)}
                  className="bid-card-layer2-image-card slick-slide"
                >
                  {liveAuction?.mediaType.includes("image") && <img
                    src={
                      (liveAuction?.mediaFile?.startsWith("https")
                        ? liveAuction?.mediaFile
                        : DEV_BASE_URL_IMAGE + liveAuction?.mediaFile) ||
                      defaultImg
                    }
                    alt=""
                  />}
                  {liveAuction?.mediaType.includes("audio") && <img src={HalfTone} alt="" style={{width: 'auto'}}/>}
                  {liveAuction?.mediaType.includes("video") && <video width="320" height="240" autoPlay loop muted controlsList="nodownload noremoteplayback noplaybackrate" disablePictureInPicture src={liveAuction.mediaFile || defaultImg} />}
                </button>
                {overlayText && (
                  <span className="bid-card-layer2-overlay">{overlayText}</span>
                )}
              </div>
              <div className="bid-card-layer3">
                <div className="title">{coverDescriptionText}</div>
              </div>
              <div  className="bid-card-layer3-span" style={{ paddingLeft: "10px" }}>{liveAuction?.title}</div>
              <div className="bid-card-layer4">
                <div className="bid-card-layer4-sft">
                  {liveAuction?.price}
                  {liveAuction?.currency}
                </div>
                <span>.</span>
                <div className="bid-card-layer4-stock">
                  <b>{liveAuction?.quantity}</b>in stock
                </div>
              </div>
            </>

            <div className="bid-card-layer5">
              <button onClick={() => handlePlaceBid(liveAuction?.price, liveAuction?.tokenId)}>
                {isbidNotForSale ? "MAKE ME OFFER" : "Place a bid"}
              </button>
              <span style={{ display: "flex", marginRight: "6px" }}>
                <img src={likeIcon} alt="" />
                {likeNumbers}
              </span>
            </div>
          </>
        )}

        {useAs === "shots" && (
          <>
            <>
              <div className="bid-card-layer1">
                <div className="bid-card-circle-container tooltip-on-hover">
                  {/* <img className="bid-card-circle-image" src={Logo} alt="" /> */}
                  <span>
                    <img className="bid-card-badge" src={tickBadge} alt="" />
                  </span>
                  <div className="tooltip">
                  Collection: {popularShots?.collection?.displayName}
                  </div>
                </div>
                <div>
                  <p>...</p>
                </div>
              </div>
              <div className="bid-card-layer2">
                <button
                  onClick={() => handleMarketPlace(popularShots?.id)}
                  className="bid-card-layer2-image-card slick-slide"
                >
                  {popularShots?.mediaType.includes("image") && <img
                    src={
                      (popularShots?.mediaFile?.startsWith("https")
                        ? popularShots?.mediaFile
                        : DEV_BASE_URL_IMAGE + popularShots?.mediaFile) ||
                      defaultImg
                    }
                    alt=""
                  />}
                  {popularShots?.mediaType.includes("audio") && <img src={HalfTone} alt="" style={{width: 'auto'}}/>}
                  {popularShots?.mediaType.includes("video") && <video width="320" height="240" autoPlay loop controlsList="nodownload noremoteplayback noplaybackrate" disablePictureInPicture muted src={popularShots.mediaFile || defaultImg} />}
                </button>
                {overlayText && (
                  <span className="bid-card-layer2-overlay">{overlayText}</span>
                )}
              </div>
              <div className="bid-card-layer3">
                <div className="title">{coverDescriptionText}</div>
              </div>
              <div  className="bid-card-layer3-span" style={{ paddingLeft: "10px" }}>{popularShots?.title}</div>
              <div className="bid-card-layer4">
                <div className="bid-card-layer4-sft">
                {popularShots?.price !== 0 ? `${popularShots?.price}${popularShots?.currency}` : 'Open for bids'}
                </div>
                <span>.</span>
                <div className="bid-card-layer4-stock">
                  <b>{popularShots?.quantity}</b>in stock
                </div>
              </div>
            </>

            <div className="bid-card-layer5">
              <button onClick={() => handlePlaceBid(popularShots?.price, popularShots?.tokenId)}>
                {isbidNotForSale ? "MAKE ME OFFER" : "Place a bid"}
              </button>
              <span style={{ display: "flex", marginRight: "6px" }}>
                <img src={likeIcon} alt="" />
                {likeNumbers}
              </span>
            </div>
          </>
        )}
      </div>
      <Modal
          size="tiny"
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
        >
      <Modal.Content>
            <Modal.Description>
              <img
                onClick={() => setOpen(false)}
                className="close-span"
                src={close}
                alt="close"
              />
              <h3>MUSICRYPT</h3>
              <p>
                Sign in with one of available wallet providers or create a new
                wallet.
              </p>
              <a href="/#"> What is a wallet?</a>

              <div className="button-span">
                <button className="btn_full" onClick={handleMetaMaskLogin}>
                  METAMASK
                </button>
                <button className="btn_full">ELECTRUM WALLET</button>
                <button className="btn_full">
                  TORUS
                </button>
                <button
                  className="btn_outline"
                >
                  SHOW MORE OPTIONS
                </button>
              </div>
              <p className="para">
                We don’t own your private key and cannot access funds without
                your confirmation
              </p>
            </Modal.Description>
          </Modal.Content>
          </Modal>

    </StyledContainer>
  );
};

export default BidAutionShotCard;
