import styled, { css } from 'styled-components';

const TimerStyledContainer = styled.div`
width: 100%;
    ${css`
    .countTimer-wrap{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
  }
  
  .countTimer-section{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
  }
  .countTimer-section .counter-title {
      font-size: 20px;
      font-family: "Montserrat-SemiBold";
      color: #B7B7B7;
      margin: 0;
      margin-bottom: 20px;
  }
  .countTimer-blk{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 55px;
  }
  
  .countTimer-blk .timer {
      font-size: 32px;
      font-family: "SF-Pro-Rounded-Bold";
      color:  ${props => props.theme.color.primaryfontColor};
  }
  .countTimer-blk .interval {
      font-size: 13px;
      font-family: "Montserrat-SemiBold";
      color: #B7B7B7;
      text-transform: capitalize;
  }
    `}
`;


export default TimerStyledContainer;