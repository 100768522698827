import React, { useEffect, useState } from "react";
// import "./countdown-timer.css";
import TimerStyledContainer from './style';

const TimerCountDown = (props: any) => {
    const { date,title, onEnd = () => {} } = props;
  const calculateTimeLeft = () => {
    const time = date ? date : `2021-12-31`;
    const difference = +new Date(time) - +new Date();
    let timeLeft: any = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  // const [year] = useState(new Date().getFullYear());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents: any = [];

  Object.keys(timeLeft).forEach((interval) => {
    // if (!timeLeft[interval]) {
    //   return;
    // }
    timerComponents.push(
      <div key ={`${interval}${timeLeft[interval]}`+1} className="countTimer-blk">
        <div className="timer">{ interval === 'seconds' && timeLeft[interval] < 10 ? `0${timeLeft[interval]}`  :timeLeft[interval]} </div>
        <div className="interval">{interval} </div>
      </div>
    );
  });

  return (
    <TimerStyledContainer> 
    <div className="countTimer-section">
      <h2 className="counter-title"> {title ? title : timerComponents.length ? 'Auction ends in' : 'Auction has ended'}  </h2>
      <div className="countTimer-wrap">
        {timerComponents.length !== 0 && timerComponents }
        {/* {timerComponents.length ? timerComponents : <span>Time's up!</span>} */}
        {timerComponents.length === 0 &&  onEnd() }
      </div>
    </div>
    </TimerStyledContainer>
  );
};

export default TimerCountDown;
