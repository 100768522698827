import * as action_types from "./constant";

export const exampleAction = () => {
    return (dispatch: any) => {
        dispatch({
            type: action_types.EXAMPLE,
            data: "I am from example reducer",
        });
    };
};
