import styled, {css} from 'styled-components';

 const TabStyledContainer = styled.div`
 width: 100%;
    ${css`
    .TabSection-tab-div{
        width: 100%;
        display: flex;
    }
     .TabSection-tab-div>div{
        width: 100%;
    }
     .TabSection-tab-div .menu{   
        background: transparent;    
        width: 100%;   
        margin-top: 60px !important;
        border-bottom: 1px solid ${props => props.theme.mode === "dark" ? "#151515" : "#f2f2f2"};       
    }
     .TabSection-tab-div .ui.menu .item {   
        padding: 0;
        margin: 0;  
        justify-content: center;
        font-size: 15px;
        color: #AFAFAF;
        font-family: "Montserrat-Bold";
        text-decoration: none;
        border-radius: 0;
        margin-right: 80px;
        position: relative;
    }
     .TabSection-tab-div .ui.menu .active.item{   
        color: ${props => props.theme.color.primaryfontColor};
    }
     .TabSection-tab-div .ui.tab p{
        margin-bottom: 30px;
    }
    .TabSection-tab-div .ui.tab.active{
        background: transparent !important;
    }
    .TabSection-tab-div .ui.menu a.item:hover {
        color:${props => props.theme.color.primaryfontColor};
    }
    .TabSection-tab-div .ui.menu .active.item::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: ${props => props.theme.color.primaryfontColor};
        display: flex;
        bottom: -1px;
    }
    
     .TabSection-tab-div .ui.segment{
       padding: 0;
       margin: 0;
    }
    
    `}
`;


export default TabStyledContainer;