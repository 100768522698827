import StylesContainer from "./styles";
import { Input } from "semantic-ui-react";
import { useEffect, useState } from "react";

const InputSet = ({addNew, id, removeSet}:any) => {
    const [firstSet, setFirstSet] = useState("");
    const [secondSet, setSecondSet] = useState("");
    const [lock, setLock] = useState(true);

    useEffect(() => {
        if (firstSet) {
            if (secondSet.length > 0 && lock) {
                addNew(firstSet,secondSet, id);
                setLock(false);
            }
            if (firstSet.length && secondSet.length === 0) {
                removeSet(id + 1);
                setLock(true);
            }
        }
    }, [secondSet]);

  return (
    <div className="inputset-container">
      <Input
        inverted
        value={firstSet}
        onChange={e => setFirstSet(e.target.value)}
        placeholder="e.g.Size"
      />
      <Input
        inverted
        value={secondSet}
        onChange={e => setSecondSet(e.target.value)}
        placeholder="e.g.M"
      />
    </div>
  );
};

const CollectibleSubTypeProperties = ({ title, description }: any) => {
  const initObject = {
    id: 0,
    setOneValue: "",
    setTwoValue: "",
    canAdd: false
  };
  const [iSets, setIsets] = useState([initObject]);

  const addNew = (fv:any, sv:any, id:any) => {
    console.log("fv sv id", fv,sv,id);
    setIsets([...iSets, {...initObject, id: (iSets.length && iSets[iSets.length - 1].id + 1)}]);
  }

  const removeSet = (id:any) => {
    let toDel = iSets.findIndex(e => e.id === id);
    let local = Array.from(iSets);
    local.splice(toDel, 1);
    setIsets(local);
  }

  console.log("iSets", iSets);

  return (
    <StylesContainer>
      <div className="collectible-properties-sub-type-container">
        <div className="collectible-properties-sub-type">
          <p>{title}</p>
          <p></p>
        </div>
        <p className="collectible-properties-sub-type-description">
          {description}
        </p>

        <div>
            {iSets.map(e => <InputSet addNew={addNew} id={e.id} removeSet={removeSet} />)}
        </div>
      </div>
    </StylesContainer>
  );
};

export default CollectibleSubTypeProperties;
