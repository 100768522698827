import StyledContainer from "./styles";
import BidAutionShotCard from "../../../../../components/cards/bidAutionShotCard/bidAutionShotCard";
import TitleBlock from "../../../home/components/title-component/title";
import hotCollection from "../../../../../styles/images/hot-collection.png";


const CollectionUserItemCard = ({ tabName, data }: any) => {
  return data && data?.length
    ? data.map((e: any) => {
        let randomeColor = Math.floor(Math.random() * 16777215)
          .toString(16)
          .padStart(6, "0");
        return tabName === "collections" || tabName === "users" ? (
          <StyledContainer randomeColor={randomeColor}>
            <div className="collection-user-card-container">
              <div className="collection-user-card-container-upper"></div>
              <div className="collection-user-card-container-logo">
                <img
                  src={
                    tabName === "collections"
                      ? e.sellerProfilePicture
                      : e.profileImg
                  }
                  alt=""
                />
              </div>
              <div className="collection-user-card-container-lower">
                {tabName === "collections" ? e.displayName : e.name}
              </div>
            </div>
          </StyledContainer>
        ) : (
          tabName === "products" && (
            <>
              <TitleBlock
                title={"Popular Shots"}
                subTitle={"Explore more shots"}
                image={hotCollection}
                filter={true}
              />
              <BidAutionShotCard useAs="shots" popularShots={e} />
            </>
          )
        );
      })
    : `No ${tabName} found`;
};

export default CollectionUserItemCard;
