import * as action_types from "./constant";
import axios from "axios";
import { DEV_BASE_URL } from "../../../../constants/url";

export const getAccountDetails = (page: string) => {
    return (dispatch: any) => {
        try {
            const response = page;
            dispatch({
                type: action_types.ACCOUNTDETAILS,
                data: response || []
            });
        } catch (error) {
            console.log("ERROR", error);
        }
    };
};

export const actionLogin = (result:any, signature:any) => {
    const url = `${DEV_BASE_URL}/auth/login`;
    const data = {
        account: result?.data?.account,
        message: `I am signing my one time nonce: ${result?.data?.nonce}`,
        signature: signature
      }
    return async (dispatch: any) => {
        try {
            const response = await axios.post(url, data);
            localStorage.setItem('authToken', response?.data?.data?.token);
            dispatch({
                type: action_types.ACTIONLOGIN,
                data: response?.data?.data || []
            });
        } catch (error) {
            console.log("ERROR", error);
        }
    };
};

