import * as action_types from "./constant";

const initialState = {
    exampleText: '',
};

const nftFixedReducer =  (state = initialState, action: any) => {
    const { type, data } = action;
    switch (type) {
        case action_types.EXAMPLE:
            return { ...state, exampleText: data };
        default:
            return state;
    }
};

export default nftFixedReducer;
