import styled, {css} from 'styled-components';

 const HotColectionStyledContainer = styled.div`
    ${css`
    .collection_heading_wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 15px 0;
    }
    .mt{
        padding: 15px 0 30px 0;
    }
    .collection_title h2 {
        font-size: 25px;
        font-family: 'MADE-Outer-Sans-Regular';
        color: ${props => props.theme.color.primaryfontColor};
        margin-bottom: 5px;
        background: transparent;
    }
    .explore_collection a{
        font-size: 13px;
        color: #AFAFAF;
        cursor: pointer;
        text-decoration: none;
    }
    
    .collection_card_block{
        width: 220px;
        /* width: calc( 100% - 20px); */
        height: 230px;
        border: 1px solid #BCBCBC27;
        border-radius: 10px;
        margin: 0 auto;

    }
    .collection_card{
        display: block;
        width: calc( 100% - 12px);;
        height: calc( 100% - 12px);;
        margin: 6px;
        /*background: #1A4576  linear-gradient(180deg, #23405C00 0%, #00000088 100%) 0% 0% no-repeat padding-box;*/
        text-align:center;
    }
    .collection_card .collection-cover {
        height: 90px;
        width: 100%;
        background-color: rgba(247, 247, 247, 1);
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }
    
    .collection_card .img_block {
        width: 57px;
        height: 57px;
        border-radius: 100%;
        background-color: #ff0c0027;
        border: 1px solid #BCBCBC27;
        position: relative;
        margin-bottom: 10px;
        display:inline-block;
        margin-top: -30px;
        overflow: hidden;
    }
    
    .collection_card .img_block img {
        position: absolute;
        right: 0;
        bottom: 0;
        background: transparent;
    }
    
    .collection_card h3 {
        font-size: 15px;
        font-family: 'Montserrat-Bold';
        color: ${props => props.theme.color.primaryfontColor};
        margin: 0;
        background: transparent;
    }
    @media only screen and (max-width: 520px) {
       
        .collection_title h2 {
            font-size: 15px;
        }
    }
    `}
`;


export default HotColectionStyledContainer;