import styled, {css} from 'styled-components';

 const TitleCompStyledContainer = styled.div`
    ${css`
    .title_heading_wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 15px 0;
    }
    .collection_title { 
        display: flex;
        align-items: center;
    }
    .collection_title h2 {
        font-size: 25px;
        font-family: 'MADE-Outer-Sans-Regular';
        color:  ${props => props.theme.color.primaryfontColor};
        margin-bottom: 5px;
    }
    .collection_title h2 img {
        position: relative;
        top: 5px;
        left: 5px;
    }
    .explore_collection a{
        font-size: 13px;
        color: ${props => props.theme.color.tertiaryColor};
        cursor: pointer;
        text-decoration: none;
    }

    @media only screen and (max-width: 520px) {
        .title_heading_wrapper {
           flex-direction: column;
        } 
        .collection_title h2 {
            font-size: 15px;
        }
    }
    `}
`;


export default TitleCompStyledContainer;