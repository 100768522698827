import { useState } from "react";
// import dot from "../../../../../styles/images/dot.svg";
// import tickBadge from "../../../../../styles/images/tick-badge-big.svg";
// import defaultImg from "../../../../../styles/images/default.svg";
// import './tab-list.css';
import AuctionTabStyledContainer from './style';
import { Button, Menu, Tab } from "semantic-ui-react";
import BidAutionShotCard from "../../../../../components/cards/bidAutionShotCard/bidAutionShotCard";
import Slider from "react-slick";
import { useSelector } from "react-redux";
const TabPortfolio = () => {
  const categories = useSelector((state: any) => state.homeReducer.categories);
  const [selectedMusicCategory, setSelectedMusicCategory] = useState("ART"); // TODO::expecting all from API
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const bidSettings = { ...settings, slidesToShow: 4, slidesToScroll: 4 };
  const musicCategorySettings = {
    ...settings,
    slidesToShow: 7,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,          
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  const handleMusciCategory = (category: any) => {
    setSelectedMusicCategory(category);
  };
  const panes = [
    {
      
      menuItem: (
              <Menu.Item key='On Sale'>
                On Sale<span className="count">4</span>
              </Menu.Item>
            ),
      render: () => <Tab.Pane attached={false}>
         <div className="home-category-slide">
          <Slider {...musicCategorySettings}>
            {categories.map((category:any) => (
              <div key={category?.name} style={{ width: "100px" }}>
                <Button
                  size="medium"
                  content={category?.name}
                  primary={category?.name === selectedMusicCategory}
                  secondary={category?.name !== selectedMusicCategory}
                  onClick={() => handleMusciCategory(category?.name)}
                />
              </div>
            ))}
          </Slider>
        </div>
     <div className="home-bids-slide">
          <Slider {...bidSettings}>
            {[...Array(18)].map((e) => (
              <div>
                <BidAutionShotCard useAs="bids" />
              </div>
            ))}
          </Slider>
        </div>
        
        </Tab.Pane>,
    },
    {
      menuItem: (
        <Menu.Item key='Owned'>
          Owned<span className="count">4</span>
        </Menu.Item>
      ),
      render: () => <Tab.Pane attached={false}>
        <div className="home-category-slide">
          <Slider {...musicCategorySettings}>
            {categories.map((category:any) => (
              <div key={category?.name} style={{ width: "100px" }}>
                <Button
                  size="medium"
                  content={category?.name}
                  primary={category?.name === selectedMusicCategory}
                  secondary={category?.name !== selectedMusicCategory}
                  onClick={() => handleMusciCategory(category?.name)}
                />
              </div>
            ))}
          </Slider>
        </div>
     <div className="home-bids-slide">
          <Slider {...bidSettings}>
            {[...Array(18)].map((e) => (
              <div>
                <BidAutionShotCard useAs="bids" />
              </div>
            ))}
          </Slider>
        </div>
       
      </Tab.Pane>,
    },
    {
      menuItem: (
        <Menu.Item key='Created'>
          Created<span className="count">4</span>
        </Menu.Item>
      ),
      render: () => <Tab.Pane attached={false}>
        <div className="home-category-slide">
          <Slider {...musicCategorySettings}>
            {categories.map((category:any) => (
              <div key={category?.name} style={{ width: "100px" }}>
                <Button
                  size="medium"
                  content={category?.name}
                  primary={category?.name === selectedMusicCategory}
                  secondary={category?.name !== selectedMusicCategory}
                  onClick={() => handleMusciCategory(category?.name)}
                />
              </div>
            ))}
          </Slider>
        </div>
     <div className="home-bids-slide">
          <Slider {...bidSettings}>
            {[...Array(18)].map((e) => (
              <div>
                <BidAutionShotCard useAs="bids" />
              </div>
            ))}
          </Slider>
        </div>
       
      </Tab.Pane>,
    },
    {
      menuItem: (
        <Menu.Item key='Liked'>
          Liked<span className="count">4</span>
        </Menu.Item>
      ),
      render: () => <Tab.Pane attached={false}>
           <div className="home-category-slide">
          <Slider {...musicCategorySettings}>
            {categories.map((category:any) => (
              <div key={category?.name} style={{ width: "100px" }}>
                <Button
                  size="medium"
                  content={category?.name}
                  primary={category?.name === selectedMusicCategory}
                  secondary={category?.name !== selectedMusicCategory}
                  onClick={() => handleMusciCategory(category?.name)}
                />
              </div>
            ))}
          </Slider>
        </div>
     <div className="home-bids-slide">
          <Slider {...bidSettings}>
            {[...Array(18)].map((e) => (
              <div>
                <BidAutionShotCard useAs="bids" />
              </div>
            ))}
          </Slider>
        </div>
       
      </Tab.Pane>,
    }
  ]
  const TabExampleAttachedFalse = () => (
    <Tab menu={{ attached: false }} panes={panes} />
  )
    return (
      <AuctionTabStyledContainer>  
        <div className="tab-port">
     <TabExampleAttachedFalse/>
     </div>
        </AuctionTabStyledContainer>
    )
  }

  export default TabPortfolio;