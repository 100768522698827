//import React, { useState, useEffect } from "react";
//import { Container, Button } from "semantic-ui-react";
import "./detail-left.css";
import defaultImg from "../../../../../styles/images/default-img.svg";
import StyledContainer from "./styles";
const DetailLeft = () => {
  //   const { image, title, subTitle } = props;
  return (
    <StyledContainer>
 <div className="market_img_block">
        <div className="market_img_card">
          <div className="img_block">
            <img src={defaultImg} alt="" />
          </div>
        </div>
      </div>
    </StyledContainer>
     
  );
};

export default DetailLeft;
