import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { marketPlaceAction } from "./duck/action";
import { Container } from "semantic-ui-react";
import DetailRight from "./components/detail-right/detail-right";
import DetailLeft from "./components/detail-left/detail-left";
import "./fixedPrice.css";
import  FixedPriceCss  from './fixedPrice'
const NftFixedPricePage = () => {
  const dispatch = useDispatch();
 // const exampleText = useSelector((state: any) => state.marketPlaceReducer.exampleText);
  
  useEffect(() => {
    dispatch(marketPlaceAction());
  }, [dispatch]);

  return (
    <FixedPriceCss>
      <Container>
        <div className="detail-main">
          <div className="detail-left">
            <DetailLeft />
          </div>
          <div className="detail-right">
            <DetailRight />
          </div>
        </div>
      </Container>
    </FixedPriceCss>
     
  );
};

export default NftFixedPricePage;
