// import React, { useState, useEffect } from "react";
// import "./tab-section.css";
import { Tab } from "semantic-ui-react";
import TabStyledContainer from "./style";

const TabSection = (props: any) => {
  const { tabData = [] } = props;

  const panes = tabData.map((data: any) => {
    return {
      menuItem: data.header,
      render: () => <Tab.Pane attached={false}>{data.childran}</Tab.Pane>,
    };
  });

  const TabBlock = () => <Tab menu={{ attached: false }} panes={panes} />;
  return (
    <TabStyledContainer>
      <div className="TabSection-tab-div">{tabData && <TabBlock />}</div>
    </TabStyledContainer>
  );
};

export default TabSection;
