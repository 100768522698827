import styled, { css } from "styled-components";

export default styled.div`
    ${css`
    .headerBlock{
        border-bottom: 1px solid #707070;
        padding:10px 0;
        position: fixed;
        top: 0;
        z-index: 9999;
        background: ${props => props.theme.mode === "dark" ? "black" : "#ffffff"};
    }
    .wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        margin: auto;
        justify-content: space-between;
    }
    
    .menuWrapper {
        display: flex;
        align-items: center;
    }
    
    .subWrapper {
        display: flex;
        align-items: center;
    }
    
    .subWrapper a.menuItems {
        font-size: 12px;
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"} !important;
        text-transform: uppercase;
        cursor: pointer;
        text-decoration: none;
        padding: 8px;
        margin: 0 5px;
        font-family: "Montserrat-Bold";
    }
    
    .logoText {
        font-size: 19px;
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"} !important;
        margin-right: 30px;
        font-family: 'MADE-Outer-Sans-Regular';
        cursor: pointer;
    }
    
    .subWrapper a span {
        background: #094eff;
        font-size: 10px;
        padding: 2px;
    }
    
    .buttonWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    
    .btn_outline {
        border-radius: 18px;
        border: 1px solid #E56BCC;
        height: 35px;
        line-height: 10px;
        font-size: 12px;
        color: #E56BCC;
        padding: 0 12px;
        cursor: pointer;
        background-color: transparent;
        margin-right: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    
    /* .btn_outline:hover {
        background-color: #E56BCC;
        color: #ffffff;
    } */
    .btn_full {
        border-radius: 18px;
        background-color: #E56BCC;
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "#FFFFFF"} !important;;
        border: 1px solid #E56BCC;
        font-size: 12px;
        height: 35px;
        line-height: 10px;
        padding: 0 12px;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    
    /* .btn_full:hover {
        background-color: transparent;
        color: #E56BCC;
    } */
    
    
    
    
    .checkbox {
      position: relative;
      cursor: pointer;
      font-size: 22.25px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      display: flex;
      align-items: center;
      margin: 5px 0;
      color: #AFAFAF;
      font-family: "Montserrat-Medium";
    }
    
    .checkbox input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    
    .checkbox input:checked ~ .checkmark {
      background-color: transparent;
    }
    
    .checkbox input:checked ~ .checkmark:after {
      display: block;
      opacity: 1;
    }
    
    .checkbox .checkmark {
      width: 21px;
      height: 21px;
      position: relative;
      background: transparent;
      border-radius: 7px;
      border: 1px solid #E154C4;
      float: left;
      transition: 0.25s;
    }
    
    .checkbox .checkmark:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      background: #E154C4;
      width: 12px;
      height: 12px;
      border-radius: 2px;
      opacity: 0;
      transition: 0.25s;
    }
    
    .checkbox span {
      font-size: 22.25px;
      color: #AFAFAF;
      font-family: "Montserrat-Medium";
      margin-left: 10px;
    }
    .p-0{
      padding: 0;
    }
    .btn_menu{
      background: transparent;
      border: transparent;
      margin-right: 10px;
      display : none;
    }
    .btn_menu img{
      width:20px;
    }
    .main-block {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;      
    }
    .overlay{
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      display: block;
      background: rgb(0 0 0 / 74%);
      z-index: 1;
      cursor: pointer;
    }
    .main-block .mobile-menu{
      display: flex;
      flex-direction: column;
      width: 80%;
      width: 80%;
      padding: 25px 10px;
      background: #E154C4;
      height: 100vh;   
      z-index: 2;   
      position: relative;
    }
    .main-block .mobile-menu a{     
      font-size: 12px;
      color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"} !important;;
      text-transform: uppercase;
      cursor: pointer;
      text-decoration: none;
      padding: 10px;
      margin: 0 5px;
      font-family: "Montserrat-Bold";
    }
    .close-menu{
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 3;
      cursor: pointer;
    }
    @media only screen and (max-width: 991px) {
     .menuList{
        display: none;
     }
     .btn_menu{
      display: block;
    }
    }
    @media only screen and (max-width: 798px) {
      .logoText {
        font-size: 14px;       
        margin-right: 20px;        
      }
      .btn_menu{
        display: block;
      }
  }
    `}

`;