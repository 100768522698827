import { Popup } from "semantic-ui-react";
// import "./notification.css";
//import back from "../../styles/images/back.svg";
import CummunityStyledContainer from "./style";
const CummunityPopup = (props: any) => (
  <Popup
    position="bottom right"
    style={{ marginTop: '25px'}}
    on="click"
    pinned
    trigger={
      // <button className="btn_outline p-0 btn-img">
     <span className="cummunity_text">COMMUNITY</span>  
      // </button>
    }
  >
    <Popup.Content>
      <Popup.Content>
        <CummunityStyledContainer>
          <div className="cummunity-pop-div">
            <div className="cummunity-inner-pop">
              <h4>MUSICRYPT TOKES</h4>
            </div>
            <div className="cummunity-inner-pop">
            <h4>DISCUSSION</h4>
            </div>
            <div className="cummunity-inner-pop">
            <h4>VOTING</h4>
            </div>
            <div className="cummunity-inner-pop">
            <h4>SUGGEST FEATURE</h4>
            </div>
            <div className="cummunity-inner-pop">
            <h4>MUSICRYPT PROTOCOL</h4>
            </div>
            <div className="cummunity-inner-pop">
            <h4>SUBSCRIBE</h4>
            </div>
          </div>
        </CummunityStyledContainer>
      </Popup.Content>
    </Popup.Content>
  </Popup>
);

export default CummunityPopup;
