// import React, { useState, useEffect } from "react";
import defaultImg from "../../../../../styles/images/default-img.svg";
import DetailLeftCssContainer from './style';
const DetailLeft = (props: any) => {
  //   const { image, title, subTitle } = props;
  return (
    <DetailLeftCssContainer> 
      <div className="market_img_block">
        <div className="market_img_card">
          <div className="img_block">
            <img src={defaultImg} alt="" />
          </div>
        </div>
      </div>
      </DetailLeftCssContainer>
  );
};

export default DetailLeft;
