import * as action_types from "./constant";

const initialState = {
    categories: [],
    topSellers: [],
    hotBidsData: [],
    liveAuctionData: [],
    popularShotsData : [],
    topArtistsData: [],
    hotBidsPaginationData: [],
    liveAuctionPaginationData: [],
};

const homeReducer =  (state = initialState, action: any) => {
    const { type, data, paginationData } = action;
    switch (type) {
        case action_types.CATEGORIES:
            return { ...state, categories: data };
        case action_types.GETTOPSELLERS:
            return { ...state, topSellers: data };
        case action_types.GETHOTBIDS:
            return { ...state, hotBidsData: [...state.hotBidsData, ...data], hotBidsPaginationData: paginationData };
        case action_types.GETLIVEAUCTION:
                return { ...state, liveAuctionData: [...state.liveAuctionData, ...data], liveAuctionPaginationData: paginationData };
        case action_types.GETPOPULARSHOTS:
                return { ...state, popularShotsData: data };
        case action_types.GETTOPARTISTS:
                return { ...state, topArtistsData: data };                     
        default:
            return state;
    }
};

export default homeReducer;
