import styled, { css } from "styled-components";

export default styled.div`
width: 100%;
    ${css`
    .market_img_block{
        display: flex;
        /* width: 100%; */
        width: calc( 100% - 50px);
        height: 500px;
        border: 1px solid #BCBCBC27;
        border-radius: 6px;
        /* margin: 0 10px; */
        margin-right: 25px;
    }
    .market_img_card{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: calc( 100% - 30px);;
        height: calc( 100% - 30px);;
        margin: 15px;

        audio {
            // background: #000;
            // display: none;
        }
        audio::-webkit-media-controls-panel {
            background-color: ${props => props.theme.mode === "dark" ? "#000000" : "#ffffff"};
        }
        audio::-webkit-media-controls-mute-button,
        audio::-webkit-media-controls-play-button {
            background-color: #E154C4;
            border-radius: 50%;
        }
        audio::-webkit-media-controls-timeline-container {
            color: red !important;
        }
        audio::-webkit-media-controls-current-time-display,
        audio::-webkit-media-controls-time-remaining-display {
            color: #E154C4;
        }

        audio::-webkit-media-controls-timeline {
            background-color: #E154C4;
            border-radius: 25px;
            margin-left: 10px;
            margin-right: 10px;
        }
        audio::-webkit-media-controls-volume-slider {
            background-color: #E154C4;
            border-radius: 25px;
            padding-left: 8px;
            padding-right: 8px;
        }
    }
    @media only screen and (max-width: 991px) {
       
        .market_img_block{       
            width: 100%;      
            margin-right: 0;
        }
    }
    `}

`;