import React from "react";
import { Modal } from "semantic-ui-react";
import infoIcon from "../../../../../styles/images/question-icon.svg";
import close from "../../../../../styles/images/close.svg";
const PlaceBidModal = (props: any) => {
  const { show, onClose } = props;
  const [succes, setSuccess] = React.useState(false);
  const [amount, setAmount] = React.useState();
  if (!show) {
    return null;
  }
  const addFund = () => {
    setSuccess(true);
  };
  return (
    <Modal
      size="tiny"
      onClose={() => {
        onClose(false);
        setSuccess(false);
      }}
      onOpen={() => {
        onClose(true);
        setSuccess(false);
      }}
      open={show}
    >
      <Modal.Content>
        <Modal.Description>
          <img
            onClick={onClose}
            className="close-span"
            src={close}
            alt="close"
          />
          <div className="place-bid-div">
            {succes && (
              <React.Fragment>
                <p className="place-bid-p">
                  {" "}
                  <span>ThisNFT-Rules</span> by <span>The Bunni Lama</span>
                </p>
                <h4 className="place-bid-title place_bid_scc">
                  You have successfully placed a bid for 0.06 wETH
                </h4>
                <p className="place-bid-p">
                  Your bid will be reviewed by the seller. If the seller accepts
                  your bid, you will receive a notification from your wallet and
                  the transaction will be initiated.
                </p>
              </React.Fragment>
            )}
            {!succes && (
              <React.Fragment>
                <h3 className="place-bid-title">Place a bid</h3>
                <p className="place-bid-p">
                  You are about to place a bid for:
                  <br /> <span>ThisNFT-Rules</span> by{" "}
                  <span>The Bunni Lama</span>
                </p>
              </React.Fragment>
            )}
            <div className="place-bid-tab-div">
              <div className="checkout-div">
                <div className="form-div">
                  <label>YOUR BID *</label>
                  <input
                    type="text"
                    className="tab-input"
                    placeholder=""
                    pattern="[+-]?\d+(?:[.,]\d+)?"
                    value={amount}
                    onChange={({target: {value}}) => setAmount((prevState: any) => {
                      const regex = new RegExp(/^[+-]?\d*(?:[.,]\d*)?$/);
                      if(regex.test(value)) {
                        return value;
                      }
                      return prevState;
                    })}
                  />
                  <span className="bid-input-icon">
                    <img src={infoIcon} alt="WETH" /> WETH
                  </span>
                </div>
                <span className="bid-input-info">
                  Must be at least 0.06 wETH
                </span>
                <div className="pay-div">
                  <div className="inner-pay-div">
                    <div className="body-div">
                      <div className="inner-body-div">
                        <div className="left-div com-div">
                          Your bidding balance
                        </div>
                        <div className="right-div com-div">0 wETH</div>
                      </div>
                      <div className="inner-body-div">
                        <div className="left-div com-div">Your balance</div>
                        <div className="right-div com-div">0 ETH</div>
                      </div>
                      <div className="inner-body-div">
                        <div className="left-div com-div">Service fee</div>
                        <div className="right-div com-div">0.0015 wETH</div>
                      </div>
                      <div className="inner-body-div">
                        <div className="left-div com-div">You will pay</div>
                        <div className="right-div com-div">0.0615 wETH</div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => {
                    // succes ? [onClose(false), setSuccess(false)] : addFund();
                    if (succes) {
                      onClose(false);
                      setSuccess(false);
                    } else {
                      addFund();
                    }
                  }}
                  className="btn_full"
                >
                  {succes ? "CLOSE" : "ADD FUNDS"}
                </button>
                {!succes && <div className="fund-div">Not enough funds</div>}
              </div>
            </div>
          </div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default PlaceBidModal;
