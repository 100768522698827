import styled, { css } from "styled-components";

export default styled.div`
    ${css`
    .collectible-properties-sub-type-container {
        font-family: 'Montserrat-Bold';
    }
    .collectible-properties-sub-type {
        display: flex;
        justify-content: space-between;
        text-align: left;
        font-size: 20px;
        letter-spacing: 1.33px;
        text-transform: uppercase;
        margin-top: 50px;
        margin-bottom: -10px;
    }
    .collectible-properties-sub-type-description {
        text-align: left;
        letter-spacing: 0px;
        color: #AFAFAF !important;
    }
    
    .ui.input>input {
        background-color: #0000;
        font-family: 'Montserrat-Bold';
        // opacity: 0.4;
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};
        // border: 1px solid white;
        margin-bottom: 10px;
       
    }
    .ui.dropdown.label {
        background-color: #0000;
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};
    }
    .inputset-container {
        display: flex;
        justify-content: space-between;
    }
    .inverted{
        border: 1px solid #707070;
    }
    
    `}
`;