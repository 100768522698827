import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  MultiSelectWrapper,
  MultiSelectListWrapper,
  MultiSelectList,
} from "./MultiSelect.styles";
import MultiSelectDropdown from "./MultiSelectDropdown";
import MultiSelectListButtons from "./MultiSelectListButtons";
import MultiSelectListItem from "./MultiSelectListItem";
import MultiSelectFooter from "./MultiSelectFooter";
import useMultiSelect from "./useMultiSelect";

const MultiSelect = ({
  list,
  dropdownButtonText,
  isRightAligned,
  selectAllButtonText,
  onOptionChanged,
  onSelectionApplied,
  resetButtonText,
  applyButtonText,
  onOverAllReset,
}: any) => {
  const {
    isDropdownOpened,
    checkedItems,
    toggleDropdown,
    selectAll,
    resetSelections,
    handleInputChange,
  } = useMultiSelect({ list });
  const listItems: any = [];
  const keyEvents = {
    up: () => {
      const activeElementIndex = listItems.findIndex((item: any) => {
        return item === document.activeElement;
      });
      listItems[activeElementIndex - 1] &&
        listItems[activeElementIndex - 1].focus();
    },
    down: () => {
      const activeElementIndex = listItems.findIndex((item: any) => {
        return item === document.activeElement;
      });
      listItems[activeElementIndex + 1] &&
        listItems[activeElementIndex + 1].focus();
    },
    home: () => {
      listItems[0].focus();
    },
    end: () => {
      listItems[listItems.length - 1].focus();
    },
  };
  const checkedItemsQuantity = Object.keys(checkedItems).filter(
    (itemName) => checkedItems[itemName]
  ).length;

  useEffect(() => {
    if (isDropdownOpened) {
      listItems[0].focus();
    }
  }, [isDropdownOpened]);

  useEffect(() => {
    onOptionChanged && onOptionChanged(checkedItems);
  }, [checkedItems]);

  const handleApplyClick = () => {
    onSelectionApplied(checkedItems);
    toggleDropdown();
  };

  const multiSelectDropdownProps = {
    className: "multiselect-button-dropdown",
    text: dropdownButtonText,
    quantity: checkedItemsQuantity,
    toggleDropdown: toggleDropdown,
    isOpened: isDropdownOpened,
  };
  const multiSelectListProps = {
    role: "listbox",
    tag: "ul",
    hasFooter: !!onSelectionApplied,
    className: "multiselect-list",
    keyEvents: { ...keyEvents },
  };
  const multiSelectFooterProps = {
    applyButtonText: applyButtonText,
    resetButtonText: resetButtonText,
    disableReset: !Object.values(checkedItems)?.some(e => e === true),
    disableApply: !Object.values(checkedItems)?.some(e => e === true),
    resetSelections: resetSelections,
    handleApplyClick: handleApplyClick
  };

  /* Outside click event */
  const box = useRef(null);
  const handleOutsideClick = (event: any) => {
    //@ts-ignore
    if (box.current && !box.current?.contains(event.target)) {
      toggleDropdown();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", (e) => handleOutsideClick(e));
    return () => {
      document.removeEventListener("mousedown", (e) => handleOutsideClick(e));
    };
  }, [handleOutsideClick]);

  useEffect(() => {
    if (onOverAllReset) {
      resetSelections();
    }
  }, [onOverAllReset]);

  return (
    <MultiSelectWrapper className="multiselect-button-dropdown-wrapper">
      <MultiSelectDropdown {...multiSelectDropdownProps} />
      {isDropdownOpened && (
        <MultiSelectListWrapper
          ref={box}
          {...(isRightAligned ? { isRightAligned } : {})}
          className="multiselect-section-wrapper"
        >
          <MultiSelectListButtons
            selectAll={selectAll}
            selectAllButtonText={selectAllButtonText}
            resetSelections={toggleDropdown}
            resetButtonText={resetButtonText}
          />
          <MultiSelectList {...multiSelectListProps}>
            {list.map((listItem: any, index: any) => {
              const { label, id, name } = listItem;
              const checked = checkedItems[id];
              const key = `${id}-${index}`;
              const multiSelrtectListProps = {
                className: "multiselect-list-item",
                label: label,
                key: key,
                id: id,
                name: name,
                handleInputChange: handleInputChange,
                checked: checked,
              };
              return (
                <MultiSelectListItem
                  {...multiSelrtectListProps}
                  ref={(el) => {
                    listItems[index] = el;
                    return el;
                  }}
                />
              );
            })}
          </MultiSelectList>
          {onSelectionApplied && (
            <MultiSelectFooter {...multiSelectFooterProps} />
          )}
        </MultiSelectListWrapper>
      )}
    </MultiSelectWrapper>
  );
};

MultiSelect.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      checked: PropTypes.bool.isRequired,
    })
  ).isRequired,
  dropdownButtonText: PropTypes.string.isRequired,
  isRightAligned: PropTypes.bool,
  selectAllButtonText: PropTypes.string,
  onOptionChanged: PropTypes.func,
  onSelectionApplied: PropTypes.func,
  resetButtonText: PropTypes.string,
  applyButtonText: PropTypes.string,
};

export default MultiSelect;
