// import React, { useState, useEffect } from "react";
import { Container } from "semantic-ui-react";
import "./title.css";
import StyledContainer from "./styles";
import TitleCompStyledContainer from './style';
import FiltersComponent from '../filters-component/filters';
import SortComponent from "../sort-component/sort";
const TitleBlock = (props: any) => {
  const { image, title, subTitle, filter= false } = props;
  return (
    <StyledContainer>
    <Container>
      <TitleCompStyledContainer> 
      <div className="title_heading_wrapper">
        <div className="collection_title">
          <h2>{title}  <img src={image} alt={title}  /> </h2>
         {filter && <FiltersComponent />} 
        </div>
        <div className="explore_collection">
          {/* <a href="/#">{subTitle}</a> */}
          {filter ?  <SortComponent/> : (<a href="/#">{subTitle}</a>) } 
        </div>
      </div>
      </TitleCompStyledContainer>
    </Container>
    </StyledContainer>
    
  );
};

export default TitleBlock;
