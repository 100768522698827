import styled, { css } from "styled-components";

export default styled.div<any>`
    ${css`
    .clbt-logo-card-container {
        background: #E154C4 0% 0% no-repeat padding-box;
        border-radius: 5px;
        opacity: 1;
        width: 216px;
        height: 171px;
        padding: 40px;
        text-align: center;
        font-family: 'Montserrat-Bold';
        cursor: pointer;
    }
    .clbt-logo-card-container-main-text{
        margin-top: 15px;
        font-size: 17px;
        margin-bottom: 4px;
    }
    
    .clbt-logo-card-container-sub-text{
        font-size: 13px;
        opacity: 0.7;
    }
    
    .clbt-logo-card-container-unselect {       
        border: 1px solid ${(props:any) => props.borderSelect ? '#E56BCC' : '#707070' };
        border-radius: 5px;
        opacity: 1;
        width: 216px;
        height: 171px;
        padding: 40px;
        text-align: center;
        font-family: 'Montserrat-Bold';
        cursor: pointer;
    }
    @media only screen and (max-width: 991px) {
        .clbt-logo-card-container, .clbt-logo-card-container-unselect{
           margin-bottom: 20px;
        }
        .sctbl-c2 {
            width: 90%;
            margin: 0 30px;
        }
        .sctbl-c2-logo-card-holder{
            flex-direction: column;
        }
    }
    `}
`;
