import { useEffect } from "react";
 import { useDispatch, useSelector } from "react-redux";
 import { marketPlaceAction } from "./duck/action";
import { Container } from "semantic-ui-react";
import DetailRight from "./components/detail-right/detail-right";
import DetailLeft from "./components/detail-left/detail-left";
import "./market.css";
import StyledContainer from "./styles";
import { useHistory } from "react-router-dom";

const MarketPlacePage = () => {

  const { location } = useHistory();
  
   const dispatch = useDispatch();
   const marketPlaceData = useSelector((state: any) => state.marketPlaceReducer.marketPlaceData);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    if(location?.state)
    dispatch(marketPlaceAction(location?.state));
  }, [location?.state]);

  return (
    <StyledContainer>
      <Container>
        <div className="detail-main">
          <div className="detail-left">
            <DetailLeft marketPlaceData={ marketPlaceData }/>
          </div>
          <div className="detail-right">
             <DetailRight marketPlaceData={ marketPlaceData }/>
          </div>
        </div>       
      </Container>
    </StyledContainer>
      
  );
};

export default MarketPlacePage;
