import React from 'react';
import SortStyledContainer from './styles';
import { Popup } from 'semantic-ui-react'

const DropdownComponent = (props: any) => (


 
  <Popup
  position="bottom right"
  on="click"
  pinned
  trigger={
  <span style={{ cursor: "pointer"}}>...</span>
  }
>
  <Popup.Content>
    <Popup.Content>
      <SortStyledContainer>
        <div className="sort-pop-div">
          <div className="inner-pop">        
            <div className="pop-info">
                <span>New bid</span>
                <span>Refresh Metadata</span>
                <span>Share</span>
                <span>Report</span>
            </div>           
          </div>        
        </div>
      </SortStyledContainer>
    </Popup.Content>
  </Popup.Content>
</Popup>
)

export default DropdownComponent;