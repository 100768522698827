import styled, { css } from 'styled-components';

const TimedAuctionStyledContainer = styled.div`
    ${css`
    .home-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 30px 0;
    }
    
    .home-category-slide {
        height: 36px;
        margin: 50px 0;
    }
    .home-artist-slide {
        margin: 50px 0;
    }
    
    .home-artist-slide {
        margin: 50px 0;
    }
    
    .detail-main{
        display: flex;
        width: 100%;
        margin-top: 120px;
    }
    .detail-main .detail-left{
        display: flex;
        width: 45%;   
    }
    .detail-main .detail-right{
        display: flex;
        width: 55%; 
    }
    
    .right-block{
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
    }
    
    .highBit-section{
        display: flex;
        width: 100%;
        flex-direction: column;
        background: #1A101C ;
        border-radius: 9px;
        padding: 20px;
        margin-top: 50px;
        margin-bottom: 60px;
    }
    .highBit-section h2{
        color:  ${props => props.theme.color.primaryfontColor};
        font-family: "SF-Pro-Rounded-Bold";
        font-size: 32px;
    }
    .highBit-section p{
        margin-bottom: 5px;
        color: #B7B7B7;
        font-size: 20px;
        font-family: "Montserrat-SemiBold";
    }
    .highBit-section p span{
        color:  ${props => props.theme.color.primaryfontColor};
    }
    .highBit-section h4{
        color: #B7B7B7;
        font-size: 13px;
        color: #B7B7B7;
        margin: 0;
    }
    .highBit-section .button-span{
        flex-direction: row;
        width: 100%;
        margin: 30px 0 0 0;
    }
    .highBit-section .button-span button{
        width: calc(50% - 8px);
    }
    .highBit-section .button-span .btn_full{
        margin-right: 8px;
    }
    .highBit-section .button-span .btn_outline{
        margin-left: 8px;
    }
    @media only screen and (max-width: 991px) {
        .detail-main{
            flex-direction: column;
        }
        .detail-main .detail-left{
            width: 100%;  
        }
        .detail-main .detail-right{
            width: 100%;  
        }
    }
    `}
`;


export default TimedAuctionStyledContainer;