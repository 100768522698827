import styled, { css } from 'styled-components';

const NotificationStyledContainer = styled.div`
    ${css`
    .ui.bottom.popup {
        padding: 0;
      }
      .ui.popup {
        border: 0 !important;
        border-radius: 8px !important;
      }
      .notity-pop-div {
        background: ${props => props.theme.color.secondaryColor};
        display: flex;
        flex-direction: column;
        padding: 10px 20px 0 20px;
        width: 400px;
        border-radius: 8px;
        position: relative;
      }
      .notity-pop-div::before {
        position: absolute;
        top: -5px;
        right: 1em;
        bottom: auto;
        left: auto;
        content: '';
        width: 0.71428571em;
        height: 0.71428571em;
        background: ${props => props.theme.color.secondaryColor};
        transform: rotate(45deg);
        z-index: 2;
      }
      .notity-pop-div .inner-pop {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 10px 0;
        border-bottom: 1px solid #EAEAEA;
        padding-bottom: 10px;
      }
      .notity-pop-div .inner-pop:last-child {
        border-bottom: 0;
      }
      .notity-pop-div .inner-pop .pop-img {
        width: 25%;
        display: flex;
      }
      .notity-pop-div .inner-pop .pop-info {
        width: 75%;
        display: flex;
        flex-direction: column;
      }
      .notity-pop-div .inner-pop .pop-info .info-content {
        font-size: 13px;
        color: ${props => props.theme.color.primaryfontColor};
        font-family: "Montserrat-SemiBold";
      }
      .notity-pop-div .inner-pop .pop-info .time {
        font-size: 10px;
        color: #EAEAEA;
        font-family: "Montserrat-Medium";
      }
      .ui.bottom.right.popup:before {
        background: ${props => props.theme.color.secondaryColor};
      }
    `}
`;


export default NotificationStyledContainer;