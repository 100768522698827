import styled, { css } from 'styled-components';

const HighBidStyledContainer = styled.div`
width: 100%;
    ${css`
    .high-bid-block{
        display: flex;
        width: 100%;
        flex-direction: column;
        background:  ${props => props.theme.mode === "dark" ? "#1A101C" : "#f7f7f7"};
        border-radius: 9px;
        padding: 20px;
        margin-top: 50px;
        margin-bottom: 60px;
    }
    .high-bid-block h2{
        color:  ${props => props.theme.mode === "dark" ? "#ffffff" : "#000000"};
        font-family: "SF-Pro-Rounded-Bold";
        font-size: 32px;
        /* margin: 0; */
        margin: 10px 0 15px 0;
    }
    .high-bid-block h3{
        margin-bottom: 5px;
        color: #B7B7B7;
        font-size: 20px;
        font-family: "Montserrat-SemiBold";
    }
    .high-bid-block h3 span{
        color:  ${props => props.theme.color.primaryfontColor};
    }
    .high-bid-block h4{
        font-size: 12px;
        color: #B7B7B7;
        margin: 0;
        font-family: "Montserrat-SemiBold";
    }
    .high-bid-block .button-span{
        flex-direction: row;
        width: 100%;
        margin: 30px 0 0 0;
    }
    .high-bid-block .button-span button{
        width: calc(100% - 8px);
    }
    .high-bid-block .button-span .btn_full{
        margin-right: 8px;
    }
    .high-bid-block .button-span .btn_outline{
        margin-left: 8px;
        background: #2B1C2E;
        border: 0;
        color:  #ffffff;
    }
    
    .high-bid-info-blk {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
    }
    .bid-info-left, .bid-info-right {
        display: flex;
        width: 48%;
        flex-direction: column;
        align-items: flex-start;
    }
    .bid-info-left {
        border-right: 1px solid #707070;
    }
  
    @media only screen and (max-width: 720px) {
        .high-bid-info-blk{
            flex-direction: column;
        }
        .high-bid-info-blk .bid-info-left{
            width: 100%;  
            border-right: none;
            border-bottom: 1px solid #707070;
            padding-bottom: 20px;
        }
        .high-bid-info-blk .bid-info-right{
            width: 100%;  
            margin-top: 20px
        }
    }
    @media only screen and (max-width: 520px) {
        .high-bid-block h3{
            font-size: 16px;
        }
        .high-bid-block h2{
            font-size: 24px;
        }
        .countTimer-section .counter-title {
            font-size: 16px;
            margin-bottom: 25px;
        }
        .countTimer-blk .timer {
            font-size: 28px;
        }
    }
    `}
`;


export default HighBidStyledContainer;