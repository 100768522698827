import StyledContainer from "./styles";

export const Level1Card = ({ l1CardImage, l1CardText }: any) => {
  return (
    <StyledContainer>
      <div className="l1-card">
        <img src={l1CardImage} alt="" />
        <p>{l1CardText}</p>
      </div>
    </StyledContainer>
  );
};
