import StylesContainer from "./styles";
import { Dropdown, Input } from "semantic-ui-react";
import ToggleButton from "../../../../../components/toggleButton/toggleButton";
import { useState } from "react";

const options = [
  { key: "opt1", text: "opt1", value: "opt1" },
  { key: "opt2", text: "opt2", value: "opt2" },
  { key: "opt3", text: "opt3", value: "opt3" },
];

const suggestionKV = [
  { key: "hellow", value: "World" },
  { key: "hellow", value: "World" },
];

const CollectibleSubType = ({
  title,
  description,
  textFiledPlaceHolder,
  textFieldDropDownOption = options,
  suggestionsKVArrayData = suggestionKV,
  isTextField = false,
  isSuggestion = false,
  isToggleButton = false,
  defaultToggleValue = false,
  getToggleValue = () => {},
  getDropdownValue = () => {},
  getInputData = () => {},
  isDropDown = true,
  defaultDropDownValue="opt1",
  inputType = "text",
  inputValue,
  isPlainSuggestion = false,
  plainSuggestionText,
}: any) => {
  const [selected, setSelected] = useState(false);

  const handleToggleButton = () => {
    setSelected(!selected);
    getToggleValue(!selected);
  };

  const handleInputChange = (e:any) => {
    const value = e.target.value;
    const regex = /^\d*\.?\d*$/;
    const regexWithoutDecimal = /^\d*[1-9]\d*$/;
    
    inputType === 'number' ? ((regex.test(value) || (value === "")) && (value.length < 16) && getInputData(value.trim()) ): getInputData(value);

    if(title.toLowerCase().includes('royal')) {
      if (regex.test(value) && value <= 50 && value.length < 14) {
        getInputData(value);
      } else {
        getInputData("");
      }
    }
    
    if (inputType === "noc") {
      if (regexWithoutDecimal.test(value) && value <= 10) {
        getInputData(value.trim());
      } else {
        getInputData("");
      }
    }
  }
  return (
    <StylesContainer>
      <div className="collectible-sub-type-container">
        <div className="collectible-sub-type">
          <p>{title}</p>
          {isToggleButton && (
            <ToggleButton
              selected={selected}
              toggleSelected={handleToggleButton}
            />
          )}
          {!isToggleButton && <p></p>}
        </div>
        <p className="collectible-sub-type-description">{description}</p>

        {isTextField && (
          <Input
            fluid
            inverted
            value={inputValue}
            min={0}
            onWheel={(e:any) => e.target.blur()}
            onChange={(e) => handleInputChange(e)}
            {...(isDropDown && {
              label: (
                <Dropdown
                  defaultValue={defaultDropDownValue}
                  options={textFieldDropDownOption}
                  onChange={(e, {value}) => getDropdownValue(value)}
                />
              ),
            })}
            labelPosition="right"
            placeholder={textFiledPlaceHolder}
          />
        )}

        {isSuggestion &&
          suggestionsKVArrayData?.map((e: any) => (
            <div className="collectible-sub-type-suggesstion">
              <>
                <p className="key">{e.key}</p>
                <span>:</span>
                <p className="value">{e.value}</p>
              </>
            </div>
          ))}
        {isPlainSuggestion && (
          <div className="collectible-sub-type-plain-suggesstion">
            <p>{plainSuggestionText}</p>
          </div>
        )}
      </div>
    </StylesContainer>
  );
};

export default CollectibleSubType;
