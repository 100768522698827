import styled, {css} from 'styled-components';

const FixedPriceCss = styled.section`
${css`
.home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 30px 0;
}

.home-category-slide {
    height: 36px;
    margin: 50px 0;
}
.home-artist-slide {
    margin: 50px 0;
}

.home-artist-slide {
    margin: 50px 0;
}

.detail-main{
    display: flex;
    width: 100%;
    margin-top: 120px;
}
.detail-main .detail-left{
    display: flex;
    width: 45%;   
}
.detail-main .detail-right{
    display: flex;
    width: 55%; 
}
@media only screen and (max-width: 991px) {
    .detail-main{
        flex-direction: column;
    }
    .detail-main .detail-left{
        width: 100%;  
    }
    .detail-main .detail-right{
        width: 100%;  
    }
}
`}
`;


export default FixedPriceCss;