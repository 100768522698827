/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useHistory} from "react-router-dom";
import { getCategories, getHotBids, getLiveAuction, getPopularShots, getTopArtists, getTopSellers } from "./duck/action";
//import { Button } from "semantic-ui-react";
import Slider from "react-slick";
import StyledContainer from "./styles";
import ArtistCard from "../../../components/cards/artistCard/artistCard";
import BidAutionShotCard from "../../../components/cards/bidAutionShotCard/bidAutionShotCard";
//import { musicCategory } from "../../../constants/dummyData/homePage";
import { Container } from "semantic-ui-react";
import arrow from "../../../styles/images/right-arrow.svg";
import search from "../../../styles/images/search.svg";
import filter from "../../../styles/images/filter.svg";
import arrowDark from "../../../styles/images/right-arrow-dark.svg";
import searchDark from "../../../styles/images/search-dark.svg";
import filterDark from "../../../styles/images/filter-dark.svg";
import hotCollection from "../../../styles/images/hot-collection.png";
import TopSellers from "../../../components/top-sellers/topSellers";
import HotCollections from "../../../components/hot-collections/hotCollections";
import TitleBlock from "./components/title-component/title";

// import "./home.css";

const HomePage = () => {
  const currentTheme = localStorage.getItem("nftTheme");
  const dispatch = useDispatch();
  const history = useHistory();
  const [hotBidsPagination, setHotBidsPagination] = useState(1);
  const [liveAuctionPagination, setLiveAuctionPagination] = useState(1)
  const topSellers = useSelector((state: any) => state.homeReducer.topSellers);
  const categories = useSelector((state: any) => state.homeReducer.categories);
  const hotBidsData = useSelector((state: any) => state.homeReducer.hotBidsData);
  const hotBidsPaginationData = useSelector((state: any) => state.homeReducer.hotBidsPaginationData);
  const liveAuctionPaginationData = useSelector((state: any) => state.homeReducer.liveAuctionPaginationData);
  const liveAuctionData = useSelector((state: any) => state.homeReducer.liveAuctionData);
  const topArtistsData = useSelector((state: any) => state.homeReducer.topArtistsData);
  const popularShotsData = useSelector((state: any) => state.homeReducer.popularShotsData);
  const [selectedMusicCategory, setSelectedMusicCategory] = useState("ALL"); // TODO::expecting all from API
  const [searchInput, setSearchInput] = useState("");

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const musicCategorySettings = {
    ...settings,
    slidesToShow: 7,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: false,          
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  const bidSettings = { 
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: hotBidsData.length > 4 ? 4 : hotBidsData.length,
    beforeChange: (current: number, next: number) => setHotBidsPagination((prevState) => next === 0 ? 1 : prevState === hotBidsPaginationData?.totalPages ? prevState : prevState+1),
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const auctionSettings = { ...settings, dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: liveAuctionData.length > 4 ? 4 : liveAuctionData.length,
    beforeChange: (current: number, next: number) => setLiveAuctionPagination((prevState) => next === 0 ? 1 : prevState === liveAuctionPaginationData?.totalPages ? prevState : prevState+1),
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const topArtistSettings = { ...settings,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: topArtistsData.length > 4 ? 4 : topArtistsData.length,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    dispatch(getTopSellers());
    dispatch(getCategories());
    dispatch(getTopArtists());
    dispatch(getPopularShots()); 
  }, []);
  useEffect(() => {
    dispatch(getHotBids(hotBidsPagination));
    dispatch(getLiveAuction(liveAuctionPagination));
  }, [hotBidsPagination, liveAuctionPagination]);
  

  const handleMusciCategory = (category: any) => {
    setSelectedMusicCategory(category);
  };


  return (
    <StyledContainer>
      <Container>
        <div className="wrap-head">
          <h1>
            Glad to see you at our <span className="gradient">Marketplace</span>
          </h1>
          <p>All your favourite artists collectibles in one place</p>
          <div className="big-input ui fluid  input">
            <input type="text" placeholder="SEARCH BY MUSIC OR ARTIST..." onChange={e => setSearchInput(e.target.value)}/>
            <img src={currentTheme === 'dark' ? search : searchDark} alt="search" className="search-icon"/>
            {/* <img src={currentTheme === 'dark' ? filter : filterDark} alt="filter" className="back-icon"/> */}
            <img src={currentTheme === 'dark' ? arrow : arrowDark} alt="arrow" className="filter-icon" onClick={e => history.push("/search", {
                searchTerm: searchInput
              })}/>
          </div>
        </div>
     
     
      <div className="home-container">
        <div className="home-category-slide">
          <Slider {...musicCategorySettings}>
            {categories.map((category: any) => (
              <div key={category?.name} style={{ width: "100px" }}>
                <button
                  className={
                    category?.name === selectedMusicCategory
                      ? "catButton catButtonSelected"
                      : "catButton"
                  }
                  onClick={() => handleMusciCategory(category?.name)}
                >
                  {category?.name}
                </button>
              </div>
            ))}
          </Slider>
        </div>
        <TitleBlock
          title={"Top Artist"}
          subTitle={"Explore all top artists"}
          image={hotCollection}
        />
        <div className="home-bids-slide">
          <Slider {...topArtistSettings}>
            {topArtistsData.map((artistsData: any, key: number) => (
              <div key={`top-artists-${key}`}>
                <ArtistCard
                  overlayData="10"
                  artistsData={artistsData}
                />
              </div>
            ))}
          </Slider>
        </div>
        <TitleBlock
          title={"Hot Bids"}
          subTitle={"Explore all hot bids"}
          image={hotCollection}
        />
        <div className="home-bids-slide">
          <Slider {...bidSettings}>
            {hotBidsData?.map((hotBid: any, key: number) => (
              <div key={`home-bids-${key}`}>
                <BidAutionShotCard useAs="bids" hotBid={hotBid} />
              </div>
            ))}
          </Slider>
        </div>

        
        <div className="live-auction-container">
          <TitleBlock
          title={"Live Auctions"}
          subTitle={"Explore all Live auctions"}
          image={hotCollection}
        />
        </div>
        <div className="home-bids-slide">
          <Slider {...auctionSettings}>
             {liveAuctionData?.map((liveAuction: any) => (
              <div>
                <BidAutionShotCard useAs="aution" liveAuction={liveAuction} />
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <TopSellers data={topSellers} />
      <TitleBlock
        title={"Hot Collections"}
        subTitle={"Explore more collections"}
        image={hotCollection}
      />
      <HotCollections />

      <TitleBlock
        title={"Popular Shots"}
        subTitle={"Explore more shots"}
        image={hotCollection}
        filter={true}
      />
      <div className="home-popshot-container">
             {popularShotsData?.map((popularShots: any) => (
              <div>
                <BidAutionShotCard useAs="shots" popularShots={popularShots} />
              </div>
            ))}
          </div>
      </Container>
    </StyledContainer>
  );
};

export default HomePage;
