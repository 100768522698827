import dot from "../../../../../styles/images/dot.svg";
import tickBadge from "../../../../../styles/images/tick-badge-big.svg";
import defaultImg from "../../../../../styles/images/default.svg";
// import './tab-list.css';
import AuctionTabStyledContainer from './style';


const TabList = () => {
    return (
      <AuctionTabStyledContainer>  
      <div className="tabList-tab-block">
          <div className="des-block">
            <div className="des-block-left"> 
              <div className="img_block">
                <img className="img-big" src={defaultImg} alt=""/>              
                <img className="img-small" src={tickBadge} alt='' />
              </div>
              <div className="info_block">
                <h3 className="title">0.04 wETH by Random Girl</h3>
                <p>15 minutes ago</p>
              </div>
            </div> 
            <div className="des-block-left">           
              <img src={dot} alt='' style={{ cursor:"pointer" }}/>
            </div>
          </div>
          <div className="des-block">
            <div className="des-block-left"> 
              <div className="img_block">
                <img className="img-big" src={defaultImg} alt=""/>              
                <img className="img-small" src={tickBadge} alt='' />
              </div>
              <div className="info_block">
                <h3 className="title">0.04 wETH by Random Girl</h3>
                <p>45 minutes ago</p>
              </div>
            </div> 
            <div className="des-block-left">           
              <img src={dot} alt='' style={{ cursor:"pointer" }}/>
            </div>
          </div>
        </div>
        </AuctionTabStyledContainer>
    )
  }

  export default TabList;