import * as action_types from "./constant";

const initialState = {
    accountDetails: null,
    loginDetail: null
};

const headerReducer =  (state = initialState, action: any) => {
    const { type, data } = action;
    switch (type) {
        case action_types.ACCOUNTDETAILS:
            return { ...state, accountDetails: data };
        case action_types.ACTIONLOGIN:
            return { ...state, loginDetail: data };                        
        default:
            return state;
    }
};

export default headerReducer;
