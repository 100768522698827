import * as action_types from "./constant";
import axios from "axios";
import { DEV_BASE_URL } from "../../../../constants/url";
//import { sellerData } from "../../../../constants/dummyData/homePage";

export const getCategories = () => {
    const url = `${DEV_BASE_URL}/categories/list`;
    return async (dispatch: any) => {
        try {
            const response = await axios.get(url);
            dispatch({
                type: action_types.CATEGORIES,
                data: response?.data?.data || []
            });
        } catch (error) {
            console.log("ERROR", error);
        }
    };
}

export const getHotBids = (page: number) => {
    const url = `${DEV_BASE_URL}/products/purchase-type/hotBids/${localStorage.getItem('userID')}?page=${page}&records=8`;
    return async (dispatch: any) => {
        try {
            const response = await axios.get(url);
            dispatch({
                type: action_types.GETHOTBIDS,
                data: response?.data?.data || [],
                paginationData: response?.data?.paginate || {}
            });
        } catch (error) {
            console.log("ERROR", error);
        }
    };
};

export const getLiveAuction = (page: number) => {
    const url = `${DEV_BASE_URL}/products/purchase-type/liveAuction/${localStorage.getItem('userID')}?page=${page}&records=8`;
    return async (dispatch: any) => {
        try {
            const response = await axios.get(url);
            dispatch({
                type: action_types.GETLIVEAUCTION,
                data: response?.data?.data || [],
                paginationData: response?.data?.paginate || {}
            });
        } catch (error) {
            console.log("ERROR", error);
        }
    };
};

export const getTopSellers = () => {
    const url = `${DEV_BASE_URL}/users/top-seller?page=1&records=10`;
    return async (dispatch: any) => {
        try {
            const response = await axios.get(url);
            dispatch({
                type: action_types.GETTOPSELLERS,
                data: response?.data?.data || []
            });
        } catch (error) {
            console.log("ERROR", error);
        }
    };
};

export const getPopularShots = () => {
    const url = `${DEV_BASE_URL}/products/list`;
    const data = {
        "page": 1,
        "records": 10,
        "searchValue": "",
        "sort": "",
        "verifiedOnly": true,
        "nsfw": true,
        "category": "",
        "collections": [
          
        ],
        "saleStatus": [
          
        ],
        "maxPrice": 0,
        "minPrice": 0,
        "userId": localStorage.getItem('userID')
      }
    return async (dispatch: any) => {
        try {
            const response = await axios.post(url, data);
            dispatch({
                type: action_types.GETPOPULARSHOTS,
                data: response?.data?.data || []
            });
        } catch (error) {
            console.log("ERROR", error);
        }
    };
};

export const getTopArtists = () => {
    const url = `${DEV_BASE_URL}/collections/top-artists?page=1&records=10`;
    return async (dispatch: any) => {
        try {
            const response = await axios.get(url);
            dispatch({
                type: action_types.GETTOPARTISTS,
                data: response?.data?.data || []
            });
        } catch (error) {
            console.log("ERROR", error);
        }
    };
};
