import styled, { css } from "styled-components";

export default styled.div`
    ${css`
    .preview-card {
        width: 277px;
        height: 411px;
        border: 1px solid #707070;
        font-family: 'Montserrat-Bold';
        border-radius: 5px;
        position: relative;
        cursor: auto;
    }
    .preview-card img {
        width: 48px;
        height: 85px;
        position: absolute;
        left: 40%;
        top: 30%;
    }
    
    .preview-card p {
        position: absolute;
        text-align: left;
        letter-spacing: 0px;
        color: #AFAFAF;
        top: 75%;
        top: 55%;
        padding: 0 55px;
    }
    `}
`;