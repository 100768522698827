import styled, { css } from "styled-components";

export default styled.div`
    ${css`
    .title_heading_wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 15px 0;
    }
    .collection_title h2 {
        font-size: 25px;
        font-family: 'MADE-Outer-Sans-Regular';
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};
        margin-bottom: 5px;
    }
    .collection_title h2 img {
        position: relative;
        top: 5px;
        left: 5px;
    }
    .explore_collection a{
        font-size: 13px;
        color: #AFAFAF;
        cursor: pointer;
        text-decoration: none;
    }
    @media only screen and (max-width: 520px) {
       
        .collection_title h2 {
            font-size: 15px;
        }
    }
    `}

`;