import styled, { css } from 'styled-components';
import React from "react";
const DetailLeftCssContainer = styled(React.Fragment)`
    ${css`
    .market_img_block{
        display: flex;
        width: calc( 100% - 50px);
        height: 500px;
        border: 1px solid #BCBCBC27;
        border-radius: 6px;
        /* margin: 0 10px; */
        margin-right: 25px;
    }
    .market_img_card{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: calc( 100% - 30px);;
        height: calc( 100% - 30px);;
        margin: 15px;
    }  
    @media only screen and (max-width: 991px) {
        .market_img_block{
            width:100%;
            margin-right: 0;
            margin-bottom: 25px;
        }
    }
    `}
`;


export default DetailLeftCssContainer;