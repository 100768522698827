import React, { useState } from 'react';
// import MultiSelect from 'react-multiselect-button-dropdown';
import FiltersStyledContainer from './styles';
import MultiSelect from '../multiselect-dropdown/MultiSelect';

const FiltersComponent = () => {

    const [overAllReset, setOverAllReset] = useState(false);

    const categoryProps = {
        list: [
          {
            label: "Art",
            name: "art",
            id: "art-option",
            checked: false
          },
          {
            label: "Photography",
            name: "Photography",
            id: "photography-option",
            checked: false
          },
          {
            label: "Games",
            name: "games",
            id: "games-option",
            checked: false
          }
        ],
        onSelectionApplied: (selection: any) => {
          // console.log("Selected : ", selection);
          setOverAllReset(false);
        },
        dropdownButtonText: "Category",
        resetButtonText: "Reset",
        applyButtonText: "Apply",
        onOverAllReset: overAllReset,
        onOptionChanged: (sel:any) => {
          // console.log("onOptionChanged", sel);
          setOverAllReset(false);
        }
      };

    const collectionsProps = {
        list: [
          {
            label: "CloneX",
            name: "cloneX",
            id: "CloneX-option",
            checked: false
          },
          {
            label: "Doodles",
            name: "doodles",
            id: "doodles-option",
            checked: false
          },
          {
            label: "Town Star",
            name: "town-star",
            id: "town-star-option",
            checked: false
          }
        ],
        onSelectionApplied: (selection: any) => {
          // console.log("Selected : ", selection);
          setOverAllReset(false);
        },
        dropdownButtonText: "Collections",
        resetButtonText: "Reset",
        applyButtonText: "Apply",
        onOverAllReset: overAllReset,
        onOptionChanged: (sel:any) => {
          // console.log("onOptionChanged", sel);
          setOverAllReset(false);
        }
      };
    const salesTypeProps = {
        list: [
          {
            label: "Timed auction",
            name: "timed-auction",
            id: "timed-auction-option",
            checked: false
          },
          {
            label: "Fixed price",
            name: "fixed-price",
            id: "fixed-price-option",
            checked: false
          },
          {
            label: "Not for sale",
            name: "not-for-sale",
            id: "not-for-sale-option",
            checked: false
          },
          {
            label: "Open for offers",
            name: "open-for-offers",
            id: "open-for-offers-option",
            checked: false
          },
        ],
        onSelectionApplied: (selection: any) => {
          console.log("Selected : ", selection);
          setOverAllReset(false);
        },
        dropdownButtonText: "Sales Type",
        resetButtonText: "Reset",
        applyButtonText: "Apply",
        onOverAllReset: overAllReset,
        onOptionChanged: (sel:any) => {
          console.log("onOptionChanged", sel);
          setOverAllReset(false);
        }
      };

      const onOverAllReset = () => setOverAllReset(true);

      return (
        <FiltersStyledContainer>
          <div style={{ display: "flex", justifyContent: "space-around",alignItems: 'center',marginLeft: '15px' }}>
            <MultiSelect {...categoryProps} />
            <MultiSelect {...collectionsProps} />
            <MultiSelect {...salesTypeProps} />
            <span className='reset_text' onClick={onOverAllReset}>Reset</span>
          </div>
        </FiltersStyledContainer>
      );

}

export default FiltersComponent;