import dot from "../../../../../styles/images/dot.svg";
import tickBadge from "../../../../../styles/images/tick-badge-big.svg";
import defaultImg from "../../../../../styles/images/default.svg";
import art from "../../../../../styles/images/art.png";
import cloud from "../../../../../styles/images/cloud.svg";
// import './tab-list.css';
import TabStyleCss from './tab-style'

export const DetailsTabList = () => {
    return (
      <TabStyleCss>

    
      <div className="tabList-tab-block">
          <div className="des-block">
            <div className="des-block-left"> 
              <div className="img_block">
                <img className="img-big" src={defaultImg} alt=""/>              
                <img className="img-small" src={tickBadge} alt='' />
              </div>
              <div className="info_block">
                <h3 className="title">0.04 wETH by Random Girl</h3>
                <p>42 minutes ago</p>
              </div>
            </div> 
            <div className="des-block-left">           
              <img src={cloud} alt='' style={{ cursor:"pointer" }}/>
            </div>
          </div>
          <div className="des-block">
            <div className="des-block-left"> 
              <div className="img_block">
                <img className="img-big" src={defaultImg} alt=""/>              
                <img className="img-small" src={tickBadge} alt='' />
              </div>
              <div className="info_block">
                <h3 className="title">0.04 wETH by Random Girl</h3>
                <p>45 minutes ago</p>
              </div>
            </div> 
            <div className="des-block-left">           
              <img src={cloud} alt='' style={{ cursor:"pointer" }}/>
            </div>
          </div>
          <div className="art-div">
             <div className="art-btn"><img src={art} alt="art"/> Art</div>
             <div className="art-list">
               <div className="inner-art">
                 <div className="left-art art-span">Dimensions</div>
                 <div className="right-art art-span">3050px by 4961px</div>
               </div>
               <div className="inner-art">
                 <div className="left-art art-span">Return</div>
                 <div className="right-art art-span">40% APY</div>
               </div>
               <div className="inner-art">
                 <div className="left-art art-span">Editions</div>
                 <div className="right-art art-span">Blank Canvas</div>
               </div>
               <div className="inner-art">
                 <div className="left-art art-span">Untitled</div>
                 <div className="right-art art-span">TBA</div>
               </div>
               <div className="inner-art">
                 <div className="left-art art-span">Mint Date</div>
                 <div className="right-art art-span">02/02/2021</div>
               </div>
             </div>
          </div>
        </div>
        </TabStyleCss>

    )
  }

  export const BidTabList = (props: any) => {

    const { onAcceptOffClick = () => {} } = props;
    return (
      <TabStyleCss>
      <div className="tabList-tab-block">
          <div className="des-block">
            <div className="des-block-left"> 
              <div className="img_block">
                <img className="img-big" src={defaultImg} alt=""/>              
                <img className="img-small" src={tickBadge} alt='' />
              </div>
              <div className="info_block">
                <h3 className="title">0.06 wETH by Random Girl</h3>
                <p>15 minutes ago</p>
              </div>
            </div> 
            <div className="des-block-left">       
            <button className="btn_full" onClick={() => onAcceptOffClick(true)}>ACCEPT</button>      
              <img src={cloud} alt='' style={{ cursor:"pointer" }}/>
            </div>
          </div>
          <div className="des-block">
            <div className="des-block-left"> 
              <div className="img_block">
                <img className="img-big" src={defaultImg} alt=""/>              
                <img className="img-small" src={tickBadge} alt='' />
              </div>
              <div className="info_block">
                <h3 className="title">0.04 wETH by Random Girl</h3>
                <p>45 minutes ago</p>
              </div>
            </div> 
            <div className="des-block-left">      
            <button className="btn_full" onClick={() => onAcceptOffClick(true)}>ACCEPT</button>     
              <img src={cloud} alt='' style={{ cursor:"pointer" }}/>
            </div>
          </div>
          <div className="des-block">
            <div className="des-block-left"> 
              <div className="img_block">
                <img className="img-big" src={defaultImg} alt=""/>              
                <img className="img-small" src={tickBadge} alt='' />
              </div>
              <div className="info_block">
                <h3 className="title">Minted by The Bunni Lama</h3>
                <p>15 minutes ago</p>
              </div>
            </div> 
            <div className="des-block-left">           
              <img src={cloud} alt='' style={{ cursor:"pointer" }}/>
            </div>
          </div>
        </div>
        </TabStyleCss>

    )
  }