import React, { useRef, useState } from "react";
import { Button } from "semantic-ui-react";
import { StyledContainer } from "./styles";
import { toast } from "react-toastify";
import AxiosRequest, { REQUESTS } from "../../../../../utils/hooks/useRequest";
import copy from "../../../../../styles/images/copy.png";

const CollectionForm = ({ handleCollection }: any) => {
  const [previewImage, setPreviewImage] = useState(copy);
  const [binaryImageData, setBinaryImageData] = useState("");
  const fileInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const [formData, setFormData] = useState({
    display: "",
    symbol: "",
    description: "",
    shortUrl: "",
  });

  const handleUpload = async (e: any) => {
    let fileData = e.target.files[0];
    let binaryFile = URL.createObjectURL(fileData);
    setPreviewImage(binaryFile);
    setBinaryImageData(fileData);
  };

  const formValidator = (): any => {
    if (!binaryImageData) {
      toast.error("Please Upload an image");
      return false;
    }
    if (!formData.display) {
      toast.error("Please add Display Name");
      return false;
    }
    if (!formData.symbol) {
      toast.error("Please add Symbol");
      return false;
    }
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    const isTrueVal = regex.test(formData.shortUrl);
    if (!formData.shortUrl) {
      toast.error("Please add ShortUrl");
      return false;
    }
    if (!isTrueVal) {
      toast.error("Please add valid ShortUrl");
      return false;
    }

    return true;
  };

  const handleCreateCollection = async () => {
    if (formValidator()) {
      const fData = new FormData();
      fData.append("userId", "dummy");
      fData.append("description", formData.description);
      fData.append("sellerProfilePicture", binaryImageData);
      fData.append("displayName", formData.display);
      fData.append("symbol", formData.symbol);
      fData.append("shortUrl", formData.shortUrl);
      fData.append("type", "single");
      //@ts-ignore
      fData.append("isMaster", false);

      const { executeRequest } = AxiosRequest({
        method: REQUESTS.POST,
        url: "collections/create-collection",
        body: fData,
        token: {"Authorization" : `Bearer ${localStorage.getItem('authToken')}`},
      });

      try {
        const res = await executeRequest();
        if ([200, 201, 203].includes(res?.statusCode)) {
          toast.success(res?.message);
          handleCollection(res?.data);
        } else {
          toast.error(
            res?.message === "property sellerProfilePicture should not exist"
              ? "Please upload image"
              : res?.message
          );
        }
      } catch (err: any) {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <StyledContainer>
      <h2>Collection</h2>
      <div className="imageContainer">
        <div className="imageClass">
          <div className="spanImg">
            <img src={previewImage} alt="" />
          </div>
        </div>
        <div className="uploader">
          <div className="info_txt">
            We recommend an image of at least 400x400. Gifs work too.
          </div>
          <Button
            className="upButton"
            onClick={() => fileInputRef.current.click()}
          >
            CHOOSE FILE
          </Button>
          <input
            ref={fileInputRef}
            type="file"
            hidden
            maxLength={100}
            accept="image/*"
            onChange={(e) => handleUpload(e)}
          />
        </div>
      </div>

      <div className="form-container">
        <div className="field-holder">
          <h5>Display Name (required) </h5>
          <input
            placeholder="Enter Collection name"
            value={formData.display}
            onChange={(e) =>
              setFormData({ ...formData, display: e.target.value })
            }
          />
          <div className="info_txt">Token name cannot be changed in future</div>
        </div>

        <div className="field-holder">
          <h5>Symbol (required)</h5>
          <input
            placeholder="Enter token symbol"
            value={formData.symbol}
            onChange={(e) =>
              setFormData({ ...formData, symbol: e.target.value })
            }
          />
          {/* <div>Token name cannot be changed in future</div> */}
        </div>

        <div className="field-holder">
          <h5>Discription (optional)</h5>
          <input
            placeholder="Spread some words about your token collection"
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
          />
          {/* <div>Token name cannot be changed in future</div> */}
        </div>

        <div className="field-holder">
          <h5>Short url</h5>
          <span>gemcollections.blockgemini.net/</span>
          <input
            placeholder="Enter Collection name"
            value={formData.shortUrl}
            onChange={(e) =>
              setFormData({ ...formData, shortUrl: e.target.value })
            }
          />
          <div className="info_txt">Will be used as public URL</div>
        </div>
      </div>

      <button className="buttonClass" onClick={handleCreateCollection}>
        Create Collection
      </button>
    </StyledContainer>
  );
};

export default CollectionForm;
