import React from "react";
import { Modal } from "semantic-ui-react";
import AcceptOfferPopupCss from "./style";
import { Icon } from "semantic-ui-react";
import close from "../../../../../styles/images/close.svg";
const AcceptOfferModal = (props: any) => {
  const { show, onClose = () => {} } = props;
  const [succes, setSuccess] = React.useState(false);
  if (!show) {
    return null;
  }
  const addFund = () => {
    setSuccess(true);
  };
  return (
    <AcceptOfferPopupCss>
      <Modal
        size="tiny"
        onClose={() => {
          onClose(false);
          setSuccess(false);
        }}
        onOpen={() => {
          onClose(true);
          setSuccess(false);
        }}
        open={show}
      >
        <Modal.Content>
          <Modal.Description>
          <img
              onClick={() => onClose(false)}
              className="close-span"
              src={close}
              alt="close"
            />
            <div className="accept-offer-modal-div">
              {succes && (
                <React.Fragment>
                  <h4 className="accpt-h4">Musicrypt</h4>
                  <h3 className="accept-offer-modal-title"   style={{marginTop: '10px'}}>App Request</h3>
                  <p className="place-bid-p">
                    Confirm interaction with this app?
                  </p>
                  <div className="accept-offer-tab-div">
                    <div className="accept-offer-details-container">
                      <span className="accept-offer-details-label">
                        BID OFFER
                      </span>
                      <span className="accept-offer-details-data3">
                        0.09 wETH
                      </span>
                    </div>
                    <div className="accept-offer-details-container">
                      <span className="accept-offer-details-label">
                      FEE
                      </span>
                      <span className="accept-offer-details-data3">
                      0.00138745 ETH
                      </span>
                    </div>
                    <button onClick={() =>{
                       onClose(false);
                       setSuccess(false);
                    }} className="btn_full">
                    CONFIRM
                    </button>
                  </div>
                </React.Fragment>
              )}
              {!succes && (
                <React.Fragment>
                  <h3 className="accept-offer-modal-title">Are you sure?</h3>
                  <p className="place-bid-p">
                    You are about to accept a bid from:
                    <br /> <span>Xe0534…2Be07 for 0.09 wETH</span>
                  </p>
                  <div className="accept-offer-tab-div">
                    <div className="acct-notify-div">
                      <Icon color="pink" name="exclamation circle" />
                      <span className="acct-info-txt">
                        {" "}
                        Please review the details below before accepting final
                        offer
                      </span>
                    </div>
                    <div className="accept-offer-details">
                      <span className="accept-offer-details-label">
                        BID OFFER
                      </span>
                      <span className="accept-offer-details-data">
                        0.09 wETH
                      </span>
                      <span className="accept-offer-details-label">BIDDER</span>
                      <span className="accept-offer-details-data2">
                        Xe0534Bw23t2…2Be07
                      </span>
                    </div>
                    <button onClick={() => addFund()} className="btn_full">
                      ACCEPT OFFER
                    </button>
                  </div>
                </React.Fragment>
              )}
            </div>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </AcceptOfferPopupCss>
  );
};

export default AcceptOfferModal;
