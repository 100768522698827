import StyledContainer from "./styles";

const LogoCard = ({
  logo,
  text,
  select = true,
  borderSelect = false,
  isSubText = false,
  subText,
  betaName,
  onTileClick = () => {},
}: any) => {
  return (
    <StyledContainer borderSelect={borderSelect}>
      <div
        className={
          select
            ? "clbt-logo-card-container"
            : "clbt-logo-card-container-unselect"
        }
        onClick={() => onTileClick(betaName)}
      >
        <img src={logo} alt="" />
        <p className="clbt-logo-card-container-main-text">{text}</p>
        {isSubText && (
          <p className="clbt-logo-card-container-sub-text">{subText}</p>
        )}
      </div>
    </StyledContainer>
  );
};

export default LogoCard;
