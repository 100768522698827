import React, { useState } from "react";
import SortStyledContainer from "./styles";
import { Popup } from "semantic-ui-react";
import { Checkbox } from "semantic-ui-react";
// const DropdownExampleDropdown = () => (
//   <Dropdown text='Filter & Sort'>
//     <Dropdown.Menu>
//       <Dropdown.Header content='Sort by' />
//       <Dropdown.Item text='Recently added' />
//       <Dropdown.Item text='Price: Low to High'/>
//       <Dropdown.Item text='Price: High to Low'/>
//       <Dropdown.Item text='Auction ending soon'/>
//       <Dropdown.Header content='Options' />
//       <Checkbox toggle />
//     </Dropdown.Menu>
//   </Dropdown>
// )

// state = { isOpen: false }

// handleOpen = () => {
//   this.setState({ isOpen: true })

//   this.timeout = setTimeout(() => {
//     this.setState({ isOpen: false })
//   }, timeoutLength)
// }

// handleClose = () => {
//   this.setState({ isOpen: false })
//   clearTimeout(this.timeout)
// }

const SortComponent = (props: any) => {

  const filterValues = [
    {
      id:0,
      name: "Recently added",
      betaName: "recentlyAdded",
      isSelected: true,
    },
    {
      id:1,
      name: "Price: Low to High",
      betaName: "plth",
      isSelected: false,
    },
    {
      id:2,
      name: "Price: High to Low",
      betaName: "phtl",
      isSelected: false,
    },
    {
      id:3,
      name: "Auction ending soon",
      betaName: "auctionEnding",
      isSelected: false,
    },
  ];

  const [filterState, setFilterState] = useState(filterValues);

  const handleFilterSelection = (idValue:any) => {
    let local = [...filterState];
    local.map(e => {
      if (e.id === idValue) {
        e.isSelected = !e.isSelected
      }
      return e;
    })
    setFilterState(local);
  }

  console.log("EVSET", filterState);

  return (
    <Popup
      position="bottom right"
      on="click"
      pinned
      trigger={<span style={{ cursor: "pointer" }}>Filter & Sort</span>}
    >
      <Popup.Content>
        <Popup.Content>
          <SortStyledContainer filterState={filterState}>
            <div className="sort-pop-div">
              <div className="inner-pop">
                <div className="pop-head">Sort by</div>
                <div className="pop-info">
                  {
                    // filterState.map(e => <div className="FilterListHolder"><label key={e.id} onClick={() => handleFilterSelection(e.id)}>{e.name}</label><input className="filterSection" type="checkbox" /></div>)
                    filterState.map(e => <Checkbox label={e.name} checked={e.isSelected} className="checkClass" onChange={() => handleFilterSelection(e.id)} />)
                  }
                </div>
                <div className="pop-head">Options</div>
                <div className="pop-info">
                  <span>
                    Verified only <Checkbox toggle />
                  </span>
                  <span>
                    Show NSFW <Checkbox toggle />
                  </span>
                  {/* <span>Show lazy minted <Checkbox toggle /></span> */}
                </div>
              </div>
            </div>
          </SortStyledContainer>
        </Popup.Content>
      </Popup.Content>
    </Popup>
  );
};

export default SortComponent;
