import { useEffect, useState } from "react";
import { Container, Modal } from "semantic-ui-react";
import { MetaMaskInpageProvider } from "@metamask/providers";
import { Tab } from "semantic-ui-react";
import qrCode from "../../../styles/images/qr-code.svg";
import "./header.css";
import back from "../../../styles/images/back.svg";
import close from "../../../styles/images/close.svg";
import NotificationPopup from "../../notification-popup/notification-popup";
import CummunityPopup from "../../cummunity-popup/cummunity-popup";
import headerIcon1 from "../../../styles/images/header-icon1.svg";
import StyledContainer from "./styles";
import headerIcon3 from "../../../styles/images/header-icon3.svg";
import headerIcon3Dark from "../../../styles/images/header-icon3-dark.svg";
import headerIcon1Dark from "../../../styles/images/header-icon1-dark.svg";
import menu from "../../../styles/images/menu.png";
import { MetaMask } from "nft-web3";
import { getAccountDetails, actionLogin } from "./duck/action";
import Torus from "@toruslabs/torus-embed";
import { useDispatch, useSelector } from "react-redux";
import Auth from "../../../utils/NFT/ERC721/Auth";
import AxiosRequest, { REQUESTS } from "../../../utils/hooks/useRequest";
import { useHistory } from "react-router";

declare global {
  interface Window {
    ethereum: MetaMaskInpageProvider;
  }
}

const Header = ({ setTheme, existingTheme }: any) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [menuStatus, openMenu] = useState(false);
  const { accountDetails } = useSelector((state: any) => state.headerReducer);
  const dispatch = useDispatch();
  const [themeMode, setThemeMode] = useState(existingTheme);
  const currentTheme = localStorage.getItem("nftTheme");
  const torus = new Torus({});

  const handleMetaMaskLogin = async () => {

    if (window.ethereum) {  
      //window.provider = window.ethereum;
      const wallet = await new MetaMask(window.ethereum);
      const account = await wallet.connectMetaMask();

     localStorage.setItem("account", account);
      if (localStorage.getItem("wallet") !== "MetaMask") {
        localStorage.setItem("wallet", "MetaMask");
        const { executeRequest } = AxiosRequest({
          method: REQUESTS.POST,
          body: { account },
          url: "auth/user/account",
        });

       const result = await executeRequest();
       localStorage.setItem('userID', result?.data?.id);
       const auth = new Auth(window.ethereum);
        const authValue = await auth.signMessage(
          `I am signing my one time nonce: ${result?.data?.nonce}`
        );
       dispatch(actionLogin(result, authValue));
        if (wallet.isMetaMaskConnected()) {
          dispatch(getAccountDetails(account));
          setOpen(false);
        }
        else {
          handleLogout();
        }
     }
    } else {
      alert("MetMask is not installed");
      setOpen(false);
    }
  };

  // useEffect(() => {
  //   const addressInterVal = setInterval(() => {
  //     const { selectedAddress } = window.ethereum;
  //     if (selectedAddress?.toLowerCase() !== accountDetails?.toLowerCase())
  //       handleMetaMaskLogin();
  //   }, 10000);
  //   return () => clearInterval(addressInterVal);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [accountDetails]);

  const onClickLogin = async () => {
    await torus.init({
      enableLogging: false,
    });
    await torus.login();

    const auth = new Auth(torus.ethereum);
    const accountDetails = await auth.getAccountDetails();
    localStorage.setItem("account", accountDetails?.account);
    const { executeRequest } = AxiosRequest({
      method: REQUESTS.POST,
      body: { account: accountDetails?.account },
      url: "auth/user/account",
    });

    const result = await executeRequest();
    // when user perform some action
    const authValue = await auth.signMessage(
      `I am signing my one time nonce: ${result?.data?.nonce}`
    );
    dispatch(actionLogin(result, authValue));
    setOpen(false);
  };

  const panes = [
    {
      menuItem: "QR CODE",
      render: () => (
        <Tab.Pane attached={false}>
          <p>Scan QR code with a Metamask - compatible wallet</p>
          <img src={qrCode} alt="qrCode" />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "DESKTOP",
      render: () => (
        <Tab.Pane attached={false}>
          <p>Scan QR code with a Metamask - compatible wallet</p>
          <img src={qrCode} alt="qrCode" />
        </Tab.Pane>
      ),
    },
  ];

  const TabExampleAttachedFalse = () => (
    <Tab menu={{ attached: false }} panes={panes} />
  );

  const handleTheme = () => {
    if (themeMode === "dark") {
      setThemeMode("light");
      setTheme("light");
    } else {
      setThemeMode("dark");
      setTheme("dark");
    }
  };

  const handleLogout = async () => {
    const wallet = localStorage.getItem("wallet");
    if (wallet !== "MetaMask") {
      await torus.logout();
    }
    localStorage.removeItem("wallet");
    localStorage.removeItem("account");
    localStorage.removeItem("authToken");
    localStorage.removeItem("userID");
    window.location.reload();
  };

  const handleRedirect = () => {
    window.location.href = "/collectible";
  }

  return (
    <StyledContainer>
      <Container fluid className="headerBlock">
        <Container>
          <div className="wrapper">
            <div className="menuWrapper">
              <div className="subWrapper">
                <a href="/home">
                  <h2 className="logoText">MUSICRYPT</h2>
                </a>
              </div>
              <button className="btn_menu" onClick={() => openMenu(true)}>
                <img src={menu} alt="menu" />
              </button>
              {menuStatus && (
                <div className="main-block">
                  <span
                    onClick={() => openMenu(false)}
                    className="overlay"
                  ></span>
                  <div className="mobile-menu">
                    <a href="/#">EXPLORE</a>
                    <a href={"/marketplace"}>MARKETPLACE</a>
                    <a href="/#">MY STUFF</a>
                    <a href={"/my-activity"}>
                      ACTIVITY <span>new</span>
                    </a>
                    <a href="/#">HOW IT WORKS</a>
                    <a href="/#">COMMUNITY</a>
                  </div>
                  <img
                    onClick={() => openMenu(false)}
                    className="close-menu"
                    src={close}
                    alt="close"
                  />
                </div>
              )}
              <div className="subWrapper menuList">
                <a className="menuItems" href="/#">
                  EXPLORE
                </a>
                <a className="menuItems" href={"/marketplace"}>
                  MARKETPLACE
                </a>
                <a className="menuItems" href="/#">
                  MY STUFF
                </a>
                <a className="menuItems" href={"/my-activity"}>
                  ACTIVITY <span>new</span>
                </a>
                <a className="menuItems" href="/#">
                  HOW IT WORKS
                </a>
                {/* <a href="/#">COMMUNITY</a> */}
                <CummunityPopup />
              </div>
            </div>
            <div className="buttonWrapper">
              {/* <button className="btn_outline p-0">
              <img src={back} alt="back" />
            </button> */}
              <NotificationPopup currentTheme={currentTheme} />
              <button className="btn_outline p-0 btn-img">
                <img
                  src={currentTheme == "dark" ? headerIcon1 : headerIcon1Dark}
                  alt=""
                />
              </button>
                <button className="btn_outline" 
                onClick={!localStorage.getItem("authToken") ? () => setOpen(true) : handleRedirect}
                >
                CREATE
              </button>
              {!localStorage.getItem("authToken") ? (
                <button className="btn_full w-80" onClick={() => setOpen(true)}>
                  SIGN IN
                </button>
              ) : (
                <button className="btn_full w-80" onClick={handleLogout}>
                  LOGOUT
                </button>
              )}
              <button
                className="btn_outline p-0 btn-img"
                onClick={() => handleTheme()}
              >
                <img
                  src={currentTheme == "dark" ? headerIcon3 : headerIcon3Dark}
                  alt=""
                />
              </button>
              {/* <button  className="btn_outline"><img className="back-span" src={back} alt='back' /></button>
      <button  onClick={() => setOpen(true)} className="btn_full">GET STARTED</button> */}
            </div>
          </div>
        </Container>
        <Modal
          size="tiny"
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
        >
          {/* <Modal.Header>MUSICRYPT</Modal.Header> */}
          <Modal.Content>
            <Modal.Description>
              <img
                onClick={() => setOpen(false)}
                className="close-span"
                src={close}
                alt="close"
              />
              <h3>MUSICRYPT</h3>
              <p style={{ marginBottom: "5px" }}>
                Sign in with one of available wallet providers or create a new
                wallet.
              </p>
              <a href="/#" className="wall_link">
                {" "}
                What is a wallet?
              </a>

              <div className="button-span">
                <button className="btn_full">METAMASK</button>
                <button className="btn_full">ELECTRUM WALLET</button>
                <button
                  className="btn_outline"
                  style={{ whiteSpace: "nowrap" }}
                  onClick={() => setOpen(true)}
                >
                  SIGN IN
                </button>
                <button
                  className="btn_outline p-0 btn-img"
                  onClick={() => handleTheme()}
                >
                  <img src={headerIcon3} alt="back" />
                </button>
                {/* <button  className="btn_outline"><img className="back-span" src={back} alt='back' /></button>
      <button  onClick={() => setOpen(true)} className="btn_full">GET STARTED</button> */}
              </div>
            </Modal.Description>
          </Modal.Content>
        </Modal>
        <Modal
          size="tiny"
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
        >
          {/* <Modal.Header>MUSICRYPT</Modal.Header> */}
          <Modal.Content>
            <Modal.Description>
              <img
                onClick={() => setOpen(false)}
                className="close-span"
                src={close}
                alt="close"
              />
              <h3>MUSICRYPT</h3>
              <p>
                Sign in with one of available wallet providers or create a new
                wallet.
              </p>
              <a href="/#"> What is a wallet?</a>

              <div className="button-span">
                <button className="btn_full" onClick={handleMetaMaskLogin}>
                  METAMASK
                </button>
                <button className="btn_full">ELECTRUM WALLET</button>
                <button onClick={onClickLogin} className="btn_full">
                  TORUS
                </button>
                <button
                  onClick={() => {
                    setOpen1(true);
                    setOpen(false);
                  }}
                  className="btn_outline"
                >
                  SHOW MORE OPTIONS
                </button>
              </div>
              <p className="para">
                We don’t own your private key and cannot access funds without
                your confirmation
              </p>
            </Modal.Description>
          </Modal.Content>
          {/* <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Nope
        </Button>
        <Button
          content="Yep, that's me"
          labelPosition='right'
          icon='checkmark'
          onClick={() => setOpen(false)}
          positive
        />
      </Modal.Actions> */}
        </Modal>

        <Modal
          size="tiny"
          onClose={() => setOpen1(false)}
          onOpen={() => setOpen1(true)}
          open={open1}
        >
          <Modal.Content>
            <Modal.Description>
              <img
                onClick={() => setOpen1(false)}
                className="close-span"
                src={close}
                alt="close"
              />
              <h3>MUSICRYPT</h3>
              <div className="tab-div">
                <TabExampleAttachedFalse />
              </div>
            </Modal.Description>
          </Modal.Content>
        </Modal>
        <Modal
          size="tiny"
          onClose={() => setOpen2(false)}
          onOpen={() => setOpen2(true)}
          open={open2}
        >
          <Modal.Content>
            <Modal.Description>
              <img className="back-span" src={back} alt="back" />
              <img
                onClick={() => setOpen2(false)}
                className="close-span"
                src={close}
                alt="close"
              />
              <h3>MUSICRYPT</h3>
              <h4>Terms & Conditions of service</h4>
              <p className="para-p">
                Please Take few minutes to read and understand Musicrypt terms
                of service. To continue, you’ll need to accept the terms of
                service by checking the box.
              </p>
              <div className="div-check">
                <label className="checkbox">
                  <input type="checkbox" />
                  <span className="checkmark"></span>
                  <span>I’m at least 13 years old.</span>
                </label>
                <label className="checkbox">
                  <input type="checkbox" />
                  <span className="checkmark"></span>
                  <span>I accept the Musicrypt Terms of service</span>
                </label>
                {/* <Form.Field label='I’m at least 13 years old.' control='input' type='checkbox' />
              <Form.Field label='I accept the Musicrypt Terms of service' control='input' type='checkbox' /> */}
              </div>
              <div className="button-span">
                <button className="btn_full">PROCEED</button>
                <button className="btn_outline">SIGN OUT</button>
              </div>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </Container>
    </StyledContainer>
  );
};

export default Header;
