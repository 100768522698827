import * as React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ROOT } from "../constants/routes";
/* Pages folder is renamed to pages */
import Pages from "../pages";

const Root = () => (
  <Router>
    <Switch>
      <Route path={ROOT} component={Pages} />
    </Switch>
  </Router>
);
export default Root;