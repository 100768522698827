import StyledContainer from "./styles";
import tickBadge from "../../../../../../styles/images/tick-badge.svg";
import Daimond from "../../../../../../styles/images/Group369.png";
import defaultImg from "../../../../../../styles/images/default-img.svg";
import Timer from "../../timer/timer";
import { useEffect, useState } from "react";

const AfterPreviewCard = ({
  useAs,
  coverDescriptionText,
  overlayText,
  bidStockNumber = 1,
  bidSftValue = "",
  curencyType = "ETH",
  title = "",
  mediaType,
  isSingle = true,
  isOpenForBid = false,
  likeNumbers = 200,
  bidImage,
  isbidNotForSale = false,
  isLike = false,
  isStackContainer = false,
  hotBid,
  selectedPurchaseType,
}: any) => {

  const [restartTimer, setRestartTimer] = useState(false);

  const getDisplayValue = () => {
    if (isOpenForBid) {
      return "Open for bids";
    }
    return bidSftValue ? bidSftValue + " " + curencyType : "Not for Sale";
  };

  useEffect(() => {
    if (selectedPurchaseType !== "ta") {
      setRestartTimer(true);
    }
  }, []);

  return (
    <StyledContainer isStackContainer={isStackContainer}>
      <div
        className="after-preview-bid-card-container"
        {...(useAs === "shots" && { style: { margin: "20px" } })}
      >
        <div className="layer_inner_wrap">
          {/* Layer1 head */}
          <div className="after-preview-bid-card-layer1">
            <div className="after-preview-bid-card-circle-container">
              {/* <img className="after-preview-bid-card-circle-image" src={Logo} alt="" /> */}
              <span>
                <img
                  className="after-preview-bid-card-badge"
                  src={tickBadge}
                  alt=""
                />
              </span>
            </div>
            <div>
              <p>...</p>
            </div>
          </div>

          {/* Layer 2 Image with overlay */}
          <div className="after-preview-bid-card-layer2">
            <div className="after-preview-bid-card-layer2-image-card">
              <div className="">
                {mediaType.includes("image") && (
                  <img
                    style={{
                      width: "100%",
                      maxWidth: "234px",
                      minHeight: "100px",
                      height: "230px",
                    }}
                    src={bidImage || defaultImg}
                    alt=""
                  />
                )}
                {mediaType.includes("video") && (
                  <video
                    src={bidImage}
                    loop
                    autoPlay
                    controlsList="nodownload noremoteplayback noplaybackrate"
                    disablePictureInPicture
                    muted
                  ></video>
                )}
              </div>
            </div>
            {overlayText && (
              <span className="after-preview-bid-card-layer2-overlay">
                {overlayText}
              </span>
            )}
          </div>

          {/* Layer 3 headtext */}
          <div className="after-preview-bid-card-layer3">
            <div className="title">{coverDescriptionText}</div>
          </div>

          {/* Variations starts here */}
          {selectedPurchaseType === "ta" && (
            <div className="after-preview-card-timer">
              <Timer restartTimer={restartTimer} />
            </div>
          )}
          {/* Layer 4 and 5 Stock and bid sft */}
          {useAs === "bids" && (
            <>
              <div className="after-preview-bid-card-layer4">
                {isbidNotForSale ? (
                  <div className="after-preview-bid-card-layer4-not-for-sale">
                    <img src={Daimond} alt="" />
                    Not for sale
                  </div>
                ) : (
                  <>
                    <div className="after-preview-bid-card-layer4-sft">
                      {hotBid?.price}
                      {hotBid?.currency}
                    </div>
                    <span>.</span>
                    <div className="after-preview-bid-card-layer4-stock">
                      <b>{hotBid?.quantity}</b>in stock
                    </div>
                  </>
                )}
              </div>

              <div className="after-preview-bid-card-layer5">
                <p>{isbidNotForSale ? "MAKE ME OFFER" : "PLACE A BID"}</p>
                {isLike && (
                  <div className="after-preview-bid-card-layer5-heart">
                    {likeNumbers}
                  </div>
                )}
              </div>
            </>
          )}

          {useAs === "aution" && (
            <>
              <div className="after-preview-bid-card-layer4">{title}</div>
              <div className="after-preview-bid-card-layer4">
                <div className="after-preview-bid-card-layer4-sft">
                  {getDisplayValue()}
                </div>
                <span>.</span>
                <div className="after-preview-bid-card-layer4-stock">
                  <b>{bidStockNumber || 1}</b>in stock
                </div>
              </div>

              <div className="after-preview-bid-card-layer5">
                {(selectedPurchaseType === "fp" || selectedPurchaseType === "ob") && <p>{bidSftValue ? "Buy now" : "PLACE A BID"}</p>}
                {isLike && (
                  <div className="after-preview-bid-card-layer5-heart">
                    {likeNumbers}
                  </div>
                )}
              </div>
            </>
          )}

          {useAs === "shots" && (
            <>
              <div className="after-preview-bid-card-layer4">
                <div className="after-preview-bid-card-layer4-sft">
                  {bidSftValue}
                </div>
                <span>.</span>
                <div className="after-preview-bid-card-layer4-stock">
                  <b>{bidStockNumber}</b>in stock
                </div>
              </div>

              <div className="after-preview-bid-card-layer5">
                <p>PLACE A BID</p>
                {isLike && (
                  <div className="after-preview-bid-card-layer5-heart">
                    {likeNumbers}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </StyledContainer>
  );
};

export default AfterPreviewCard;
