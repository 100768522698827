import { combineReducers } from "redux";
import exampleReducer from "../../app/modules/example/duck/reducer";
import homeReducer from "../../app/modules/home/duck/reducer";
import marketPlaceReducer from "../../app/modules/market-place/duck/reducer";
import nftFixedPriceReducer from "../../app/modules/nft-fixed-price/duck/reducer";
import headerReducer from "../../components/shared/header/duck/reducer";

const rootReducer = combineReducers({
    exampleReducer,
    homeReducer,
    marketPlaceReducer,
    nftFixedPriceReducer,
    headerReducer,
});

export default rootReducer;
