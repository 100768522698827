 import { useState } from "react";
import "./detail-right.css";
import dot from "../../../../../styles/images/dot.svg";
import shareIcon from "../../../../../styles/images/share-icon.svg";
import likeIcon from "../../../../../styles/images/like-icon.svg";
import tickBadge from "../../../../../styles/images/tick-badge-big.svg";
import defaultImg from "../../../../../styles/images/default.svg";
import cloud from "../../../../../styles/images/cloud.svg";
import close from "../../../../../styles/images/close.svg";
import facebook from "../../../../../styles/images/facebook-icon.svg";
import twitter from "../../../../../styles/images/twitter-icon.svg";
import telegram from "../../../../../styles/images/telegram-icon.svg";
import email from "../../../../../styles/images/email-icon.svg";
import link from "../../../../../styles/images/link-icon.svg";
import { Tab, Modal } from 'semantic-ui-react'
import StyledContainer from "./styles";
import PlaceBidModal from "../../../nft-timed-auction/components/place-bid/place-bid";
const DetailRight = (props: any) => {
//   const { image, title, subTitle } = props;
const [show, setShow] = useState(false)
const [open, setOpen] = useState(false);
const panes = [
  {
    menuItem: 'Owners',
    render: () => <Tab.Pane attached={false}>
      <div className="tab-block">
        <div className="des-block">
          <div className="des-block-left"> 
            <div className="img_block">
            <img className="img-big" src={defaultImg} alt=""/>              
              <img className="img-small" src={tickBadge} alt='' />
            </div>
            <div className="info_block">
              <h3 className="title">RARIBOY</h3>
              <p>2/2 on sale for 0.18 ETH each</p>
            </div>
          </div> 
          <div className="des-block-left">           
            <img src={cloud} alt='' style={{ cursor:"pointer" }}/>
          </div>
        </div>
        <div className="des-block">
          <div className="des-block-left"> 
            <div className="img_block">
            <img className="img-big" src={defaultImg} alt=""/>              
              <img className="img-small" src={tickBadge} alt='' />
            </div>
            <div className="info_block">
              <h3 className="title">RARIBOY</h3>
              <p>2/2 on sale for 0.18 ETH each</p>
            </div>
          </div> 
          <div className="des-block-left">           
            <img src={cloud} alt='' style={{ cursor:"pointer" }}/>
          </div>
        </div>
        <div className="des-block">
          <div className="des-block-left"> 
            <div className="img_block">
            <img className="img-big" src={defaultImg} alt=""/>              
              <img className="img-small" src={tickBadge} alt='' />
            </div>
            <div className="info_block">
              <h3 className="title">RARIBOY</h3>
              <p>2/2 on sale for 0.18 ETH each</p>
            </div>
          </div> 
          <div className="des-block-left">           
            <img src={cloud} alt='' style={{ cursor:"pointer" }}/>
          </div>
        </div>        
      </div>
      
      </Tab.Pane>,
  },
  {
    menuItem: 'Bids',
    render: () => <Tab.Pane attached={false}>
      <div className="tab-block">
        <div className="des-block">
          <div className="des-block-left"> 
            <div className="img_block">
            <img className="img-big" src={defaultImg} alt=""/>              
              <img className="img-small" src={tickBadge} alt='' />
            </div>
            <div className="info_block">
              <h3 className="title">RARIBOY</h3>
              <p>2/2 on sale for 0.18 ETH each</p>
            </div>
          </div> 
          <div className="des-block-left">           
            <img src={cloud} alt='' style={{ cursor:"pointer" }}/>
          </div>
        </div>
        <div className="des-block">
          <div className="des-block-left"> 
            <div className="img_block">
            <img className="img-big" src={defaultImg} alt=""/>              
              <img className="img-small" src={tickBadge} alt='' />
            </div>
            <div className="info_block">
              <h3 className="title">RARIBOY</h3>
              <p>2/2 on sale for 0.18 ETH each</p>
            </div>
          </div> 
          <div className="des-block-left">           
            <img src={cloud} alt=''style={{ cursor:"pointer" }} />
          </div>
        </div>       
      </div>
     
    </Tab.Pane>,
  },
  {
    menuItem: 'History',
    render: () => <Tab.Pane attached={false}>
        <div className="tab-block">
        <div className="des-block">
          <div className="des-block-left"> 
            <div className="img_block">
              <img className="img-big" src={defaultImg} alt=""/>              
              <img className="img-small" src={tickBadge} alt='' />
            </div>
            <div className="info_block">
              <h3 className="title">RARIBOY</h3>
              <p>2/2 on sale for 0.18 ETH each</p>
            </div>
          </div> 
          <div className="des-block-left">           
            <img src={cloud} alt='' style={{ cursor:"pointer" }}/>
          </div>
        </div>
      </div>
     
    </Tab.Pane>,
  }
]

const TabExampleAttachedFalse = () => (
  <Tab menu={{ attached: false }} panes={panes} />
)

const handlePlaceBid =(price: number)=>{
  setShow(true);
}

const onClose = () =>{
setShow(false);
}
  return (
    <StyledContainer>
      <PlaceBidModal show={show} onClose={onClose} />
      <div className="right-block">
      <div className="head-block">
        <div className="des-block">
          <div className="des-block-left">
            {/* <div className="img_block">
              <img src={tickBadge} alt='' />
            </div> */}
            <div className="info_block">
              <h3 className="title">Cover Description</h3>
              <p>{props?.marketPlaceData?.title}</p>
            </div>
          </div> 
          <div className="des-block-left">
            <span className="count"><img src={likeIcon} alt='' style={{ cursor:"pointer" }}/>124</span>
            <img src={shareIcon} alt='' style={{ cursor:"pointer" }}  onClick={() => setOpen(true)} />
            <img src={dot} alt='' style={{ cursor:"pointer" }}/>
          </div>
        </div>
      </div>
      <div className="middle-block">
        <p>{props?.marketPlaceData?.description}</p>
      </div>  
      <div className="des-block-main">
        <div className="inner-block-main">
          <h3>Creator <span>{props?.marketPlaceData?.royalty}% royalties</span></h3>
          <div className="inner-sub">
            <div className="img_block">
              <img src={tickBadge} alt='' />
            </div>
            <div className="info_block">
              <h3 className="title">Cover Description</h3>
              <p>Follow</p>
            </div>
          </div>
        </div>
        <div className="inner-block-main">
          <h3>Collection</h3>
          <div className="inner-sub">
            <div className="img_block">
              <img src={tickBadge} alt='' />
            </div>
            <div className="info_block">
              <h4 className="title">{props?.marketPlaceData?.collection?.displayName}</h4>
              <p>Follow</p>
            </div>
          </div>
        </div>
      </div>
      <div className="tab-div">
        <TabExampleAttachedFalse />
      </div>
      <div className="last-block">
            <h2>0.02 wETH</h2>
            <p>Highest bid by <span>@johnson10</span></p>
            <h4>$182 for 0.00 for 1 edition</h4>
            <div className="button-span">
              <button className="btn_full">BUY FOR {props?.marketPlaceData?.price} {props?.marketPlaceData?.currency}</button>
              <button onClick={()=>handlePlaceBid(props?.marketPlaceData?.price)} className="btn_outline">PLACE A BID</button>
            </div>
        </div>
    </div>

    <Modal
    size="tiny"
    onClose={() => setOpen(false)}
    onOpen={() => setOpen(true)}
    open={open}
  >
    {/* <Modal.Header>MUSICRYPT</Modal.Header> */}
    <Modal.Content>
      <Modal.Description>
        <img
          onClick={() => setOpen(false)}
          className="close-span"
          src={close}
          alt="close"
        />
        <h3>Share this NFT</h3>
        <div className="share-icon">
          <div className="links">
          <a href=""><img src={facebook} alt="facebook"/></a>
          <span>Facebook</span>        
          </div>
          <div className="links">
          <a href=""> <img src={twitter} alt="twitter"/></a>
          <span>Twitter</span>
          </div>
          <div className="links">
          <a href=""> <img src={telegram} alt="telegram"/></a>
          <span>Telegram</span>
          </div>
          <div className="links">
          <a href=""> <img src={email} alt="email"/></a>
          <span>E-mail</span>
          </div>
          <div className="links">
          <a href=""> <img src={link} alt="link"/></a>
          <span>Copy</span>
          </div>
        </div>
      </Modal.Description>
    </Modal.Content>
  </Modal>
    </StyledContainer>
    
  );
};

export default DetailRight;
