import styled, { css } from "styled-components";

export default styled.div`
    ${css`
    .home-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 30px 0;
    }
    
    .home-category-slide {
        height: 36px;
        margin: 50px 0;
    }
    .home-artist-slide {
        margin: 50px 0;
    }
    
    .home-artist-slide {
        margin: 50px 0;
    }
    .home-popshot-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .catButton {
        width: calc(100% - 10px);
        margin: 0 5px;
        font-size: 13px;
        background-color: ${props => props.theme.mode === "dark" ? "black" : "white"} !important;
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"} !important;
        padding: 10px;
        background: none;
        border: ${props => props.theme.mode === "dark" ? "1px solid #707070" : "1px solid #dddddd"};
        border-radius: 5px; 
        font-family: "Montserrat-Bold";     
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .catButtonSelected {
        background-color: #223998 !important;
        color: #FFFFFF !important;
        border: 1px solid #223998;
    }

    .live-auction-container {
        padding-top: 10px;
    }
    `}
`