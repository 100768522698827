//import React, { useState, useEffect } from "react";
import { Container, Dimmer, Loader } from "semantic-ui-react";
import "./footer.css";
//import { Input, Button } from 'semantic-ui-react'
import instagram from "../../../styles/images/instagram.svg";
import telegram from "../../../styles/images/telegram.svg";
import twitter from "../../../styles/images/twitter.svg";
import youtube from "../../../styles/images/youtube.svg";
import discord from "../../../styles/images/discord.svg";
import instagramDark from "../../../styles/images/instagram-dark.svg";
import telegramDark from "../../../styles/images/telegram-dark.svg";
import twitterDark from "../../../styles/images/twitter-dark.svg";
import youtubeDark from "../../../styles/images/youtube-dark.svg";
import discordDark from "../../../styles/images/discord-dark.svg";
import StyledContainer from "./styles";
import AxiosRequest, { REQUESTS } from "../../../utils/hooks/useRequest";
import { toast } from "react-toastify";
import { useState } from "react";
const Footer = () => {
  const currentTheme = localStorage.getItem("nftTheme");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loaderConfig, setLoaderConfig] = useState({
    active: false,
    text: "Loading...",
    size: "massive",
  });

  const handleEmailChange = (event: any) => {
    const { value } = event.target;
    const emailValue = value.toLowerCase();
    const emailPattern = new RegExp(
      /^([\w.%+-]+)@([\w-]+\.)+([a-zA-Z]{2,15})$/i
    );
    if (!emailPattern.test(emailValue) && !emailValue) {
      setEmail(emailValue);
      setEmailError("Please enter valid Email Address");
    } else {
      setEmail(emailValue);
      setEmailError("");
    }
  };

  const handleEmailSubmit = async () => {
    setLoaderConfig({ ...loaderConfig, active: true });
    const { executeRequest } = AxiosRequest({
      method: REQUESTS.POST,
      body: { email },
      url: "subscription/create-subscription",
    });
    try {
      const result = await executeRequest();
      if ([200, 201, 203].includes(result?.statusCode)) {
        setLoaderConfig({ ...loaderConfig, active: false });
        toast.success(result?.message);
        setEmail("");
      } else {
        setLoaderConfig({ ...loaderConfig, active: false });
        toast.error(
          result?.message === "email must be an email"
            ? `"Email" must be a valid email`
            : result?.message
        );
      }
    } catch (err: any) {
      setLoaderConfig({ ...loaderConfig, active: false });
      toast.error("Something Wrong");
    }
  };
  return (
    <StyledContainer>
      <Dimmer active={loaderConfig.active} page={true}>
        <Loader size="massive">{loaderConfig.text}</Loader>
      </Dimmer>
      <Container fluid className="footer-div">
      <Container>
        <div className="main-footer">
          <div className="sub-div">
            <h4>GET THE LATEST MUSICRYPT UPDATES</h4>
            <div className="">
              <input
                type="text"
                className="footer-input"
                placeholder="YOUR EMAIL ADDRESS"
                value={email}
                onChange={handleEmailChange}
              />
              <button
                className="footer-button"
                onClick={handleEmailSubmit}
                disabled={!!emailError || !email}
                >
                I’M IN
              </button>
                <p className="err_info_msg">{emailError}</p>
            </div>
            <div className="social-icon">
              <a href="/#" target="_blank">              
                <img src={currentTheme == 'dark' ? twitter : twitterDark} alt="twitter" />
              </a>
              <a href="/#" target="_blank">               
                <img src={currentTheme == 'dark' ? instagram : instagramDark} alt="instagram" />
              </a>
              <a href="/#" target="_blank">                
                <img src={currentTheme == 'dark' ? telegram : telegramDark} alt="telegram" />
              </a>
              <a href="/#" target="_blank">              
                <img src={currentTheme == 'dark' ? discord : discordDark} alt="discord" />
              </a>
              <a href="/#" target="_blank">               
                <img src={currentTheme == 'dark' ? youtube : youtubeDark} alt="youtube" />
              </a>
            </div>
          </div>

          <div className="sub-div">
            <h4>MUSICRYPT</h4>
            <div className="menu-list">
              <a href="/#">EXPLORE</a>
              <a href="/#">MARKETPLACE</a>
              <a href="/#">MY STUFF</a>
              <a href="/#">
                ACTIVITY<span>new</span>
              </a>
              <a href="/#">HOW IT WORKS</a>
            </div>
          </div>
          <div className="sub-div">
            <h4>COMMUNITY</h4>
            <div className="menu-list">
              <a href="/#">MUSICRYPT TOKES</a>
              <a href="/#">DISCUSSION</a>
              <a href="/#">VOTING</a>
              <a href="/#">SUGGEST FEATURE</a>
              <a href="/#">MUSICRYPT PROTOCOL</a>
              <a href="/#">SUBSCRIBE</a>
            </div>
          </div>
          <div className="sub-div">
            <h4>COMMUNITY</h4>
            <div className="menu-list">
              <a href="/#">PRIVACY POLICY</a>
              <a href="/#">TERMS & CONDITIONS</a>
            </div>
          </div>
        </div>
        <p className="copy-right">
          &copy; MUSICRYPT, INC. ALL RIGHTS RESERVED{" "}
        </p>
      </Container>
      </Container>
    </StyledContainer>
  );
};

export default Footer;
