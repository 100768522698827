//import React, { useState, useEffect } from "react";
//import { useDispatch, useSelector } from "react-redux";
//import { marketPlaceAction } from "./duck/action";
import { Container, 
  //Label, Menu, Tab 
} from "semantic-ui-react";
import TabPortfolio from "./components/tab-portfolio/tab-portfolio";

// import "./my-activity";
//import dot from "../../../styles/images/dot.svg";
import tickBadge from "../../../styles/images/tick-badge-big.svg";
import defaultImg from "../../../styles/images/default.svg";
import MyPortfolioCss from './my-portfolio'
import filter from "../../../styles/images/filter.svg";
const MyPortfolioPage = () => {
  // const panes = [
  //   {
  //     menuItem: 'Owners',
  //     render: () => <Tab.Pane attached={false}>
  //       <div className="tab-block">
  //         <div className="des-block">
  //           <div className="des-block-left"> 
  //             <div className="img_block">
  //               <img className="img-big" src={defaultImg} alt=""/>              
  //               <img className="img-small" src={tickBadge} alt='' />
  //             </div>
  //             <div className="info_block">
  //               <h3 className="title">RARIBOY</h3>
  //               <p>2/2 on sale for 0.18 ETH each</p>
  //             </div>
  //           </div> 
  //           <div className="des-block-left">           
  //             <img src={dot} alt='' />
  //           </div>
  //         </div>
  //         <div className="des-block">
  //           <div className="des-block-left"> 
  //             <div className="img_block">
  //               <img className="img-big" src={defaultImg} alt=""/>              
  //               <img className="img-small" src={tickBadge} alt='' />
  //             </div>
  //             <div className="info_block">
  //               <h3 className="title">RARIBOY</h3>
  //               <p>2/2 on sale for 0.18 ETH each</p>
  //             </div>
  //           </div> 
  //           <div className="des-block-left">           
  //             <img src={dot} alt='' />
  //           </div>
  //         </div>
  //         <div className="des-block">
  //           <div className="des-block-left"> 
  //             <div className="img_block">
  //               <img className="img-big" src={defaultImg} alt=""/>              
  //               <img className="img-small" src={tickBadge} alt='' />
  //             </div>
  //             <div className="info_block">
  //               <h3 className="title">RARIBOY</h3>
  //               <p>2/2 on sale for 0.18 ETH each</p>
  //             </div>
  //           </div> 
  //           <div className="des-block-left">           
  //             <img src={dot} alt='' />
  //           </div>
  //         </div>        
  //       </div>
        
  //       </Tab.Pane>,
  //   },
  //   {
  //     menuItem: 'Bids',
  //     render: () => <Tab.Pane attached={false}>
  //       <div className="tab-block">
  //         <div className="des-block">
  //           <div className="des-block-left"> 
  //             <div className="img_block">
  //               <img className="img-big" src={defaultImg} alt=""/>              
  //               <img className="img-small" src={tickBadge} alt='' />
  //             </div>
  //             <div className="info_block">
  //               <h3 className="title">RARIBOY</h3>
  //               <p>2/2 on sale for 0.18 ETH each</p>
  //             </div>
  //           </div> 
  //           <div className="des-block-left">           
  //             <img src={dot} alt='' />
  //           </div>
  //         </div>
  //         <div className="des-block">
  //           <div className="des-block-left"> 
  //             <div className="img_block">
  //               <img className="img-big" src={defaultImg} alt=""/>              
  //               <img className="img-small" src={tickBadge} alt='' />
  //             </div>
  //             <div className="info_block">
  //               <h3 className="title">RARIBOY</h3>
  //               <p>2/2 on sale for 0.18 ETH each</p>
  //             </div>
  //           </div> 
  //           <div className="des-block-left">           
  //             <img src={dot} alt='' />
  //           </div>
  //         </div>       
  //       </div>
       
  //     </Tab.Pane>,
  //   },
  //   {
  //     menuItem: 'History',
  //     render: () => <Tab.Pane attached={false}>
  //         <div className="tab-block">
  //         <div className="des-block">
  //           <div className="des-block-left"> 
  //             <div className="img_block">
  //               <img className="img-big" src={defaultImg} alt=""/>              
  //               <img className="img-small" src={tickBadge} alt='' />
  //             </div>
  //             <div className="info_block">
  //               <h3 className="title">RARIBOY</h3>
  //               <p>2/2 on sale for 0.18 ETH each</p>
  //             </div>
  //           </div> 
  //           <div className="des-block-left">           
  //             <img src={dot} alt='' />
  //           </div>
  //         </div>
  //       </div>
       
  //     </Tab.Pane>,
  //   }
  // ]
  // const TabPortfolio = () => (
  //   <Tab menu={{ attached: false }} panes={panes} />
  // )
  // const tabData = [
  //   {
  //     header: (
  //       <Menu.Item key='messages'>
  //         On Sale<span>4</span>
  //       </Menu.Item>
  //     ),
  //     childran: <TabPortfolio />,
  //   },
  //   {
  //     header: "Owned",
  //     childran: <TabPortfolio />,
  //   },
  //   {
  //     header: "Created",
  //     childran: <TabPortfolio />,
  //   },
  //   {
  //     header: "Liked",
  //     childran: <TabPortfolio />,
  //   }
  // ];
  return (
   
    <MyPortfolioCss>
      <Container fluid >
        <div className="sec-portfolio">
          <Container>
            <div className="des-block">
              <div className="des-block-left">
                <div className="img_block">
                  <img className="img-big img-port" src={defaultImg} alt="" />
                  <img className="img-small" src={tickBadge} alt='' />
                </div>
                <div className="info_block">
                  <h3 className="title">0X188F…9415</h3>
                  <p>124 Followers | 22 Following</p>
                </div>
              </div>
              <div className="des-block-left">
                <img src={filter} alt="filter" className="back-icon" />
              </div>
            </div>
          </Container>
        </div>
      </Container>
      <Container>
        <TabPortfolio />
      </Container>
    </MyPortfolioCss>
    
  );
};

export default MyPortfolioPage;
