import styled, { css } from 'styled-components';

const AuctionTabStyledContainer = styled.div`
width: 100%;
    ${css`
    .tabList-tab-block{
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
    }
    .tabList-tab-block .des-block {
        display: flex;    
        width: 100%;
        justify-content: space-between;
        background: ${props => props.theme.mode === "dark" ? "#151515" : "#f2f2f2"};
        padding: 20px;
        border-radius: 9px; 
        margin-bottom: 10px;
    }
    .tabList-tab-block .des-block .des-block-left{
        display: flex;
    }
    .tabList-tab-block .des-block .des-block-left .img_block {
        width: 48px;
        height: 48px;    
        border-radius: 100%;    
        position: relative;
        margin-right: 25px;
    }
    .tabList-tab-block .des-block .des-block-left .img_block .img-big {
        position: absolute;
        width: 100%;
    }
    .tabList-tab-block .des-block .des-block-left .img_block .img-small {
        position: absolute;
        right: -3px;
        bottom: -3px;
        width: 18px;
    }
    
    .tabList-tab-block .des-block .des-block-left .info_block {
        display: flex;
        flex-direction: column;
        justify-content: center;    
    }
    
    .tabList-tab-block .des-block .des-block-left .info_block .title {
        font-size: 15px;
        font-family: 'Montserrat-Bold';
        color: ${props => props.theme.color.primaryfontColor}; 
        margin-bottom: 5px;  
    }
    
    .tabList-tab-block .des-block .des-block-left .info_block p {
        font-size: 13px;
        font-family: "SF-Pro-Rounded-Bold";
        color: ${props => props.theme.color.tertiaryColor}; 
        margin-bottom: 0;
    }
    .tabList-tab-block .des-block .des-block-left .info_block p span{
        color: ${props => props.theme.color.secondaryColor}; 
    }
    `}
`;


export default AuctionTabStyledContainer;