import React from "react";
// import "./highest-bid.css";
import TimerCountDown from "../count-down-timer/countdown-timer";
import HighBidStyledContainer from './style';

const HighestBid = ({ buttonClickFunc = () => {} }: any) => {
  let year = new Date("2022-01-01").getFullYear();
  let day = new Date("2022-01-01").getDate();
  let month = new Date("2022-01-01").getMonth() + 1;
  const timer = `${year}-${month}-${day}`;
  const [timerEnd, setTimerEnd] = React.useState(false);
  const onEnded = () => {
    setTimerEnd(true);
  };

  return (
    <HighBidStyledContainer> 
    <div className="high-bid-block">
      <div className="high-bid-info-blk">
        <div className="bid-info-left">
          <h3>Highest bid by R..</h3>
          <h2>0.04 wETH</h2>
          <h4>$334.54</h4>
        </div>
        <div className="bid-info-right">
          <TimerCountDown onEnd={() => onEnded()} date={timer} />
        </div>
      </div>

      <div className="button-span">
        {!timerEnd && (
          <button className="btn_full" onClick={buttonClickFunc()}>
            PLACE A BID
          </button>
        )}
        {timerEnd && (
          <button className="btn_outline" >
           WAITING FOR OWNER TO ACCEPT
          </button>
        )}
      </div>
    </div>
    </HighBidStyledContainer>
  );
};

export default HighestBid;
