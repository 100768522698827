// import React, { useState, useEffect } from "react";
// import "./cover-description.css";
import dot from "../../styles/images/dot.svg";
import tickBadge from "../../styles/images/tick-badge-big.svg";
import shareIcon from "../../styles/images/share-icon.svg";
import likeIcon from "../../styles/images/like-icon.svg";
import cloud from "../../styles/images/cloud.svg";
import CoverDescriptionCssContainer from './style';

const CoverDescription = () => {
  // const { image, headerTitle, headerSubTitle, description } = props;


  return (
    <CoverDescriptionCssContainer> 
    <div className="coverDescription-blk">
      <div className="head-block">
        <div className="des-block">
          <div className="des-block-left">
            {/* <div className="img_block">
              <img src={tickBadge} alt='' />
            </div> */}
            <div className="info_block">
              <h3 className="title">Cover Description</h3>
              <p>Minimum bid .06w ETH</p>
            </div>
          </div> 
          <div className="des-block-left">
            <span className="count"><img src={likeIcon} alt='' style={{ cursor:"pointer" }}/>124</span>
            <img src={shareIcon} alt='' style={{ cursor:"pointer" }}/>
            <img src={dot} alt='' style={{ cursor:"pointer" }}/>
          </div>
        </div>
      </div>
      <div className="middle-block">
        <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>
      </div>  
      <div className="des-block-main">
        <div className="inner-block-main">
          <h3>Creator <span>10% royalties</span></h3>
          <div className="inner-sub">
            <div className="img_block">
              <img src={cloud} alt='' />
            </div>
            <div className="info_block">
              <h3 className="title">Artist Name</h3>
              <p style={{ cursor:"pointer" }}>Follow</p>
            </div>
          </div>
        </div>
        <div className="inner-block-main">
          <h3>Creator 10% royalties</h3>
          <div className="inner-sub">
            <div className="img_block">
              <img src={cloud} alt='' />
            </div>
            <div className="info_block">
              <h4 className="title">Artist Name</h4>
              <p style={{ cursor:"pointer" }}>Follow</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </CoverDescriptionCssContainer>
  );
};

export default CoverDescription;
