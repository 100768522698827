import StyledContainer from "./styles";

const ToggleButton = ({ selected, toggleSelected }: any) => {
  return (
    <StyledContainer>
      <div
        className={`toggle-btn-container ${
          selected ? "" : "toggle-btn-container-disabled"
        }`}
        onClick={toggleSelected}
      >
        <div
          className={`toggle-btn-button ${
            selected ? "" : "toggle-btn-disabled"
          }`}
        >
          {selected ? "" : ""}
        </div>
      </div>
    </StyledContainer>
  );
};

export default ToggleButton;
