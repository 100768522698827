import styled, {css} from 'styled-components';

 const TopSellersStyledContainer = styled.div`
    ${css`
    .seller_heading_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 15px 0;
    }
    
    .mt {
        padding: 30px 0;
    }
    .seller_title h2 {
        font-size: 25px;
        font-family: 'MADE-Outer-Sans-Regular';
        color:  ${props => props.theme.color.primaryfontColor};
        margin-bottom: 5px;
    }
    .seller_title h2 img {
        position: relative;
        top: 5px;
        left: 5px;
    }
    .seller_tab {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .seller_tab .seller_tab_btn {
        font-size: 13px;
        font-family: 'Montserrat-Bold';
        color:  ${props => props.theme.color.primaryfontColor};
        padding: 10px;
        border: 0;
        border-right: 1px solid #BCBCBC27;
        background: transparent;
        cursor: pointer;
    }
    .seller_tab .seller_tab_btn:last-child {
        border-right: 0;
    }
    .seller_wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        margin: auto;
        margin-top: 15px;
        flex-wrap: wrap;
    }
    .seller_block {
        display: flex;
        align-items: center;
        width: 24%;
        margin: 15px 1% 15px 0;
    }
    .seller_block .img_block {
        width: 57px;
        height: 57px;
        border-radius: 100%;
        background-color: #BCBCBC27;
        border: 1px solid #BCBCBC27;
        position: relative;
    }
    .seller_block .img_block img {
        position: absolute;
        right: 0;
        bottom: 0;
    }
    .seller_block .info_block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 15px;
    }
    .seller_block .info_block .title {
        font-size: 15px;
        font-family: 'Montserrat-Bold';
        color:  ${props => props.theme.color.primaryfontColor};
        margin-bottom: 5px;
    }
    .seller_block .info_block p {
        font-size: 14px;
        font-family: 'SF-Pro-Rounded-Bold';
        color: #AFAFAF;
        margin-bottom: 5px;
        text-transform: uppercase;
    }
    @media only screen and (max-width: 1200px) {
        .seller_block {
            width: 32%;
        }
    }
    @media only screen and (max-width: 991px) {
        .seller_block {
            width: 48%;
            margin: 15px 2% 15px 0;
        }
    }
    @media only screen and (max-width: 620px) {
        .seller_block {
            width: 98%;
            margin: 15px 2% 15px 0;
        }
        .seller_title h2{
            font-size: 15px;
        }
    }
    @media only screen and (max-width: 540px) {
        .seller_heading_wrapper {
           flex-direction: column;
        }
    }
    `}
`;


export default TopSellersStyledContainer;