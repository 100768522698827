import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { exampleAction } from "./duck/action";
import { Button } from 'semantic-ui-react';
import ArtistCard from '../../../components/cards/artistCard/artistCard';
import BidAutionShotCard from '../../../components/cards/bidAutionShotCard/bidAutionShotCard';

const Example = ()=> {
  const dispatch = useDispatch();
    const exampleDataFromReducer = useSelector((state:any) => state.exampleReducer.exampleText);
  useEffect(() => {
    dispatch(exampleAction());
  }, [dispatch]);
  return (
    <div>
      <header>
        <p>Hello, {exampleDataFromReducer}</p>
        <Button>I am Semantic button</Button>
        <BidAutionShotCard />
        <ArtistCard overlayData="10" artHead={"Artist Five"} artDescription={"Original Cover Art"} />
      </header>
    </div>
  );
}

export default Example;