import styled, { css } from "styled-components";

export default styled.div`
    ${css`
    .collectible-sub-type-container {
        font-family: 'Montserrat-Bold';
    }
    .collectible-sub-type {
        display: flex;
        justify-content: space-between;
        text-align: left;
        font-size: 20px;
        letter-spacing: 1.33px;
        text-transform: uppercase;
        margin-top: 50px;
        margin-bottom: -10px;
    }
    .collectible-sub-type-description {
        text-align: left;
        letter-spacing: 0px;
        color: #AFAFAF !important;
    }
    
    .ui.input>input {
        background-color: #0000;
        font-family: 'Montserrat-Bold';
        // opacity: 0.4;
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};
        // border: 1px solid white;
        margin-bottom: 10px;      
    }
    .ui.dropdown.label {
        background-color: #0000;
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};
    }
    /* .visible.menu.transition {
        background-color: #0000;
    } */
    /* .active.selected.item .item {
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};
    } */
    
    .collectible-sub-type-suggesstion {
        display: flex;
        border-left: 1px solid #AFAFAF;
    }
    
    .collectible-sub-type-suggesstion>p{
        margin-left: 5px;
    }
    .collectible-sub-type-suggesstion span{
        margin-right: 10px;
    }
    .collectible-sub-type-suggesstion .key{
        color: #AFAFAF !important;
    }
    
    .collectible-sub-type-plain-suggesstion {
        text-align: left;
        p {
            color: #AFAFAF !important;
        }
    }
    .ui.label.dropdown .menu {
        background-color: #fff;
    }
    .ui.fluid.inverted.right.labeled.input {
        border: 1px solid #707070;
        margin-bottom: 10px;
    }
   
    `}
`;