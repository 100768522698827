import styled, { css } from "styled-components";

export default styled.div`
    ${css`
     .edit-main{
        display: flex;
        width: 100%;
        padding-top : 80px;
      }
      .edit-main .edit-left{
        display: flex;
        width: 70%;
        padding: 0 65px;
        flex-direction: column;
      }
      .edit-main .edit-left .title-edit{
        margin-bottom : 50px;
        position: relative;   
      }
      .edit-main .edit-left .title-edit .arrow-left{
        position: absolute;
        top: 0;
        left: -60px;
        cursor: pointer;
      }         
      .edit-main .edit-left .title-edit p{
        color: #AFAFAF;
        font-size: 20.25px;
        font-family: "Montserrat-Bold";  
        margin-bottom: 10px;
      }
      .edit-main .edit-left .title-edit h1{
        color:   ${props => props.theme.color.primaryfontColor};;
        font-size: 39.25px;
        font-family: "Montserrat-Bold";        
      }
      .edit-main .edit-left .form-edit h2{
        color:   ${props => props.theme.color.primaryfontColor};
        font-size: 19px;
        font-family: "Montserrat-Bold";
        margin-bottom: 10px;
      }
      .edit-main .edit-left .form-edit label{
        color: #AFAFAF;
        font-size: 16.25px;
        font-family: "Montserrat-Bold";  
        margin-bottom : 10px;
      }
      .edit-main .edit-right{
        display: flex;
        width: 30%;
        flex-direction: column;
      } 
      .edit-input{
        padding-top : 20px;
      }
      .form-edit .input-group{
        margin-bottom : 40px;
      }
      .edit-input input{
        height: 46px!important;
        padding-left: 20px!important;
        margin-right: 0px!important;
        letter-spacing: 0.84px;
        color:   ${props => props.theme.color.primaryfontColor};
        opacity: 0.7;
        font-size: 16.25px;
        border: 1px solid #707070!important;
        background: transparent!important;
        font-family: "Montserrat-Bold";  
    }
    .edit-input textarea{
        height: 153px!important;
        padding-left: 20px!important;
        margin-right: 0px!important;
        letter-spacing: 0.84px;
        color:   ${props => props.theme.color.primaryfontColor}; 
        opacity: 0.7;
        font-size: 16.25px;
        border: 1px solid #707070!important;
        background: transparent!important;
        width: 100%;
        padding-top: 15px;
        font-family: "Montserrat-Bold";  
    }
    .edit-input img{
        position: absolute;   
        top: 0;
        bottom: 0;
        margin: auto;
    }
    .button-span{
        width: 100%;
        margin: 0;
        flex-direction: row;
        justify-content: space-between;
    }
    .button-span .btn_outline{
        width: 192px;
    }
    .button-span .btn_full{
        width: 220px;
    }
    .inner-verification{
        flex-direction: row;
        justify-content: space-between; 
        display: flex;
        margin-bottom : 40px;
        justify-content: center;
        align-items: center;
    }
    .inner-verification p{
        color: #AFAFAF;
        font-size: 16.25px;
        font-family: "Montserrat-Bold";  
        margin-bottom : 10px;
        padding-right: 50px;
    }
    .inner-verification .btn_white{
        color: #E154C4;
        font-size: 15px;
        font-family: "Montserrat-Bold";
        min-width: 175px;
        height: 47px;
        background: #FFFFFF;
        border-radius: 24px;
        border: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    @media only screen and (max-width: 991px) {
    
      .edit-main .edit-left .title-edit h1{       
        font-size: 24px;          
      }
      .edit-main .edit-left .title-edit p{
        font-size: 16px; 
      }
      .edit-main .edit-left .form-edit h2{
        font-size: 16px
      }
  }
    @media only screen and (max-width: 768px) {
      .edit-main{
        flex-direction: column;
      }
      .edit-main .edit-left{       
        width: 100%;
        padding: 0 25px;       
      }
      .edit-main .edit-left .title-edit .arrow-left{
        left: -38px;       
        width: 30px;
      } 
      .edit-main .edit-left .title-edit h1{       
        font-size: 18px;          
      }
      .edit-main .edit-left .title-edit p{
        font-size: 14px; 
      }
      .edit-main .edit-left .form-edit h2{
        font-size: 14px
      }
  }
    `}
`;