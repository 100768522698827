
import Countdown from "react-countdown";
import { useState, useEffect } from "react";

const getLocalStorageValue = (s:any) => localStorage.getItem(s);

const Timer = ({restartTimer}:any) => {
  const [data, setData] = useState(
    { date: Date.now(), delay: 8.64e+7 } //24 hrs in ms
  );
  const wantedDelay = 8.64e+7; // ms

  useEffect(() => {
    const savedDate = getLocalStorageValue("end_date");
    //@ts-ignore
    if (savedDate != null && !isNaN(savedDate)) {
      const currentTime = Date.now();
      const delta = parseInt(savedDate, 10) - currentTime;
      if (delta > wantedDelay) {
        //@ts-ignore
        if (localStorage.getItem("end_date").length > 0)
          localStorage.removeItem("end_date");
      } else {
        setData({ date: currentTime, delay: delta });
      }
    }
  }, []);

  useEffect(() => {
    if (restartTimer) {
        localStorage.removeItem("end_date");
    }
  }, [restartTimer]);

  return (
    <div>
      <Countdown
        date={data.date + data.delay}
        onStart={(delta) => {
          if (localStorage.getItem("end_date") == null)
            localStorage.setItem(
              "end_date",
              JSON.stringify(data.date + data.delay)
            );
        }}
        onComplete={() => {
          if (localStorage.getItem("end_date") != null)
            localStorage.removeItem("end_date");
        }}
      />
    </div>
  );
};

export default Timer;