import styled, { css } from 'styled-components';

const FiltersStyledContainer = styled.div`
    ${css`
      .multiselect-section-wrapper {
        background: ${props => props.theme.color.mainBackgroundColor};
        width: 230px;
        z-index: 10;
        border: 0.5px solid #605e5e;
        border-radius: 10px;
        top: 50px;
      }
      .multiselect-list-item-label.is-checked:after {
        border-color: #77ff70 !important;
      }
      .multiselect-apply-button {
        background-color: ${props => props.theme.color.secondaryColor};
        border-radius: 10px;
        padding: 0 20px;
        height: 35px;
        line-height: 35px;
        font-size: 12px;
        font-family: "Montserrat-Bold";
        color: ${props => props.theme.color.primaryfontColor};
      }
      .multiselect-reset-button{
        font-size: 12px;
        font-family: "Montserrat-Bold";
        color: ${props => props.theme.color.secondaryColor};
        background: ${props => props.theme.color.mainBackgroundColor};
      }
      .multiselect-button-select-all{
        font-size: 12px;
        font-family: "Montserrat-Bold";
        color: rgb(45, 129, 255);
      }
      .multiselect-list-item{}
      .multiselect-list-item:hover{
        background-color: transparent; 
      }
      .multiselect-list-item-label:focus{
        outline: 0; 
      }
      .multiselect-button-dropdown {
        border: 1px solid #707070;
        margin:0 5px;
        padding: 12px;
        border-radius: 15px;
        font-size: 12px;
        font-family: 'MADE-Outer-Sans-Regular';
        color: ${props => props.theme.color.primaryfontColor};
        background: ${props => props.theme.color.mainBackgroundColor};
        box-shadow: none;
      }
      .multiselect-button-dropdown::after {
          display: none;
      }
      .multiselect-badge{
        color: ${props => props.theme.color.secondaryColor};
        background: ${props => props.theme.color.mainBackgroundColor};
      }
      .reset_text{
        color: rgb(45, 129, 255);
        font-family: 'MADE-Outer-Sans-Regular';
        font-size: 12px;
        cursor: pointer;
        margin-left: 5px;
      }
      .reset_text: hover{
        color: ${props => props.theme.color.primaryfontColor};
      }
    `}
`;


export default FiltersStyledContainer;