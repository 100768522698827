import StylesContainer from "./styles";
import LeftArrow from "../../../../styles/images/leftArrow.svg";
import { useHistory } from "react-router";
import {
  Button,
  Icon,
  Container,
  Modal,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { useEffect, useRef, useState } from "react";
import { PreviewCard } from "../components/cards/privewCards/privewCards";
import CollectibleSubType from "../components/collectibleSubType/collectibleSubType";
import AuctionDateSubType from "../components/auctionDateSubType/auctionDateSubType";
import LogoCard from "../components/cards/logoCard/logoCard";
import FixedPrice from "../../../../styles/images/pricetags.svg";
import AddFolderLogo from "../../../../styles/images/addFolder.png";
import UnitGroup from "../../../../styles/images/unitgroup388.png";
import Infinite from "../../../../styles/images/infinite.svg";
import CollectibleSubTypeProperties from "../components/collectibeSubTypeProperties/collectibeSubTypeProperties";
import AfterPreviewCard from "../components/cards/afterPreview/afterPreview";
import AxiosRequest, { REQUESTS } from "../../../../utils/hooks/useRequest";
import { toast } from "react-toastify";
import CollectionForm from "../components/collectionForm/collectionForm";
import close from "../../../../styles/images/close.svg";
import { useSelector } from "react-redux";

const MultipleCollectible = () => {
  const history = useHistory();
  const { loginDetail } = useSelector((state:any)=> state.headerReducer)
  const [showSettings, setShowSetings] = useState(false);
  const [createCardValue, setCreateCardValue] = useState("musy");
  const [selectedPurchaseType, setSelectedPurchaseType] = useState("");
  const [unlockToggleValue, setUnlockToggleValue] = useState(false);
  const [isMasterCollection, setIsMasterCollection] = useState("master");
  const fileInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [showPreview, setShowPreview] = useState(true);
  const [previewMedia, setPreviewMedia] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [binaryImageData, setBinaryImageData] = useState("");
  const [newCustomeCollectionId, setNewCustomeCollectionId] = useState("");
  const [loaderConfig, setLoaderConfig] = useState({
    active: false,
    text: "Loading...",
    size: "massive",
  });
  const [formData, setFormData] = useState({
    isPurchaseTypeEnabled: false,
    price: "",
    title: "",
    currency: "ETH",
    description: "",
    royalities: "",
    properties: "",
    alernativeNftText: "",
    // Action
    minimumBid: "",
    startDate: "",
    endDate: "",
    // used for both Action and bid
    unlockPurchased: "",
    noOfCopies: "",
  });

  const handleUpload = async (e: any) => {
    let fileData = e.target.files[0];
    if (fileData.size <= 1024 * 1024 * 100) {
      setMediaType(fileData.type);
      let binaryFile = URL.createObjectURL(fileData);
      setPreviewMedia(binaryFile);
      setBinaryImageData(fileData);
      if (fileData.type.includes("audio")) {
        setShowPreview(true);
      } else {
        setShowPreview(false);
      }
    } else {
      toast.error("Please Upload a file less than 100 MB");
    }
  };

  useEffect(() => {
    if (selectedPurchaseType === "ta") {
      setUnlockToggleValue(true);
    }
  }, [selectedPurchaseType, createCardValue]);

  const getToggleValueCb = (value: any) => {
    if (!value) {
      setSelectedPurchaseType("");
    }
    setFormData({ ...formData, isPurchaseTypeEnabled: value });
  };

  const handleDates = (type: any, value: any) => {
    if (type === "start") {
      setFormData({ ...formData, startDate: value });
    } else if (type === "end") {
      setFormData({ ...formData, endDate: value });
    }
  };

  const handleSetings = () => {
    setShowSetings(!showSettings);
  };

  const formValidator = (): any => {
    if (!previewMedia) {
      toast.error("Please Upload an image");
      return false;
    }
    if (selectedPurchaseType === "fp") {
      if (!formData.price) {
        toast.error("Please add Price");
        return false;
      }
      if (formData.price && parseFloat(formData.price) <= 0) {
        toast.error("Please add Price more than 0");
        return false;
      }
    }
    if (selectedPurchaseType === "ta" && !formData.minimumBid) {
      toast.error("Please enter minimum bid");
      return false;
    }
    if (!formData.title) {
      toast.error("Please add Title");
      return false;
    }
    if (!formData.royalities) {
      toast.error("Please add Roylities");
      return false;
    }
    if (!formData.noOfCopies) {
      toast.error("Please add Number of Copies");
      return false;
    }
    return true;
  };

  const choosenPurchaseType = () => {
    switch (selectedPurchaseType) {
      case "fp":
        return "fixed price";
      case "ob":
        return "open for bids";
      default:
        return "not for sale";
    }
  };

  const handleCancel = () => {
    setCreateCardValue("musy");
    setSelectedPurchaseType("fp");
    setShowPreview(true);
    setPreviewMedia("");
    setBinaryImageData("");
    setFormData({
      isPurchaseTypeEnabled: false,
      price: "",
      title: "",
      description: "",
      currency: "",
      royalities: "",
      properties: "",
      alernativeNftText: "",
      // Action
      minimumBid: "",
      startDate: "",
      endDate: "",
      // used for both Action and bid
      unlockPurchased: "",
      noOfCopies: "",
    });
  };

  const handleCreateItem = async () => {
    setLoaderConfig({ ...loaderConfig, active: true });
    if (formValidator()) {
      let fData = new FormData();
      fData.append("mediaFile", binaryImageData);
      fData.append("alternateDescription", formData.alernativeNftText);
      //@ts-ignore
      fData.append("price", Number(formData.price) || 0);
      //@ts-ignore
      fData.append("quantity", Number(formData.noOfCopies));
      //@ts-ignore
      fData.append("royalty", Number(formData.royalities) || 0);
      fData.append("expireDate", formData.endDate);
      //@ts-ignore
      fData.append("isPurchasedTypeEnabled", formData.isPurchaseTypeEnabled);
      fData.append("startDate", formData.startDate);
      fData.append("currency", formData.currency);
      fData.append("properties", formData.properties);
      fData.append("collectibleType", "multiple");
      fData.append("title", formData.title);
      fData.append(
        "collectionId",
        isMasterCollection !== "master"
          ? newCustomeCollectionId
          : "Qh5jwiYRzs0kfSncTqH0K"
      );
      //@ts-ignore
      fData.append(
        "purchaseType",
        formData.isPurchaseTypeEnabled ? choosenPurchaseType() : ""
      );
      fData.append("description", formData.description);

      const { executeRequest } = AxiosRequest({
        method: REQUESTS.POST,
        body: fData,
        url: "products/create-product",
        token: {"Authorization" : `Bearer ${loginDetail?.token}`},
      });
      try {
        const result = await executeRequest();
        if ([200, 201, 203].includes(result?.statusCode)) {
          setLoaderConfig({ ...loaderConfig, active: false });
          toast.success(result?.message);
          setTimeout(() => {
            history.push("/collectible");
          }, 3000);
        } else {
          setLoaderConfig({ ...loaderConfig, active: false });
          toast.error(
            result?.message ===
              "Purchase type fixed price, time auction or open for bids is required"
              ? "Purchase type fixed price or open for bids is required"
              : result?.message
          );
        }
      } catch (err: any) {
        setLoaderConfig({ ...loaderConfig, active: false });
        toast.error("Something Wrong");
      }
    } else {
      setLoaderConfig({ ...loaderConfig, active: false });
    }
  };

  const handleCollection = (collectionData: any) => {
    setIsMasterCollection("custome");
    setNewCustomeCollectionId(collectionData?.id);
    setCreateCardValue("");
  };

  return (
    <Container>
      <StylesContainer>
        <Dimmer active={loaderConfig.active} page={true}>
          <Loader size="massive">{loaderConfig.text}</Loader>
        </Dimmer>
        <div className="sctbl-multiple-container">
          <div className="sctbl-multiple-c1">
            <img
              className="sctbl-multiple-c1-img"
              src={LeftArrow}
              alt=""
              onClick={() => history.push("/collectible")}
            />
          </div>

          <div className="sctbl-multiple-c2">
            <p className="sctbl-multiple-c2-type-text">
              Manage collectible type
            </p>
            <p className="sctbl-multiple-c2-head-text">
              Create Multiple collectible
            </p>
            <p className="sctbl-multiple-c2-upload-head-text">UPLOAD FILE</p>

            <div className="sctbl-multiple-c2-upload-box">
              {!previewMedia && (
                <>
                  <p className="sctbl-multiple-c2-upload-box-text">
                    PNG, GIF, WEBP, MP4 or MP3. Max 100mb.
                  </p>
                  <Button
                    className="sctbl-multiple-c2-upload-box-button"
                    onClick={() => fileInputRef.current.click()}
                  >
                    CHOOSE FILE
                  </Button>
                  <input
                    ref={fileInputRef}
                    type="file"
                    hidden
                    maxLength={100}
                    accept="image/*,audio/*,video/*"
                    onChange={(e) => handleUpload(e)}
                  />
                </>
              )}
              {previewMedia && (
                <div className="sctbl-multiple-c2-upload-box-preview">
                  <span
                    onClick={() => {
                      setPreviewMedia("");
                      setShowPreview(true);
                    }}
                  >
                    X
                  </span>
                  {mediaType.includes("image") && (
                    <img src={previewMedia} alt="" />
                  )}
                  {mediaType.includes("video") && (
                    <video src={previewMedia} loop autoPlay controls></video>
                  )}
                  {mediaType.includes("audio") && (
                    <audio
                      src={previewMedia}
                      loop
                      controls
                      controlsList="nodownload noplaybackrate"
                    ></audio>
                  )}
                </div>
              )}
            </div>

            <CollectibleSubType
              title="PURCHASE TYPE"
              description="Enter price to allow users instantly purchase your NFT"
              textFiledPlaceHolder="E.G 100"
              isToggleButton
              defaultToggleValue={true}
              getToggleValue={(val: any) => getToggleValueCb(val)}
            />

            {formData.isPurchaseTypeEnabled && (
              <div className="sctbl-multiple-c2-logo-card-holder">
                <div style={{ marginRight: "20px" }} className="box-right">
                  <LogoCard
                    logo={FixedPrice}
                    text="Fixed Price"
                    select={selectedPurchaseType === "fp"}
                    betaName="fp"
                    onTileClick={setSelectedPurchaseType}
                  />
                </div>
                <LogoCard
                  logo={Infinite}
                  text="Open for bids"
                  select={selectedPurchaseType === "ob"}
                  betaName="ob"
                  onTileClick={setSelectedPurchaseType}
                />
              </div>
            )}

            {selectedPurchaseType === "fp" && (
              <CollectibleSubType
                title="PRICE"
                description="Enter price for one piece"
                textFiledPlaceHolder="E.G 100"
                isTextField
                isSuggestion
                inputType="number"
                inputValue={formData.price}
                getInputData={(e: any) =>
                  setFormData({ ...formData, price: e })
                }
                defaultDropDownValue="ETH"
                textFieldDropDownOption={[
                  { key: "ETH", text: "ETH", value: "ETH" },
                  { key: "USD", text: "USD", value: "USD" },
                  { key: "INR", text: "INR", value: "INR" },
                ]}
                getDropdownValue={(data: any) =>
                  setFormData({ ...formData, currency: data })
                }
                suggestionsKVArrayData={[
                  { key: "Service fee", value: "2.5%" },
                  {
                    key: "You will receive",
                    value: `${(Number(formData.price) * (2.5 / 100)).toFixed(
                      2
                    )} ${formData.currency}`,
                  },
                ]}
              />
            )}

            {selectedPurchaseType === "ta" && (
              <CollectibleSubType
                title="MINIMUM BID"
                description="Enter Minimum bid"
                textFiledPlaceHolder="E.G 100"
                isTextField
                isDropDown={false}
                inputValue={formData.minimumBid}
                getInputData={(e: any) =>
                  setFormData({ ...formData, minimumBid: e })
                }
                inputType="number"
                isPlainSuggestion
                plainSuggestionText="Bids below this amount won’t be allowed."
                textFieldDropDownOption={[]}
              />
            )}

            {selectedPurchaseType === "ta" && (
              <AuctionDateSubType handleDates={handleDates} />
            )}

            <CollectibleSubType
              title="UNLOCK ONCE PURCHASED"
              description="Content will be unlocked after successful transaction"
              isToggleButton
              defaultToggleValue={
                (selectedPurchaseType === "ta" ||
                  selectedPurchaseType === "ob") &&
                unlockToggleValue
              }
              isDropDown={false}
              getToggleValue={(val: any) => setUnlockToggleValue(val)}
              {...((selectedPurchaseType === "ta" ||
                selectedPurchaseType === "ob") &&
                unlockToggleValue && {
                  isTextField: true,
                  textFiledPlaceHolder:
                    "DIGITAL KET OR CODE TO REDEEM OR LINK TO A FILE…",
                  isPlainSuggestion: true,
                  plainSuggestionText: "Mark down is supported",
                })}
            />

            <p className="sctbl-multiple-c2-collection-heading">
              CHOOSE COLLECTION
            </p>
            <div className="sctbl-multiple-c2-collection-holder">
              <div>
                <LogoCard
                  logo={AddFolderLogo}
                  text="Create"
                  select={false}
                  borderSelect={isMasterCollection !== "master"}
                  isSubText
                  subText="ERC-721"
                  betaName="createCol"
                  onTileClick={setCreateCardValue}
                />
              </div>
              <div style={{ marginLeft: "25px" }} className="box-dev">
                <LogoCard
                  logo={UnitGroup}
                  text="MUSY CRYPT"
                  select={false}
                  borderSelect={isMasterCollection === "master"}
                  isSubText
                  betaName="musy"
                  subText="MUSY"
                  onTileClick={setCreateCardValue}
                />
              </div>
            </div>

            <CollectibleSubType
              title="TITLE"
              description="Enter title for your NFT"
              textFiledPlaceHolder="E.G REDEEMABLE T-SHIRT WITH LOGO"
              isTextField
              inputValue={formData.title}
              getInputData={(e: any) => setFormData({ ...formData, title: e })}
              isDropDown={false}
            />

            <CollectibleSubType
              title="DESCRIPTION (OPTIONAL)"
              description="Enter description for your NFT"
              textFiledPlaceHolder="E.G GET THE REAL T-SHIRT AFTER PURCHASING"
              isTextField
              inputValue={formData.description}
              getInputData={(e: any) =>
                setFormData({ ...formData, description: e })
              }
              isDropDown={false}
            />

            <CollectibleSubType
              title="ROYALTIES"
              description="Enter royalities for your NFT"
              textFiledPlaceHolder="E.G 100"
              isTextField
              isSuggestion
              inputValue={formData.royalities}
              getInputData={(e: any) =>
                setFormData({ ...formData, royalities: e })
              }
              isDropDown={false}
              textFieldDropDownOption={[]}
              suggestionsKVArrayData={[
                {
                  key: "Suggested",
                  value: "0%, 10%, 20%, 30%. Maximum is 50%",
                },
              ]}
            />

            <CollectibleSubType
              title="NUMBER OF COPIES"
              description=""
              textFiledPlaceHolder="Enter number of copies"
              isTextField
              isPlainSuggestion
              isDropDown={false}
              inputType="noc"
              inputValue={formData.noOfCopies}
              getInputData={(e: any) =>
                setFormData({ ...formData, noOfCopies: e })
              }
              plainSuggestionText="Amount of tokens"
            />

            <div className="sctbl-multiple-c2-settings-button-holder">
              <Button
                className={
                  showSettings
                    ? "sctbl-multiple-c2-settings-button-hide"
                    : "sctbl-multiple-c2-settings-button"
                }
                fluid
                onClick={() => handleSetings()}
              >
                {showSettings
                  ? "HIDE ADVANCED SETTINGS"
                  : "SHOW ADVANCED SETTINGS"}
                <Icon
                  className="sctbl-multiple-c2-settings-button-icon"
                  name={showSettings ? "angle down" : "angle right"}
                />
              </Button>
            </div>

            {showSettings && (
              <CollectibleSubTypeProperties
                title="PROPERTIES (OPTIONAL)"
                description="Enter Properties for your NFT"
                textFiledPlaceHolder="E.G SIZE"
                isTextField
                inputValue={formData.properties}
                getInputData={(e: any) =>
                  setFormData({ ...formData, properties: e })
                }
                isDropDown={false}
                textFieldDropDownOption={[]}
              />
            )}

            {showSettings && (
              <CollectibleSubType
                title="ALTERNATIVE TEXT FOR NFT (OPTIONAL)"
                description="Text that will be used in VoiceOver for people with disabilities"
                textFiledPlaceHolder="IMAGE DESCRIPTION IN DETAILS"
                isTextField
                inputValue={formData.alernativeNftText}
                getInputData={(e: any) =>
                  setFormData({ ...formData, alernativeNftText: e })
                }
                isDropDown={false}
                textFieldDropDownOption={[]}
              />
            )}

            <div className="sctbl-multiple-c2-cancel-create-buttons">
              <Button
                className="sctbl-multiple-c2-cancel-buttons"
                onClick={handleCancel}
              >
                CANCEL
              </Button>
              <Button
                className="sctbl-multiple-c2-create-buttons"
                onClick={handleCreateItem}
              >
                CREATE ITEM
              </Button>
            </div>
          </div>

          <div className="sctbl-multiple-c3">
            <p className="sctbl-multiple-c3-preview">PREVIEW</p>
            {showPreview && <PreviewCard />}
            {!showPreview && (
              <AfterPreviewCard
                useAs="aution"
                bidImage={previewMedia}
                mediaType={mediaType}
                curencyType={formData.currency}
                bidStockNumber={formData.noOfCopies}
                title={formData.title}
                isOpenForBid={selectedPurchaseType === "ob"}
                bidSftValue={formData.isPurchaseTypeEnabled && formData.price}
                isStackContainer={formData.noOfCopies && true}
              />
            )}
          </div>
        </div>

        <Modal
          size="tiny"
          style={{ width: "30%" }}
          onClose={() => setCreateCardValue("")}
          onOpen={() => setCreateCardValue("")}
          open={createCardValue === "createCol"}
        >
          <Modal.Content>
            <Modal.Description>
            <img  onClick={() => setCreateCardValue('')}
              className="close-span"
              src={close}
              alt="close"
            />
              <CollectionForm handleCollection={handleCollection} />
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </StylesContainer>
    </Container>
  );
};

export default MultipleCollectible;
