import StyledContainer from "./styles";
import { Input, Dropdown } from "semantic-ui-react";
import { useState } from "react";

const AuctionDateSubType = ({ handleDates }: any) => {
  const expiryDateOptions = [
    { key: "1 days", text: "1 days", value: "1" },
    { key: "3 days", text: "3 days", value: "3" },
    { key: "5 days", text: "5 days", value: "5" },
    { key: "7 days", text: "7 days", value: "7" },
  ];
  let min = new Date().toISOString().split(".")[0];
  const [startDate, setStartDate] = useState(min);

  const handleDateChange = (type:any, value:any):any => {
    handleDates(type, value)
    setStartDate(value);
  }

  return (
    <StyledContainer>
      <div className="collectible-auction-date-container">
        <div className="collectible-auction-date-holders">
          <div className="collectible-auction-date-start">
            <p>STARTING DATE</p>
            <Input
              type="datetime-local"
              onChange={(e) => handleDateChange("start", e.target.value)}
              value={startDate}
              min={min}
            />
          </div>
          <div className="collectible-auction-date-end">
            <p>EXPIRATION DATE</p>
            <Dropdown fluid placeholder="Expiration date" onChange={(e, {value}) => handleDates("end", value)} options={expiryDateOptions} />
          </div>
        </div>
        <div className="collectible-auction-description">
          Any bid placed in the last 10 minutes extends the auction by 10
          minutes.
        </div>
        <a className="collectible-auction-link-text" href="/">
          Learn more how timed auctions work
        </a>
      </div>
    </StyledContainer>
  );
};

export default AuctionDateSubType;
