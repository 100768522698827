import { Popup } from "semantic-ui-react";
// import "./notification.css";
//import back from "../../styles/images/back.svg";
import notif1 from "../../styles/images/notif1.png";
import notif2 from "../../styles/images/notif2.png";
import headerIcon2 from '../../styles/images/header-icon2.svg'
import headerIconlight from '../../styles/images/header-icon2-light.svg'
import NotificationStyledContainer from "./style";
const NotificationPopup = ({currentTheme}: any) => (
  <Popup
    position="bottom right"
    style={{ marginTop: '25px'}}
    on="click"
    pinned
    trigger={
      <button className="btn_outline p-0 btn-img">
        <img src={currentTheme == 'dark' ? headerIcon2 : headerIconlight} alt="back" />
      </button>
    }
  >
    <Popup.Content>
      <Popup.Content>
        <NotificationStyledContainer>
          <div className="notity-pop-div">
            <div className="inner-pop">
              <div className="pop-img">
                <img src={notif2} alt="notif2" />
              </div>
              <div className="pop-info">
                <span className="info-content">
                  Congratulations! Your bid has won the auction. Please wait
                  while we inform the seller so that they can accept the final
                  results.
                </span>
                <span className="time">1 m ago</span>
              </div>
            </div>
            <div className="inner-pop">
              <div className="pop-img">
                <img src={notif1} alt="notif1" />
              </div>
              <div className="pop-info">
                <span className="info-content">
                  Congratulations! Your bid has won the auction. Please wait
                  while we inform the seller so that they can accept the final
                  results.
                </span>
                <span className="time">1 m ago</span>
              </div>
            </div>
          </div>
        </NotificationStyledContainer>
      </Popup.Content>
    </Popup.Content>
  </Popup>
);

export default NotificationPopup;
