
import { Container } from "semantic-ui-react";
import StyledContainer from "./styles";
import LeftArrow from "../../../../styles/images/leftArrow.png";
const editPortfolioPage = () => {

  return (
    <Container>
      <StyledContainer>
        <div className="edit-main">
          <div className="edit-left">
            <div className="title-edit">
              <p>Manage your personal settings</p>
              <h1>Edit profile</h1>
              <img className="arrow-left" src={LeftArrow} alt="" />
            </div>
            <div className="form-edit">
              <div className="input-group">
                <h2>DISPLAY NAME</h2>
               <label>Enter your display name</label>
                <div className="edit-input ui fluid  input">
                  <input type="text" placeholder="E.G JOHNSON SMITH" />
                </div>
              </div>
              <div className="input-group">
                <h2>CUSTOM URL</h2>
               <label>Enter your custom URL</label>
                <div className="edit-input ui fluid  input">
                  <input type="text" placeholder="E.G MUSICRYPT/JOHNSON100" />
                </div>
              </div>
              <div className="input-group">
                <h2>CUSTOM URL</h2>
               <label>Enter your custom URL</label>
                <div className="edit-input ui fluid  input">
                  <textarea placeholder="TELL ABOUT YOURSELF IN A FEW WORDS" ></textarea>
                </div>
              </div>
              <div className="input-group">
                <h2>TWITTER USERNAME</h2>
               <label>Link your twitter account to gain more trust on the marketplace</label>
                <div className="edit-input ui fluid  input">
                  <input type="text" placeholder="E.G HTTPS://" />
                </div>
              </div>
              <div className="verification">
                <h2>VERIFICAITION</h2>
                <div className="inner-verification">
                  <p>Proceed with verification process to get more visibility and gain trust on Shift Marketplace. Please allow up to several weeks for the process.</p>
                  <button className="btn_white">GET VERIFIED</button>
                </div>
              </div>
              <div className="input-group">
                <h2>PERSONAL SITE OR PORTFOLIO</h2>
               <label>Link your twitter account to gain more trust on the marketplace</label>
                <div className="edit-input ui fluid  input">
                  <input type="text" placeholder="E.G JOHNSON SMITH" />
                </div>
              </div>
              
              <div className="button-span">              
              <button className="btn_outline">CANCEL</button>
              <button className="btn_full">UPDATE PROFILE</button>
            </div>
            </div>
          </div>
          <div className="edit-right"></div>
        </div>
      </StyledContainer>
    </Container>
  );
};

export default editPortfolioPage;
