import { createGlobalStyle } from 'styled-components';
// const px2vw = (size: any, width = 1440) => `${(size / width) * 100}vw`;

// Montserrat
import MontserratBold from "./fonts/Montserrat/Montserrat-Bold.ttf";
import MontserratRegular from "./fonts/Montserrat/Montserrat-Regular.ttf";
import MontserratMedium from "./fonts/Montserrat/Montserrat-Medium.ttf";
import MontserratSemiBold from "./fonts/Montserrat/Montserrat-SemiBold.ttf";

// MADE-Outer-Sans
import MosBlack from "./fonts/made-outer-sans/MADE-Outer-Sans-Black.otf";
import MosBold from "./fonts/made-outer-sans/MADE-Outer-Sans-Bold.otf";
import MosLight from "./fonts/made-outer-sans/MADE-Outer-Sans-Light.otf";
import MosMedium from "./fonts/made-outer-sans/MADE-Outer-Sans-Medium.otf";
import MosRegular from "./fonts/made-outer-sans/MADE-Outer-Sans-Regular.otf";
import MosThin from "./fonts/made-outer-sans/MADE-Outer-Sans-Thin.otf";

// SF-Pro-Rounded
import SprBold from "./fonts/SF-Pro-Rounded/SF-Pro-Rounded-Bold.otf";
import SprHeavy from "./fonts/SF-Pro-Rounded/SF-Pro-Rounded-Heavy.otf";
import SprLight from "./fonts/SF-Pro-Rounded/SF-Pro-Rounded-Light.otf";
import SprMedium from "./fonts/SF-Pro-Rounded/SF-Pro-Rounded-Medium.otf";
import SprRegular from "./fonts/SF-Pro-Rounded/SF-Pro-Rounded-Regular.otf";
import SprSemiBold from "./fonts/SF-Pro-Rounded/SF-Pro-Rounded-Semibold.otf";

//images
import arrowLeft from './images/arrow-round-left.svg'
import arrowLeftDark from './images/arrow-round-left-dark.svg'

//Put truetype for ttf and opentype for otf
const Global = createGlobalStyle`
 body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: ${(props: any) => props.theme.color.mainBackgroundColor};
    color: ${(props: any) => props.theme.color.primaryfontColor};
  }
  @font-face {
    font-family: "Montserrat-Bold";
    src: local('Montserrat-Bold'), url(${MontserratBold}) format('truetype');
  }
  @font-face {
    font-family: "Montserrat-Regular";
    src: local('Montserrat-Regular'), url(${MontserratRegular}) format('truetype');
  }

  @font-face {
      font-family: "Montserrat-Medium";
      src: local('Montserrat-Medium'), url(${MontserratMedium}) format('truetype');
  }

  @font-face {
      font-family: "Montserrat-SemiBold";
      src: local('Montserrat-SemiBold'), url(${MontserratSemiBold}) format('truetype');
  }

  @font-face {
    font-family: "MADE-Outer-Sans-Black";
    src: local('MADE-Outer-Sans-Black'), url(${MosBlack}) format('opentype');
}

  @font-face {
      font-family: "MADE-Outer-Sans-Bold";
      src: local('MADE-Outer-Sans-Bold'), url(${MosBold}) format('opentype');
  }

  @font-face {
      font-family: "MADE-Outer-Sans-Light";
      src: local('MADE-Outer-Sans-Light'), url(${MosLight}) format('opentype');
  }

  @font-face {
      font-family: "MADE-Outer-Sans-Medium";
      src: local('MADE-Outer-Sans-Medium'), url(${MosMedium}) format('opentype');
  }

  @font-face {
      font-family: "MADE-Outer-Sans-Regular";
      src: local('MADE-Outer-Sans-Regular'), url(${MosRegular}) format('opentype');
  }

  @font-face {
      font-family: "MADE-Outer-Sans-Thin";
      src: local('MADE-Outer-Sans-Thin'), url(${MosThin}) format('opentype');
  }
  @font-face {
    font-family: "SF-Pro-Rounded-Bold";
    src: local('SF-Pro-Rounded-Bold'), url(${SprBold}) format('opentype');
  }

  @font-face {
      font-family: "SF-Pro-Rounded-Heavy";
      src: local('SF-Pro-Rounded-Heavy'), url(${SprHeavy}) format('opentype');
  }

  @font-face {
      font-family: "SF-Pro-Rounded-Light";
      src: local('SF-Pro-Rounded-Light'), url(${SprLight}) format('opentype');
  }
  @font-face {
      font-family: "SF-Pro-Rounded-Medium";
      src: local('SF-Pro-Rounded-Medium'), url(${SprMedium}) format('opentype');
  }

  @font-face {
      font-family: "SF-Pro-Rounded-Regular";
      src: local('SF-Pro-Rounded-Regular'), url(${SprRegular}) format('opentype');
  }

  @font-face {
      font-family: "SF-Pro-Rounded-Semibold";
      src: local('SF-Pro-Rounded-Semibold'), url(${SprSemiBold}) format('opentype');
  }

  // GLOBAL FROM GLOBAL CSS FILE

  body {
    background: ${(props: any) => props.theme.color.mainBackgroundColor};
    font-family: "Montserrat-Regular";
    color:${props => props.theme.color.primaryfontColor};
}
.wrap-head{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 140px;
    padding-bottom: 20px;
}
.wrap-head h1{
    color: ${props => props.theme.color.primaryfontColor};
    font-size: 39.25px;
    font-family: "MADE-Outer-Sans-Regular";
}
.wrap-head p{
   color: #AFAFAF;
   font-size: 22.25px;
   font-family: "Montserrat-Bold";
}
.gradient{   
     background:  linear-gradient(to right, #56E691 20%, #094EFF, #E56BCC, #56E691 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
}
.big-input{
    width: 725px;
}
// .big-input::after{
//    content: '';
//    position: absolute;
//    height: 60%;
//    width: 1px;
//    background: #707070;
//    margin: auto;
//    right: 59px;
//    top: 0;
//    bottom: 0;
// }
.big-input input{
    height: 46px!important;
    padding-left: 50px!important;
    margin-right: 0px!important;
    letter-spacing: 0.84px;
    color: ${props => props.theme.color.primaryfontColor} !important; 
    opacity: 0.8;
    font-size: 12px;
    border: 1px solid #707070!important;
    background: transparent!important;
}
.big-input img{
    position: absolute;   
    top: 0;
    bottom: 0;
    margin: auto;
}
.search-icon{
    left: 20px;
}
.back-icon{
    right: 20px;
    left: auto;
    cursor: pointer;
    width: 20px;
}
.filter-icon{
    right: 25px;
    left: auto;
    cursor: pointer;
}

.ui.tiny.modal{
    width: 613px;
    background: ${props => props.theme.mode === "dark" ? "#1A101C" : "#f2f2f2"};
    border-radius: 26px;
}
.ui.tiny.modal .content .description{
    margin: 10px 0 10px 0;
}
.ui.tiny.modal .content{
    background: transparent;
    text-align: center;
    padding: 10px 18px;
}
.ui.tiny.modal .content .span-btn{
    width: 300px;
    height: 47px;
    border: 1px solid #E154C4;
    border-radius: 24px;
    margin: auto;
    display: flex;
}
.ui.tiny.modal .content .span-btn span{
    background: #E154C4;
    height: 47px;
    border-radius: 24px;
    width: 50%;
    display: flex;
}
.ui.tiny.modal .content h3{
    font-size: 23px;
    color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};
    font-family: "MADE-Outer-Sans-Regular";
}
.ui.tiny.modal .content h4{
    font-size: 22.25px;
    color: ${props => props.theme.mode === "dark" ? "#ffffff" : "#000000"};;
    font-family: "Montserrat-Medium";
}
.ui.tiny.modal .content p{
    font-size: 16px;
    color: #AFAFAF;
    font-family: "Montserrat-Medium";
    margin: auto;
    width: 70%;
}
.ui.tiny.modal .content .para-p{
    width: 90%;
}
.ui.tiny.modal .content .para{    
    font-size: 13.25px;
    font-family: "Montserrat-Bold";
}
.ui.tiny.modal .content a.wall_link{    
    font-size: 18px;
    color: #E154C4;
    font-family: "Montserrat-Medium";
    margin: auto;   
    text-decoration: underline;
    text-decoration-color: #E154C4;
    
}
.ui.tiny.modal .content .description .tab-div .menu{
    border: 1px solid #E154C4;
    height: 47px;
    background: transparent;
    border-radius: 47px;
    width: 300px;
    margin: auto;
    margin-top: 30px;
}
.ui.tiny.modal .content .description .tab-div .ui.menu .item {   
    padding: 0;
    margin: 0;
    width: 50%;
    border-radius: 47px;
    justify-content: center;
    font-size: 15px;
    font-family: "Montserrat-Bold";
    text-decoration: none;
    color: #E154C4;
}
.ui.tiny.modal .content .description .tab-div .ui.menu .active.item{
    background-color: #E154C4;
    color: #FFFFFF;
}
.ui.tiny.modal .content .description .tab-div .ui.tab p{
    margin-bottom: 20px;
    margin-top: 10px;
}
.ui.tiny.modal .content .description .tab-div .ui.tab.active{
    background: transparent;
}
.ui.tiny.modal .content .description .div-check{
    display: flex;
    text-align: left;
    flex-direction: column;
    margin-top: 30px;
    padding: 0 30px;
}
.button-span{
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 20px auto;
}
.button-span button{
    height: 47px;
    border-radius: 24px;
    font-size: 14px;
    margin: 6px 0;
    font-family: "Montserrat-Bold";
}
.button-span .btn_outline{
    margin-right: 0px;
}

.slick-next {
    transform: rotate(180deg) !important;
    margin-top: -8px;
    right: 4px;
}
.slick-prev{
    left: 4px;
    z-index: 1;
}
.slick-prev:before, .slick-next:before {
    font-size: 40px;
    background-image: url(${arrowLeft});   
    background-image: url(${props => props.theme.mode === "dark" ? arrowLeft : arrowLeftDark});
    background-size: cover;
    color: transparent !important;
    background-color: ${props => props.theme.mode === "dark" ? "#000000" : "#ffffff"};
    opacity: 1;
    border-radius: 40px;
} 
.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
    opacity: 1;
    cursor: not-allowed;
}
.back-span{
    cursor: pointer;
    position: absolute;
    left: 25px;
    top: 25px;
}
.close-span{
    cursor: pointer;
    position: absolute;
    right:  25px;
    top: 25px;
    width: 25px;
}

.des-div{
    padding: 0 20px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
}
.ui.tiny.modal .content .des-div p{
    width: 100%;
    text-align: left;
    font-size: 16.25px;
}

.ui.tiny.modal .content .description .des-div .tab-div{
    width: 100%;
}

.ui.tiny.modal .content .description .des-div .tab-div .menu{
    width: 100%;
}

.checkout-div{
    flex-direction: column;
    display: flex;
    width: 100%;
}
.checkout-div .form-div{
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    margin: 30px 0 40px 0;
}
.checkout-div .form-div label{
    color: #FFFFFF;
    font-size: 19px;
    font-family: 'Montserrat-Bold';
    margin-bottom: 20px;
}
.checkout-div .form-div input{
    border: 1px solid #707070;
    height: 46px;
    background-color: transparent;
    color: #767077;
    font-size: 16px;
    font-family: "Montserrat-SemiBold";
    border-radius: 3px;
    padding-left: 20px;
}
.ui.segment{
    padding: 0;
}
.checkout-div .pay-div{
    display: flex;   
    width: 100%;
}
.checkout-div .pay-div .inner-pay-div{
    display: flex;
    width: 100%;
    flex-direction: column;
}
.checkout-div .pay-div .inner-pay-div .head-div{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #707070;
}
.checkout-div .pay-div .inner-pay-div .head-div .com-div{    
    display: flex;
    color: #FFFFFF;
    font-family: "Montserrat-Bold";
    font-size: 19px;
}
.checkout-div .pay-div .inner-pay-div .body-div{
    width: 100%;
    display: flex;  
    flex-direction: column;
    margin: 15px 0;
}
.checkout-div .pay-div .inner-pay-div .body-div .inner-body-div{
    width: 100%;
    display: flex;
    justify-content: space-between;   
    margin: 5px 0;
}
.checkout-div .pay-div .inner-pay-div .body-div .inner-body-div .left-div{    
    display: flex;
    color: #AFAFAF;
    font-family: "Montserrat-Bold";
    font-size: 16.25px;
}
.checkout-div .pay-div .inner-pay-div .body-div .inner-body-div .right-div{
    display: flex;
    color: #FFFFFF;
    font-family: "SF-Pro-Rounded-Bold";
    font-size: 16px;
}
.checkout-div .btn_full{
    font-size: 15px;
    font-family: "Montserrat-Bold";
    height: 47px;
    border-radius: 47px;
    width: 321px;
    margin: 25px auto;
}
.checkout-div .btn_outline{
    font-size: 15px;
    font-family: "Montserrat-Bold";
    height: 47px;
    border-radius: 47px;
    width: 321px;
    margin: 25px auto;
}
.fund-div{
    color: #AFAFAF;
    font-family: "Montserrat-Bold";
    font-size: 16.25px;
}
.phone-input{
   width: 100%;
   text-align: left;  
}
.phone-input label{
    color: #AFAFAF;
    font-size: 16.25px;
    font-family: "Montserrat-Bold";    
}
.PhoneInput{
    margin-top: 30px;
}
.phone-input .PhoneInputInput{
    border: 1px solid #707070;
    height: 46px;
    background-color: transparent;
    color: #767077;
    font-size: 16px;
    font-family: "Montserrat-SemiBold";
    border-radius:0 3px 3px 0;
    padding-left: 20px;
    border-left: 0;
}
.phone-input .PhoneInputCountry{
    border: 1px solid #707070;
    padding: 0 10px;
    border-right: 0;
    margin: 0;
    border-radius: 3px 0 0 3px;
}
.phone-input .PhoneInputCountrySelectArrow{
    font-size: 25px;
}
.country-div{
    display: flex;
    width: 100%;
    background: #2B1C2E;
    border-radius: 13px;
    padding: 20px;
    margin: 30px 0;
}
.country-div .right-div{
    padding-left: 40px;   
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.country-div .right-div .span-p{
    color: #FFFFFF;
    font-size: 16.25px;
    font-family: "Montserrat-Bold";
}
.country-div .right-div a{
    color: #E154C4!important;
    font-size: 13px!important;
    font-family: "Montserrat-SemiBold";
    margin: 0!important;
    padding-top: 10px;
}
.m-5{
    margin-bottom: 5px;
}

.checkout-div1 .btn_full{
    font-size: 15px;
    font-family: "Montserrat-Bold";
    height: 47px;
    border-radius: 47px;
    width: 321px;
    margin: 5px auto!important;
}
.checkout-div1 .btn_outline{
    font-size: 15px;
    font-family: "Montserrat-Bold";
    height: 47px;
    border-radius: 47px;
    width: 321px;
    margin: 5px auto!important;
}
.privacy-policy{
    color: #AFAFAF;
    font-family: "Montserrat-SemiBold";
    font-size: 13px;
    width: 60%;
    margin: 15px auto 0;
}
.resend{
    color: #AFAFAF;
    font-family: "Montserrat-SemiBold";
    font-size: 13px;
    width: 60%;
    margin: 15px auto 0;
    padding-bottom: 50px;
}
.privacy-policy a{
    color: #ffffff!important;
    font-size: 13px!important;
}
.resend a{
    color: #E154C4!important;
    font-size: 13px!important;
    display: block;
    font-family: "Montserrat-Bold"!important;
}
.opt-text{
    color: #AFAFAF;
    font-size: 16.25px;
    font-family: "Montserrat-Bold";
    text-align: left;
}
.opt-text span{
    color: #FFFFFF;
}

.form-div-card{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5px;
}
.form-div-card .inner-form{
    display: flex;
    width: 100%;
    flex-direction: column;
}
.form-div-card .inner-form .input-form{
    display: flex;
    width: 100%;
    flex-direction: row;
    margin: 8px 0;
}
.ml-8{
    margin-left: 8px;
}
.mr-8{
    margin-right: 8px;
}
.form-div-card .inner-form .input-form .card-input{
    width: 100%;
    border: 1px solid #707070;
    height: 46px;
    background-color: transparent;
    color: #767077;
    font-size: 16px;
    font-family: "Montserrat-SemiBold";
    border-radius: 3px;
    padding-left: 20px;
}
.form-div-card label{
    text-align: left;
    font-size: 16.25px;
    font-family: "Montserrat-Bold";
    color: #FFFFFF;
    margin: 10px 0;
}
.m-30{
    margin-top: 30px;
}

.country-dev{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
}
.country-dev select{  
    border: 1px solid #707070;
    height: 46px;
    background-color: transparent;
    color: #767077;
    font-size: 16px;
    font-family: "Montserrat-SemiBold";
    border-radius: 3px;
    margin: 8px 5px;   
}




.form-gp {
    position: relative;
    cursor: pointer;
    font-size: 22.25px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    margin: 5px 0;
    color: #AFAFAF;
    font-family: "Montserrat-Medium";
    flex-direction: column;
    width: 100%;
  }
  .form-gp .radia-div{
    width: 100%;
  }
  .form-gp input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .form-gp input:checked ~ .checkmark {
    background-color: transparent;
  }
  
  .form-gp input:checked ~ .checkmark:after {
    display: block;
    opacity: 1;
  }
  
  .form-gp .checkmark {   
    position: relative;
    background: transparent;
    border-radius: 7px;
    font-size: 13px;
    border: 1px solid #E154C4;
    display: flex;
    transition: 0.25s;
    color: #FFFFFF;
    height: 71px;
    align-items: center;
    padding: 0 20px;
    margin: 10px 0px;
  }
  .form-gp .checkmark img{
    margin-right: 15px;
  }
  .form-gp .checkmark:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    background: #E154C4;
    width: 100%;
    border-radius: 2px;
    opacity: 0;
    transition: 0.25s;
    z-index: -1;
  }
  
  .form-gp span {
    font-size: 22.25px;
    color: #AFAFAF;
    font-family: "Montserrat-Medium";    
  }

  .art-div{
    display: flex;
    flex-direction: column;
    width: 100%;
    background: ${props => props.theme.mode === "dark" ? "#151515" : "#f2f2f2"};
    padding: 15px 20px;
    border-radius: 9px;
  }
  .art-div .art-btn{
    justify-content: center;
    align-items: center;
    width: 80px;
    display: flex;
    border: 1px solid ${props => props.theme.mode === "dark" ? "#F0F0F0" : "#E154C4"};
    border-radius: 16px;
    color: #E154C4;
    font-family: "Montserrat-Bold";
    margin-bottom: 5px;
  }
  .art-div .inner-art{
    display: flex;
    flex-direction: row;
  }
  .art-div .inner-art .art-span{
    font-size: 15px;
    font-family: "Montserrat-Bold";
    padding: 5px;    
  }
  .art-div .inner-art .left-art{
    display: flex;
    width: 30%;
    color: #AFAFAF;
  }
  .art-div .inner-art .right-art{
    display: flex;
    color: ${props => props.theme.mode === "dark" ? "#ffffff" : "#000000"};
  }

  /* notification popup */
  .ui.bottom.popup {
    padding: 0;
  }
  .ui.popup {
    border: 0 !important;
    border-radius: 8px !important;
  }
  /* notification popup */
  .ui.table {
    width: 100%;
    background: transparent;
    border: 1px solid transparent; 
  }
  .ui.table thead th {      
    background: ${props => props.theme.mode === "dark" ? "#151515" : "#f2f2f2"};
    border-top: 15px solid ${props => props.theme.mode === "dark" ? "black" : "#ffffff"} !important;   
    color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};
    font-size: 13px;
    font-family: "Montserrat-Bold";
    border-bottom: 1px solid transparent;
  }
 .ui.table td, .ui.table th {
    background: ${props => props.theme.mode === "dark" ? "#151515" : "#f2f2f2"};  
    border-top: 15px solid ${props => props.theme.mode === "dark" ? "black" : "#ffffff"} !important;  
  }
  /* .ui.table thead tr>th:first-child {
    border-radius: 9px 0 0 9px;
  } */
  /* .ui.table tr{
      outline: 8px solid #000000;
  } */

  .table-title, .highest-bid, .expiration, .from-div{
      display: flex;
      flex-direction: row;
      align-items: center;
  }
  .table-title .image-title, .from-div .image-title{
      margin-right: 15px;
      display: flex;
  }
  .table-title .info-title{
      display: flex;
      flex-direction: column;      
  }
  .table-title .info-title .big-title, .highest-bid .bid-title, .expiration .expiration-title, .from-div .info-title{
    color: ${props => props.theme.color.primaryfontColor};
    font-size: 15px;
    font-family: "Montserrat-Bold";
  }
  .table-title .info-title .small-title{
    color: #AFAFAF;
    font-size: 11px;
    font-family: "Montserrat-Bold";
  }
  .highest-bid .bid-btn{
    border: 1px solid #E154C4;
    border-radius: 24px;
    width: 128px;
    height: 27px;
    color: #E154C4;
    font-size: 11px;
    font-family: "Montserrat-Bold";
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 15px;
    cursor: pointer;   
  } 
  .ui.table td.span-dr {   
    border-top: 0 !important;   
  }  
  .btn-img{
    width: 35px;
    height: 35px;
    border-radius: 18px;
  }
  /* Place a bid modal start*/
  
  .place-bid-div {
    padding: 0 20px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
}

.place-bid-div .place-bid-title {
    text-align: left !important;
}
.place-bid-div .place_bid_scc {
    margin: 15px 0 0;
}

.ui.tiny.modal .content .place-bid-div p.place-bid-p {
    width: 100%;
    text-align: left;
    font-size: 12.25px;
}

.ui.tiny.modal .content .place-bid-div p.place-bid-p span {
    color: ${props => props.theme.mode === "dark" ? "#ffffff" : "#000000"};
}

.ui.tiny.modal .content .description .place-bid-div .place-bid-tab-div {
    width: 100%;
}

.ui.tiny.modal .content .description .place-bid-div .place-bid-tab-div .menu {
    width: 100%;
}

.place-bid-tab-div .checkout-div {
    flex-direction: column;
    display: flex;
    width: 100%;
}

.place-bid-tab-div .checkout-div .form-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    margin: 20px 0 10px 0;
    position: relative;
}

.place-bid-tab-div .checkout-div .form-div .bid-input-icon {
    position: absolute;
    right: 10px;
    bottom: 12px;
    color: ${props => props.theme.mode === "dark" ? "#ffffff" : "#000000"};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: 'Montserrat-Bold';
}

.place-bid-tab-div .checkout-div .form-div .bid-input-icon img {
    margin-right: 6px;
    width: 20px;
    height: 20px;
}
.place-bid-tab-div .checkout-div .bid-input-info { 
    color: ${props => props.theme.mode === "dark" ? "#ffffff" : "#000000"};
    font-size: 11px;
    font-family: 'Montserrat-Bold';
    text-align:left;
    margin-bottom:0px;
 }

.place-bid-tab-div .checkout-div .form-div label {
    color: ${props => props.theme.mode === "dark" ? "#ffffff" : "#000000"};
    font-size: 16px;
    font-family: 'Montserrat-Bold';
    margin-bottom: 12px;
}

.place-bid-tab-div .checkout-div .form-div input {
    border: 1px solid #707070;
    height: 46px;
    background-color: transparent;
    color:${props => props.theme.mode === "dark" ? "#ffffff" : "#000000"};
    font-size: 16px;
    font-family: "Montserrat-SemiBold";
    border-radius: 3px;
    padding-left: 20px;
    padding-right: 80px;
}

.ui.segment {
    padding: 0;
}

.place-bid-tab-div .checkout-div .pay-div {
    display: flex;
    width: 100%;
}

.place-bid-tab-div .checkout-div .pay-div .inner-pay-div {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.place-bid-tab-div .checkout-div .pay-div .inner-pay-div .head-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #707070;
}

.place-bid-tab-div .checkout-div .pay-div .inner-pay-div .head-div .com-div {
    display: flex;
    color: ${props => props.theme.color.primaryfontColor};
    font-family: "Montserrat-Bold";
    font-size: 19px;
}

.place-bid-tab-div .checkout-div .pay-div .inner-pay-div .body-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 15px 0;
}

.place-bid-tab-div .checkout-div .pay-div .inner-pay-div .body-div .inner-body-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}

.place-bid-tab-div .checkout-div .pay-div .inner-pay-div .body-div .inner-body-div .left-div {
    display: flex;
    color: ${props => props.theme.color.tertiaryColor};
    font-family: "Montserrat-Bold";
    font-size: 14px;
}

.place-bid-tab-div .checkout-div .pay-div .inner-pay-div .body-div .inner-body-div .right-div {
    display: flex;
    color: ${props => props.theme.mode === "dark" ? "#ffffff" : "#000000"};
    font-family: "SF-Pro-Rounded-Bold";
    font-size: 14px;
}

.place-bid-tab-div .checkout-div .btn_full, .accept-offer-tab-div .btn_full{
    font-size: 15px;
    font-family: "Montserrat-Bold";
    height: 47px;
    border-radius: 47px;
    width: 321px;
    margin: 15px auto;
    margin-bottom: 5px;
}

.place-bid-tab-div .fund-div {
    color: ${props => props.theme.color.tertiaryColor};
    font-family: "Montserrat-Bold";
    font-size: 13.25px;
}
.cummunity_text {
    display: block;
    font-size: 12px;
    color: ${props => props.theme.color.primaryfontColor};
    text-transform: uppercase;
    cursor: pointer;
    padding: 10px;
    margin: 0 5px;
    font-family: "Montserrat-Bold";
  }
  .ui.popup:before {
    display: none;
  }
  /* Place a bid modal end*/


  .tab-activity{
    width: 100%;
    display: flex;
}
 .tab-activity>div{
    width: 100%;
}
 .tab-activity .menu{   
    background: transparent;    
    width: 100%;   
    margin-top: 0px !important;
   
    // border-bottom: 1px solid ${(props:any) => props.borderSelect ? '#f1f1f1' : '#151515' };

    border-bottom: 1px solid ${props => props.theme.mode === "dark" ? "#151515" : "#f1f1f1"} ;
}
 .tab-activity .ui.menu .item {   
    padding: 0;
    margin: 0;  
    justify-content: center;
    font-size: 11px;
    color: #AFAFAF;
    font-family: "Montserrat-Medium";
    text-decoration: none;
    border-radius: 0;
    margin-right: 80px;
    position: relative;       
}
 .tab-activity .ui.menu .active.item{   
    color: ${props => props.theme.color.primaryfontColor};
    background: transparent;
}
 .tab-activity .ui.tab p{
    margin-bottom: 30px;
}
.tab-activity .ui.tab.active{
    background: transparent !important;
}
.tab-activity .ui.menu a.item:hover {
    color:${props => props.theme.color.primaryfontColor};
}
.tab-activity .ui.menu .active.item::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: ${props => props.theme.color.primaryfontColor};
    display: flex;
    bottom: -1px;
}

 .tab-activity .ui.segment{
   padding: 0;
   margin: 0;
}
.tab-activity .ui.menu{
    justify-content: center;
}
.activity-div h1{    
    color: ${props => props.theme.mode === "dark" ? "#ffffff" : "black"};
    font-family: "MADE-Outer-Sans-Regular";
    font-size: 39.25px;
    text-align: center;
    margin-top: 80px;
}

.activity-div i.icon.caret.right:before {
    content: "";    
    background-image: url(${arrowLeft})!important;
    background-size: cover;
    width: 35px;
    height: 35px;
    display: flex;
    color: transparent !important;
    transform: rotate(180deg);
}
.activity-div i.icon.caret.down:before {
    content: "";
    background-image: url(${arrowLeft})!important;
    background-size: cover;
    width: 35px;
    height: 35px;
    display: flex;
    color: transparent !important;
    transform: rotate(270deg);
}

.accept-offer-modal-div {
    padding: 0 20px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
}
.accept-offer-modal-div .accept-offer-modal-title {
    text-align: left !important;
}
.accept-offer-modal-div .accpt-h4 {
    text-align: left !important;
    font-family: "MADE-Outer-Sans-Regular";
    font-size:24px;
    color:${props => props.theme.color.primaryfontColor};
}

.ui.tiny.modal .content .accept-offer-modal-div p.place-bid-p {
    width: 100%;
    text-align: left;
    font-size: 16.25px;
}

.ui.tiny.modal .content .accept-offer-modal-div p.place-bid-p span {
    color: ${props => props.theme.color.primaryfontColor};
}
.ui.tiny.modal .content .description .accept-offer-modal-div .accept-offer-tab-div {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.accept-offer-tab-div .acct-notify-div {
    display: flex;
    align-items: center;
    width:90%;
    background: ${props => props.theme.mode === "dark" ? "#2B1C2E" : "#ffffff"};
    border-radius: 13px;
    padding: 0 15px;
    height: 60px;
    margin-top: 20px;
}
.accept-offer-tab-div .accept-offer-details-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width:100%;
    background: ${props => props.theme.mode === "dark" ? "#2B1C2E" : "#ffffff"};
    border-radius: 13px;
    padding: 15px;
    margin: 15px 0;
}
.accept-offer-tab-div .accept-offer-details-container .accept-offer-details-data3{
    font-family: "SF-Pro-Rounded-Bold";
    font-size:22px;
    color:${props => props.theme.color.primaryfontColor};
 }
.accept-offer-tab-div .acct-notify-div i.icon{
    display: flex;
    align-items: center;
}
.accept-offer-tab-div .acct-notify-div span.acct-info-txt{
    display: flex;
    align-items: center;
    font-size:13px;
    color:${props => props.theme.color.secondaryColor};
    font-family: "Montserrat-SemiBold";
    margin-left: 15px
}
.accept-offer-tab-div  .accept-offer-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width:100%;
    margin: 20px 0;
}
.accept-offer-tab-div .accept-offer-details-label{
    font-family: "Montserrat-Bold";
    font-size:16px;
    color:${props => props.theme.color.tertiaryColor};
}
.accept-offer-tab-div  .accept-offer-details .accept-offer-details-data{
    font-family: "SF-Pro-Rounded-Bold";
    font-size:28px;
    color:${props => props.theme.color.primaryfontColor};
    margin-bottom: 20px;
}
.accept-offer-tab-div  .accept-offer-details .accept-offer-details-data2{
    font-family: "Montserrat-Bold";
    font-size:24px;
    color: ${props => props.theme.color.primaryfontColor};
}
/*detail left*/
.market_img_block{
    display: flex;
    width: calc( 100% - 50px);
    height: 500px;
    border: 1px solid #BCBCBC27;
    border-radius: 6px;
    /* margin: 0 10px; */
    margin-right: 25px;
}
.market_img_card{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: calc( 100% - 30px);;
    height: calc( 100% - 30px);;
    margin: 15px;
}
.w-80{
    width: 80px;
    margin-right: 10px;
}
.btn_full{
    background: ${props => props.theme.color.secondaryColor};
    border: 1px solid ${props => props.theme.color.secondaryColor};
    color: #ffffff;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.btn_outline{
    background: transparent;
    border: 1px solid ${props => props.theme.color.secondaryColor};
    color: ${props => props.theme.color.secondaryColor};
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}    
.Toastify__toast-body > div:last-child {
    color: black !important;
}
.Toastify__toast-container {
    z-index: 200000;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ui.segment {   
    box-shadow: none;
    border: 1px solid transparent;
}
.ui.menu{
    box-shadow: none;
    border: 0;
}
.ui.menu .item:before {  
    background: transparent;
}
.ui.dropdown>.text{
    color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "#000000"};
}
.ui.dropdown .menu{
    background: ${props => props.theme.mode === "dark" ? "#111111" : "#FFFFFF"}!important;
}
.ui.dropdown .menu .selected.item, .ui.dropdown.selected{
    color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "#000000"};
}
.ui.dropdown .menu>.item{
    color: ${props => props.theme.mode === "dark" ? "#AFAFAF" : "#111111"};
}
.ui.dropdown .menu>.item:hover{
    color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "#000000"};
}
.ui.checkbox .box:after, .ui.checkbox label:after{
    right: 0;
    left: auto;
}
.ui.checkbox .box:before, .ui.checkbox label:before{
    border: 1px solid transparent;
    border-color: transparent;
    width: 0;
    height: 0;
    opacity :0;
}
.ui.checkbox .box, .ui.checkbox label {    
    padding-left: 15px; 
    font-size: 12px;
    margin: 1px 0;
}
.ui.checkbox input:checked~.box:before, .ui.checkbox input:checked~label:before {
    border-color: transparent;   
}
.ui.checkbox input:focus~.box:before, .ui.checkbox input:focus~label:before{
    border-color: transparent;   
}
.ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after {    
    color: #77ff70;
}
.ui.inverted.menu {
    border: transparent;  
}
.ui.secondary.inverted.pointing.menu .active.item{
    border-color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "#000000"};
    color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "#000000"}!important;
}
.ui.secondary.inverted.menu .link.item, .ui.secondary.inverted.menu a.item{
    color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "#000000"}!important;
}
.ui.secondary.inverted.menu .dropdown.item:hover, .ui.secondary.inverted.menu .link.item:hover, .ui.secondary.inverted.menu a.item:hover{
    color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "#000000"}!important;
}
.share-icon{
    display: flex;
    justify-content: center;
}
.share-icon .links{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    width: 60px;
}
.share-icon .links a{   
    height: 40px;
    width: 40px;
    border: 1px solid #707070;
    border-radius: 40px;
    display: flex;
}
.share-icon .links a img{ 
    width: 60%;
    margin: auto; 
}
.share-icon .links span{
    font-size: 10px;
    margin-top: 10px;
}
@media only screen and (max-width: 991px) {
    .big-input{
        width: 100%;
    }
    .market_img_block{
        width:100%;
        margin-right: 0;
        margin-bottom: 25px;
    }
    .market_img_block{       
        width: 100%;      
        margin-right: 0;
    }
    .wrap-head h1{        
        font-size: 24px;
    }
    .wrap-head p{       
       font-size: 16px;      
    }
    .activity-div h1{
        font-size: 24px;
    }
}
@media only screen and (max-width: 768px) {
    .ui.tiny.modal {
        width: 90%;
        background: #1A101C;
        border-radius: 26px;
    }
    .tab-activity .ui.menu .item {  
        margin-right: 10px;
     }
     .ui.tiny.modal .content h3 {
        font-size: 21px;
     }
     .ui.tiny.modal .content .place-bid-div p.place-bid-p {
        font-size: 11px;
     }
     .place-bid-tab-div .checkout-div .form-div {
         margin: 24px 0 10px 0;
     }
     .place-bid-tab-div .checkout-div .form-div label {
        font-size: 14px;
        margin-bottom: 10px;
    }
    .place-bid-tab-div .checkout-div .form-div .bid-input-icon {
        font-size: 10px;
    }
    .place-bid-tab-div .checkout-div .bid-input-info {
        font-size: 10px;
      }
      .place-bid-tab-div .checkout-div .pay-div .inner-pay-div .body-div .inner-body-div .left-div {
        font-size: 14px;
      }
      .place-bid-tab-div .checkout-div .pay-div .inner-pay-div .body-div .inner-body-div .right-div {
        font-size: 14px;
      }
      .place-bid-tab-div .checkout-div .btn_full, .accept-offer-tab-div .btn_full {
          width: 250px;
          font-size: 13px;
      }
      .place-bid-tab-div .fund-div {
        font-size: 14px;
      }
      .wrap-head h1{        
       font-size: 18px;
      }
       .wrap-head p{       
         font-size: 14px;      
       }
       .activity-div h1{
        font-size: 18px;
    }
      .accept-offer-tab-div .acct-notify-div {
          width: 100%;
      }
      .accept-offer-tab-div .acct-notify-div span.acct-info-txt{
        font-size: 11px;
      }
      .accept-offer-tab-div .accept-offer-details-label {
        font-size: 15px;  
      }
      .accept-offer-tab-div .accept-offer-details .accept-offer-details-data {
        font-size: 22px; 
      }
      .accept-offer-tab-div .accept-offer-details .accept-offer-details-data2 {
        font-size: 16px; 
      }
      .ui.tiny.modal .content .accept-offer-modal-div p.place-bid-p {
        font-size: 14px;
      }
      .accept-offer-tab-div .accept-offer-details-container .accept-offer-details-data3 {
        font-size: 16px; 
      }
      .accept-offer-tab-div .accept-offer-details-container {
        margin: 15px 0 10px 0;
      }
      .btn-img{
        width: 25px;
        height: 25px;
      }
      .btn_outline{
        height: 25px; 
      }
     
}
`;

export default Global;

/* TODO to be used in futre
  :root {
      font-size: ${px2vw(24)};v

      @media (min-width: 768px) {
        font-size: ${px2vw(18)};
      }

      @media (min-width: 1024px) {
        font-size: ${px2vw(16)};
      }
    } */