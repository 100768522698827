import styled, { css } from "styled-components";

export default styled.div<any>`
    
    ${css`
    .after-preview-bid-card-container {
        width: 234px;
        margin: auto;
        padding: 2px;
        font-family: "Montserrat-Bold";
        border-radius: 6px;       
        border: 1px solid #BCBCBC27;
    }
    
    .after-preview-bid-card-layer1 {
        display: flex;
        justify-content: space-between;
        padding: 10px;
    }
    .after-preview-bid-card-circle-container {
        position: relative;
        width: 35px;
        height: 36px;
        border-radius: 100%;
        background-color: #BCBCBC27;
        border: 1px solid #BCBCBC27;
    }
    
    .after-preview-bid-card-circle-image{
        
    }
    .after-preview-bid-card-badge {       
        height: 15px;
        position: absolute;
        bottom: 0;
        left: 20px;
    }
    
    .after-preview-bid-card-layer2 {
        position: relative;       
    }
    .after-preview-bid-card-layer2-image-card {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        // width: calc( 100% - 12px);
        // height: 231px;
        // margin: 6px;

        video {
            max-width: 100%;
            max-height: 300px;
        }
       
    }
    .after-preview-bid-card-layer2-overlay {
        position: absolute;
        text-align: center;
        letter-spacing: 0px;
        color:#FFFFFF;
        opacity: 1;
        top: 5px;
    }
    
    .after-preview-bid-card-layer3{
        text-align: left;
        letter-spacing: 0px;
        color:#FFFFFF;
        opacity: 1;
        padding: 10px 10px 0 10px;
    }
    
    .after-preview-bid-card-layer4 {
        display: flex;
        padding: 10px;
    }
    .after-preview-card-timer {
        display: flex;
        padding: 0 0 0 10px;
        margin: 0 10px;
        border: 2px solid #fff;
    }
    .after-preview-card-timer span::after {
        content: 'left';
        padding-left: 10px;
    }
    .after-preview-bid-card-layer3 .title{
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};       
        font-size: 15px;
        font-family: "Montserrat-Bold";
    }
    .after-preview-bid-card-layer4-sft {
        text-align: left;
        letter-spacing: 0px;
        color:${props => props.theme.mode === "dark" ? "#FFFFFF" : "#000000"};
        padding-right: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .after-preview-bid-card-layer4 span {
        padding-right: 5px;
        color:#FFFFFF;
    }
    .after-preview-bid-card-layer4-stock {
        font: normal normal 600 12px/15px Montserrat;
        letter-spacing: 0px;
        color: #B7B7B7;
        display: flex;
        align-items: center;
        width: 100px;
    }
    
    .after-preview-bid-card-layer4-stock b {
        background-color: #FFFFFF;
        padding: 0 3px;
        color: black !important;
        border-radius: 20%;
        margin: 2px;
        height: 16px;
        line-height: 16px;
    }
    .after-preview-bid-card-layer4-not-for-sale {
        text-align: left;
        display: flex;
        align-items: center;
        font: normal normal 600 12px/15px Montserrat;
        letter-spacing: 0px;
        color: #B7B7B7;
    }
    
    .after-preview-bid-card-layer5 {
        display: flex;
        justify-content: space-between;
        padding: 0 10px 10px 10px;
    }
    .after-preview-bid-card-layer5 p {
        text-align: left;
        letter-spacing: 0.84px;
        color: #E154C4 !important;
        text-transform: uppercase;
        font-size: 12px;
    }
    
    .after-preview-bid-card-layer5-heart {
        text-align: right;
        letter-spacing: 0.84px;
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "#000000"};
        cursor: pointer;
    }
    ${(props: any): any => {
            if (props.isStackContainer) {
                return css`
                .after-preview-bid-card-container {
                    border: none !important;
                    position: relative;
                }
                .layer_inner_wrap {
                    border: 1px solid #7070706E;
                    border-radius: 5px;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    position: relative;
                    background: ${props => props.theme.mode === "dark" ? "#000000" : "#FFFFFF"};
                }
               .after-preview-bid-card-container::before{
                content: '';
                border: 1px solid #7070706E;
                border-radius: 5px;
                width: 96%;
                height: 100%; 
                position: absolute;
                margin: auto;
                top: 8px;
                left: 0;
                right: 0;
                z-index: 1;
                background: ${props => props.theme.mode === "dark" ? "#000000" : "#FFFFFF"};
            }
            .after-preview-bid-card-container::after {
                content: '';
                border: 1px solid #7070706E;
                border-radius: 5px;
                width: 92%;
                height: 100%; 
                position: absolute;
                margin: auto;
                top: 16px;
                left: 0;
                right: 0;
                z-index: 0;
            }
            `
            }
        }}
    `}
`;