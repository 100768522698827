import { AxiosResponse } from 'axios';
import { http } from '../http';

export enum REQUESTS {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

enum SEVERITY {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

interface Notification {
  successMessage: string;
  errorMessage: string;
}

interface useRequestProps {
  method: REQUESTS;
  url: string;
  body?: object;
  notification?: Notification;
  token?: any;
}

const AxiosRequest = ({ method, url, body, token, notification }: useRequestProps) => {
  const executeRequest = async () => {
    let severity: SEVERITY;
    let message: string;
    let response: any;

    try {
      const ApiResponse: AxiosResponse<any, any> = await http({
        method: method,
        url: url,
        data: body || {},
        headers: token
      });

      if (process.env.NODE_ENV === 'development')
        console.log('ApiResponse from', url, ' =====> ', ApiResponse);

      if (ApiResponse.data) {
        severity = SEVERITY.SUCCESS;
        message = notification?.successMessage ?? '';
        response = ApiResponse.data;
      } else {
        severity = SEVERITY.ERROR;
        message =
          notification?.errorMessage ??
          "You'r request could not be completed. Please try later."
        response = ApiResponse.data;
      }
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development')
        console.log('error from', url, ' =====> ', error.response);

      switch (error?.response?.status) {
        case 401:
          message = 'DefaultNotification.Unauthenticated';
          break;
        case 403:
          message = 'DefaultNotification.Unauthorized';
          break;
        case 404:
          message = 'DefaultNotification.NotFound';
          break;
        case 500:
          message = 'DefaultNotification.ServerError';
          break;
        default:
          message =
            notification?.errorMessage ??
            'DefaultNotification.DefaultErrorMessage';
      }
      severity = SEVERITY.ERROR;
      message = error.response?.data?.message ?? message;
      response = error.response?.data;
    }
    console.log(severity + '\n' + message);
    return response;
  };
  return { executeRequest };
};

export default AxiosRequest;
