import styled, {css} from 'styled-components';
import React from "react";
//import arrowLeft from '../../../styles/images/arrow-round-left.svg'
const MyActivityCss = styled(React.Fragment)`

${css`
.place-bid-div {
    padding: 0 20px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
}

.place-bid-div .place-bid-title {
    text-align: left !important;
}

.ui.tiny.modal .content .place-bid-div p {
    width: 100%;
    text-align: left;
    font-size: 16.25px;
}

.ui.tiny.modal .content .place-bid-div p span {
    color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"} !important;;
}

.ui.tiny.modal .content .description .place-bid-div .place-bid-tab-div {
    width: 100%;
}

.ui.tiny.modal .content .description .place-bid-div .place-bid-tab-div .menu {
    width: 100%;
}

.checkout-div {
    flex-direction: column;
    display: flex;
    width: 100%;
}

.checkout-div .form-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    margin: 30px 0 40px 0;
    position: relative;
}

.checkout-div .form-div .bid-input-icon {
    position: absolute;
    right: 10px;
    bottom: 12px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: 'Montserrat-Bold';
}

.checkout-div .form-div .bid-input-icon img {
    margin-right: 6px;
    width: 20px;
    height: 20px;
}

.checkout-div .form-div label {
    color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"} !important;;
    font-size: 19px;
    font-family: 'Montserrat-Bold';
    margin-bottom: 20px;
}

.checkout-div .form-div input {
    border: 1px solid #707070;
    height: 46px;
    background-color: transparent;
    color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"} !important;;
    font-size: 16px;
    font-family: "Montserrat-SemiBold";
    border-radius: 3px;
    padding-left: 20px;
    padding-right: 80px;
}

.ui.segment {
    padding: 0;
}

.checkout-div .pay-div {
    display: flex;
    width: 100%;
}

.checkout-div .pay-div .inner-pay-div {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.checkout-div .pay-div .inner-pay-div .head-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #707070;
}

.checkout-div .pay-div .inner-pay-div .head-div .com-div {
    display: flex;
    color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"} !important;;
    font-family: "Montserrat-Bold";
    font-size: 19px;
}

.checkout-div .pay-div .inner-pay-div .body-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 15px 0;
}

.checkout-div .pay-div .inner-pay-div .body-div .inner-body-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}

.checkout-div .pay-div .inner-pay-div .body-div .inner-body-div .left-div {
    display: flex;
    color: #AFAFAF;
    font-family: "Montserrat-Bold";
    font-size: 16.25px;
}

.checkout-div .pay-div .inner-pay-div .body-div .inner-body-div .right-div {
    display: flex;
    color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"} !important;;
    font-family: "SF-Pro-Rounded-Bold";
    font-size: 16px;
}

.checkout-div .btn_full {
    font-size: 15px;
    font-family: "Montserrat-Bold";
    height: 47px;
    border-radius: 47px;
    width: 321px;
    margin: 25px auto;
}

.fund-div {
    color: #AFAFAF;
    font-family: "Montserrat-Bold";
    font-size: 16.25px;
}
@media only screen and (max-width: 768px) {
   
}
`}
`;


export default MyActivityCss;


