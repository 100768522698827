import { useEffect, useState } from "react";
import { Container } from "semantic-ui-react";
//import tickBadge from "../../styles/images/tick-badge.svg";
import Slider from "react-slick";
import AxiosRequest, { REQUESTS } from "../../utils/hooks/useRequest";
// import "./hotCollections.css";
import HotColectionStyledContainer from "./style";

interface HotCollectionsApiInterface {
  sellerProfilePicture: string;
  displayName: string;
  sum: string;
}
const HotCollections = (props: any) => {
  const [hotCollections, setHotCollections] = useState(
    Array<HotCollectionsApiInterface>()
  );
  // const { data } = props;
  const hotCollectionSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: hotCollections.length > 4 ? 4 : hotCollections.length,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { executeRequest } = AxiosRequest({
    method: REQUESTS.GET,
    url: "collections/hot-collection?page=1&records=10",
  });

  useEffect(() => {
    (async () => {
      const { data } = await executeRequest();
      setHotCollections(data || []);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HotColectionStyledContainer>
      <Container>
        <div>
          <div className="collection_wrapper">
            <Slider {...hotCollectionSettings}>
              {hotCollections?.map((hotCollection) => (
                <div className="coll_slide">
                  <div className="collection_card_block">
                    <div className="collection_card">
                      <div className="collection-cover"></div>
                      <div className="img_block">
                        <img src={hotCollection?.sellerProfilePicture} alt="" />
                      </div>
                      <h3>{hotCollection?.displayName}</h3>
                      <span>${hotCollection?.sum}</span>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </Container>
    </HotColectionStyledContainer>
  );
};

export default HotCollections;
