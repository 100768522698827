import React, { useEffect, useState } from "react";
import { Modal } from "semantic-ui-react";
// import "./place-bid.css";
import infoIcon from "../../../../../styles/images/question-icon.svg";
import close from "../../../../../styles/images/close.svg";
import AxiosRequest, { REQUESTS } from "../../../../../utils/hooks/useRequest";
import NFTData from "../../../../../utils/artifacts/NFT.json";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MetaMask } from "nft-web3";

const PlaceBidModal = (props: any) => {
  const { show, onClose = () => {}, token } = props;
  const [succes, setSuccess] = useState(false);
  const [tokenRes, setTokenRes] = useState<any>({});
  const { loginDetail } = useSelector((state: any) => state.headerReducer);
  const [amount, setAmount] = useState();
  // if (!show) {
  //   return null;
  // }
  const addFund = async () => {
    const { executeRequest } = AxiosRequest({
      method: REQUESTS.GET,
      url: `nft-vouchers/${token}`,
      token: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
    });
    try {
      const res = await executeRequest();
      if ([200, 201, 203].includes(res?.statusCode)) {
        // toast.success(res?.message);
        setTokenRes(res?.data);
        setSuccess(true);
      } else {
        toast.error(res?.message);
      }
    } catch (err: any) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (Object.keys(tokenRes).length > 0) {  
      (async () => {
        const signTX = [
          Number(tokenRes?.tokenId),
          tokenRes?.minPrice,
          tokenRes?.url,
          tokenRes?.signature,
        ];
       const wallet = await new MetaMask(window.ethereum);
       const account = await wallet.connectMetaMask();
        const redeemData = await wallet.sendTransaction(
          `${process.env.REACT_APP_CONTRACT_ADDRESS}`,
          NFTData?.abi,
          signTX,
          account
        );
      })();
    }
  }, [tokenRes]);

  return (
    <Modal
      size="tiny"
      onClose={() => {
        onClose(false);
        setSuccess(false);
      }}
      onOpen={() => {
        onClose(true);
        setSuccess(false);
      }}
      open={show}
    >
      <Modal.Content>
        <Modal.Description>
          <img
            onClick={() => onClose(false)}
            className="close-span"
            src={close}
            alt="close"
          />
          <div className="place-bid-div">
            {succes && (
              <React.Fragment>
                <p className="place-bid-p">
                  {" "}
                  <span>ThisNFT-Rules</span> by <span>The Bunni Lama</span>
                </p>
                <h4 className="place-bid-title place_bid_scc">
                  You have successfully placed a bid for 0.06 wETH
                </h4>
              </React.Fragment>
            )}
            {!succes && (
              <React.Fragment>
                <h3 className="place-bid-title">Place a bid</h3>
                <p className="place-bid-p">
                  You are about to place a bid for:
                  <br /> <span>ThisNFT-Rules</span> by{" "}
                  <span>The Bunni Lama</span>
                </p>
              </React.Fragment>
            )}
            <div className="place-bid-tab-div">
              <div className="checkout-div">
              {!succes &&  <div className="form-div">
                  <label>YOUR BID *</label>
                   <input
                    type="text"
                    className="tab-input"
                    placeholder=""
                    pattern="[+-]?\d+(?:[.,]\d+)?"
                    value={amount}
                    onChange={({ target: { value } }) =>
                      setAmount((prevState: any) => {
                        const regex = new RegExp(/^[+-]?\d*(?:[.,]\d*)?$/);
                        if (regex.test(value)) {
                          return value;
                        }
                        return prevState;
                      })
                    }
                  /> 
                  
                  <span className="bid-input-icon">
                    <img src={infoIcon} alt="WETH" /> WETH
                  </span>
                </div>
                }
                {!succes && <div className="pay-div">
                  <div className="inner-pay-div">
                    <div className="body-div">
                      <div className="inner-body-div">
                        <div className="left-div com-div">
                          Your bidding balance
                        </div>
                        <div className="right-div com-div">0 wETH</div>
                      </div>
                      <div className="inner-body-div">
                        <div className="left-div com-div">Your balance</div>
                        <div className="right-div com-div">0 ETH</div>
                      </div>
                      <div className="inner-body-div">
                        <div className="left-div com-div">Service fee</div>
                        <div className="right-div com-div">0.0015 wETH</div>
                      </div>
                      <div className="inner-body-div">
                        <div className="left-div com-div">You will pay</div>
                        <div className="right-div com-div">0.0615 wETH</div>
                      </div>
                    </div>
                  </div>
                </div> 
                }
                <button
                  onClick={() => {
                    // succes ? [onClose(false), setSuccess(false)] : addFund();
                    if (succes) {
                      onClose(false);
                      setSuccess(false);
                    } else {
                      addFund();
                    }
                  }}
                  className="btn_full"
                >
                  {succes ? "CLOSE" : "ADD FUNDS"}
                </button>
                {!succes && <div className="fund-div">Not enough funds</div>}
              </div>
            </div>
          </div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default PlaceBidModal;
