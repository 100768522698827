import styled, { css } from "styled-components";

export default styled.div`
    ${css`
     .sort-pop-div{
        display: flex;
        padding: 10px;
        background: ${props => props.theme.color.mainBackgroundColor};
        color: ${props => props.theme.color.primaryfontColor};
        border: 0.5px solid #605e5e;
     }
     .sort-pop-div .inner-pop{
        display: flex;
        flex-direction: column;
        padding-bottom: 10px
     }
     .sort-pop-div .inner-pop .pop-head{
        padding: 10px 15px;
        color: ${props => props.theme.color.secondaryColor};
        font-size: 11px;
        font-family: "Montserrat-Bold";
     }
     .sort-pop-div .inner-pop .pop-info{
        display: flex;
        flex-direction: column;
        font-size: 12px;
     }
     .sort-pop-div .inner-pop .pop-info span{
        padding: 5px 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;   
        font-family: "Montserrat-Bold";   
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"}; 
     }
     .sort-pop-div .inner-pop .pop-info span:hover{
        color: ${props => props.theme.mode === "dark" ? "#707070" : "#707070"}; 
     }
     .ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before {
        background-color: #E154C4 !important;
        
     }
     .ui.toggle.checkbox input~.box:after, .ui.toggle.checkbox input~label:after {
        left: 0.3rem;
    }
    .ui.toggle.checkbox input:checked~.box:after, .ui.toggle.checkbox input:checked~label:after {
        left: 2.15rem;
    }
     .ui.toggle.checkbox .box:after, .ui.toggle.checkbox label:after {
        width: 1rem;
        height: 1rem;
        top: 3px;       
    }
    
    `}
`