import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { buttonCSS } from './MultiSelect.styles';

const MultiSelectListFooter = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.25rem; /* 20px if base font-size is 16px */
  // border-top: 1px #ccc solid;
`;

const MultiSelectApplyButton = styled('button')`
  ${buttonCSS}
  &:disabled {
    cursor: no-drop;
    background-color: grey;
  }
`;
const MultiSelectResetButton = styled('button')`
background-color: #222222;
border-radius: 10px;
padding: 0 20px;
height: 35px;
line-height: 35px;
border: 1px solid #E154C4;
cursor: pointer;
&:disabled {
  cursor: no-drop;
  background-color: grey;
  color: #fff;
  border: none;
}
`;

const MultiSelectFooter = ({ applyButtonText = 'Apply', resetButtonText ='Reset', resetSelections, handleApplyClick, disableReset, disableApply }: any) => (
  <MultiSelectListFooter>
    <MultiSelectResetButton className="multiselect-reset-button" onClick={resetSelections} disabled={disableReset}>
      {resetButtonText}
    </MultiSelectResetButton>
    <MultiSelectApplyButton className="multiselect-apply-button" onClick={handleApplyClick} disabled={disableApply}>
      {applyButtonText}
    </MultiSelectApplyButton>
  </MultiSelectListFooter>
);

MultiSelectFooter.propTypes = {
  applyButtonText: PropTypes.string,
  handleApplyClick: PropTypes.func
};

export default MultiSelectFooter;
