import styled, { css } from "styled-components";

export default styled.div<any>`
    
    ${css`
    .bid-card-container {
        width: 234px;
        margin: auto;
        padding: 2px;
        font-family: "Montserrat-Bold";
        border-radius: 15px;       
        border: 1px solid ${props => props.theme.mode === "dark" ? "#BCBCBC27" : "#767676"};
        margin-top: 6px;
    }
    .bid-card-container:hover{
        box-shadow: 3px 3px 12px 1px ${props => props.theme.mode === "dark" ? "#fefefe47" : "#0000005c"};
    }
    .bid-card-layer1 {
        display: flex;
        justify-content: space-between;
        padding: 10px;
    }
    .bid-card-circle-container {
        position: relative;
        width: 35px;
        height: 36px;
        border-radius: 100%;
        background-color: #BCBCBC27;
        border: 1px solid #BCBCBC27;
    }
    
    .bid-card-circle-image{
        
    }
    .bid-card-badge {       
        height: 15px;
        position: absolute;
        bottom: 0;
        left: 20px;
    }
    
    .bid-card-layer2 {
        position: relative;       
    }
    .bid-card-layer2-image-card {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: calc( 100% - 12px);
        height: 231px;
        margin: 6px;
        overflow: hidden;    
        padding: 0;
        background-image: linear-gradient(#E56BCC, #dddddd);
    }

    .bid-card-layer2-overlay {
        position: absolute;
        text-align: center;
        letter-spacing: 0px;
        color:#FFFFFF;
        opacity: 1;
        top: 5px;
    }
    
    .bid-card-layer3{
        text-align: left;
        letter-spacing: 0px;
        color:#FFFFFF;
        opacity: 1;
        padding: 10px 10px 0 10px;       
    }
    .bid-card-layer3-span, .bid-card-layer4-sft{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .bid-card-layer4 {
        display: flex;
        padding: 10px;
    }
    .bid-card-layer3 .title{
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};       
        font-size: 15px;
        font-family: "Montserrat-Bold";
    }
    .bid-card-layer4-sft {
        text-align: left;
        letter-spacing: 0px;
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};  
        padding-right: 5px;
        width: 120px;
    }
    .bid-card-layer4 span {
        padding-right: 5px;
        color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};  
    }
    .bid-card-layer4-stock {
        font: normal normal 600 12px/15px Montserrat;
        letter-spacing: 0px;
        color: #B7B7B7;
        display: flex;
        align-items: center;
    }
    
    .bid-card-layer4-stock b {
        background-color: ${props => props.theme.mode === "dark" ? "#FFFFFF" : "black"};
        padding: 0 3px;
        color: ${props => props.theme.mode === "dark" ? "black" : "#FFFFFF"} !important;
        border-radius: 20%;
        margin: 2px;
        height: 16px;
        line-height: 16px;
    }
    .bid-card-layer4-not-for-sale {
        text-align: left;
        display: flex;
        align-items: center;
        font: normal normal 600 12px/15px Montserrat;
        letter-spacing: 0px;
        color: #B7B7B7;
    }
    
    .bid-card-layer5 {
        display: flex;
        justify-content: space-between;
        padding: 0 10px 10px 10px;
        align-items: center;
    }
    .bid-card-layer5 p {
        text-align: left;
        letter-spacing: 0.84px;
        color: #E154C4 !important;
        text-transform: uppercase;
        font-size: 12px;
        margin-bottom:0;
    }

    .bid-card-layer5 button {
        text-align: left;
        letter-spacing: 0.84px;
        color: #E154C4 !important;
        font-size: 12px;
        margin-bottom:0;
        background: transparent;
        border: none;
        cursor: pointer;
    }

    .bid-card-layer5 img {
        margin-right:5px
    }
    
    .bid-card-layer5-heart {
        text-align: right;
        letter-spacing: 0.84px;
        color:#FFFFFF;
        cursor: pointer;
    }

    .tooltip {
        visibility: hidden;
    }
      
    .tooltip-on-hover:hover .tooltip {
        visibility: visible;
    }

    .tooltip-on-hover {
        position:relative;        
    }   

    .tooltip-on-hover .tooltip {
        position:absolute;
        left:-6px;
        top:-20px;
        white-space:nowrap;
        background: #fff;
        color:#000;
        line-height: 15px;
        padding:0 5px;
    }

    button.slick-slide {
        border: 0;
        cursor:pointer;
        background: ${props => props.theme.mode === "dark" ? "black" : "#ffffff"};
    }

    .slick-slide img {
        // height: 100%;
        // width: 100%;
        // object-fit: cover;
        // max-width:100%;
        // width:100%;
        height: auto;
        // max-height: 100%;
        max-width: 100%;
        border-radius: 8px
    }
    


    ${(props: any): any => {
            if (props.isStackContainer) {
                return css`
            .bid-card-container,
            .bid-card-container::before,
            .bid-card-container::after {
                box-shadow: 0 0.0625em 0.1875em 0 rgba(0, 0, 0, 0.1), 0 0.5em 0 -0.25em #f2f2f2, 0 0.5em 0.1875em -0.25em rgba(0, 0, 0, 0.1), 0 1em 0 -0.5em #e5e5e5, 0 1em 0.1875em -0.5em rgba(0, 0, 0, 0.1);
                // box-shadow: 0 0.0625em 0.1875em 0 rgba(0, 0, 0, 0.1), 0 0.5em 0 -0.25em #000000, 0 0.5em 0.1875em -0.25em rgba(0, 0, 0, 0.1), 0 1em 0 -0.5em #000000, 0 1em 0.1875em -0.5em rgba(0, 0, 0, 0.1);
            }
            `
            }
        }}
    `}
`;