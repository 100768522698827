import styled, {css} from 'styled-components';

const AcceptOfferPopupCss = styled.section`
${css`
.accept-offer-modal-div {
    padding: 0 20px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
}

.accept-offer-modal-div .accept-offer-modal-title {
    text-align: left !important;
}

.ui.tiny.modal .content .accept-offer-modal-div p {
    width: 100%;
    text-align: left;
    font-size: 16.25px;
}

.ui.tiny.modal .content .accept-offer-modal-div p span {
    color: #FFFFFF;
}

// .ui.tiny.modal .content .description .accept-offer-modal-div .place-bid-tab-div {
//     width: 100%;
// }

// .ui.tiny.modal .content .description .accept-offer-modal-div .place-bid-tab-div .menu {
//     width: 100%;
// }

.accept-offer-tab-div .acct-notify-div {
    diplay: flex;
    align-items: center;
    border: 1px solid ${props => props.theme.color.primaryfontColor}
    width:100%
}

.accept-offer-tab-div .btn_full {
    font-size: 15px;
    font-family: "Montserrat-Bold";
    height: 47px;
    border-radius: 47px;
    width: 321px;
    margin: 25px auto;
}

.fund-div {
    color: #AFAFAF;
    font-family: "Montserrat-Bold";
    font-size: 16.25px;
}
`}
`;


export default AcceptOfferPopupCss;